<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <a href="https://docs.tabnova.com/novaemm" target="_blank" class="md-button md-info">
        <i class="material-icons">document</i>
        <p>Documentation</p></a>
    </li><br>
    <div v-if="!excludePayment">
      <li v-if="!isActivePlan">
        <a @click="$router.push('plan-list')" class="md-button md-primary">
          <i class="material-icons">document</i>
          <p>Buy Plan</p>
        </a>
      </li>
      <li v-else>
        <a :href="upgradePlanLink" class="md-button md-primary">
          <i class="material-icons">document</i>
          <p>Update Plan</p>
        </a>
      </li>
    </div>
    <!--<li>
      <md-field>
        <label>Search</label>
        <md-input v-model="search" type="text"></md-input>
      </md-field>
    </li>
    <li>
      <a href="#" class="dropdown-toggle" data-toggle="dropdown">
        <i class="material-icons">dashboard</i>
        <p>Stats</p></a
      >
    </li>
    <li>
      <drop-down>
        <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
          <i class="material-icons">notifications</i>
          <span class="notification">5</span>
          <p>Some Actions</p>
        </a>
        <ul class="dropdown-menu dropdown-menu-right">
          <li><a href="#">Mike John responded to your email</a></li>
          <li><a href="#">You have 5 new tasks</a></li>
          <li><a href="#">You're now friend with Andrew</a></li>
          <li><a href="#">Another Notification</a></li>
          <li><a href="#">Another One</a></li>
        </ul>
      </drop-down>
    </li>
    <li>
      <a href="#" data-toggle="dropdown" class="dropdown-toggle"
        ><i class="material-icons">person</i>
        <p>Account</p></a
      >
    </li>-->
  </ul>
</template>
<script>
import UserDataService from "../../../../services/UsersDataService";
export default {
  data() {
    return {
      search: null,
      selectedEmployee: null,
      isActivePlan: false,
      stripeCustomerId: null,
      stripePlanId: null,
      upgradePlanLink:null,
      excludePayment: false,
    };
  },
   methods: {
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.stripeCustomerId = response.data.data.stripe_customer_id;
          this.stripePlanId = response.data.data.stripe_plan_id;
          this.upgradePlanLink = "/subscription?price_id="+this.stripePlanId;
          if (response.data.data.subscription_expire_at) {
            if (new Date(response.data.data.subscription_expire_at) > new Date()) {
              this.isActivePlan = true;
            }
          }
          
          if( response.data.exclude_users.match(new RegExp("(?:^|,)"+response.data.data.email+"(?:,|$)"))) {
              this.excludePayment = true;
          }

          if (response.data.device_exceeded_message) {
            this.device_exceeded_message = response.data.device_exceeded_message
          }

        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            this.$router.push('login');
          }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
};
</script>
