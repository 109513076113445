<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="from_date" md-immediately>
                    <label>Start Date</label>
                  </md-datepicker>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="to_date" md-immediately>
                    <label>To Date</label>
                  </md-datepicker>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <md-field>
                  <md-select class="box-textbox" placeholder="Device Type" v-model="device_type">
                      <md-option value="">Device Type</md-option>
                      <md-option value="Android">Android</md-option>
                      <md-option value="Ios">Ios</md-option>
                  </md-select>
                </md-field>
            </div>
            <div class="md-layout-item md-size-50">
                <md-field>
                  <md-select class="box-textbox" placeholder="Deleted Device" v-model="device_is_deleted">
                      <md-option value="">Select Option</md-option>
                      <md-option value="true">Yes</md-option>
                      <md-option value="false">No</md-option>
                  </md-select>
                </md-field>
            </div>
          </div>
          <div class="md-layout">
              <div class="md-layout-item md-size-50" style="padding-top: 15px;">
                <select id="db_name_dropdwon">
                    <option>Select DB</option>
                    <option v-for="db in db_list" v-bind:key="db._id" :value="db._id">
                      {{ db.db_name }}
                    </option>
                </select>
              </div>
              
              <div class="md-layout-item md-size-50">
                  <md-button class="md-primary" :disabled="isDisabled" @click="DeviceList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Serial Number">{{ item.serialNumber  }}
                <span class="deleted" v-if="item.is_deleted">Deleted</span>
              </md-table-cell>
              <md-table-cell md-label="Database">{{ item.dbName  }}</md-table-cell>
              <md-table-cell md-label="User" v-if="item.created_user_details" style="width: 35%;">
                 <a href="javascript:void(0)" @click="ByPassUserLogin(item.created_user_details.email)">{{ item.created_user_details.email }}</a>
              </md-table-cell>
              <md-table-cell md-label="User" v-else>
                  <a href="javascript:void(0)" @click="FetchUserDetail(item.serialNumber, item.dbName)">Fetch</a>
              </md-table-cell>
              <md-table-cell md-label="Device User" v-if="item.device_user_details">
                 {{ item.device_user_details.user_name }}
              </md-table-cell>
              <md-table-cell md-label="UserName" v-else>
                  <a href="javascript:void(0)" @click="FetchUserDetail(item.serialNumber, item.dbName)">Fetch</a>
              </md-table-cell>
              <md-table-cell md-label="Type">
                  <p class="badge-blue" v-if="item.type != 'Ios'">Android</p>
                  <p class="badge-red" v-else>Ios</p>
                </md-table-cell>
              <md-table-cell md-label="Updated At">{{ HumanReadableDateFormat(item.updated_at)  }}</md-table-cell>
              <md-table-cell md-label="Created At">{{ HumanReadableDateFormat(item.created_at)  }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import DeviceDataService from "../../services/SuperAdmin/DeviceDataService";
import UsersDataService from "../../services/SuperAdmin/UsersDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      from_date: "",
      to_date: new Date().toISOString().split('T')[0],
      device_type: "",
      tableData: [],
      db_list: [],
      db_name: "",
      device_is_deleted: null,
      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

    };
  },
  methods: {
    DeviceList() {
      let data = {
        from_date: this.from_date, 
        to_date: this.to_date,
        device_type: this.device_type,
        db_name: this.db_name,
        device_is_deleted: this.device_is_deleted
      }
      DeviceDataService.DeviceList(data)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
            this.db_list = response.data.filters.DbList;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    FetchUserDetail(serialNumber, dbName) {
      DeviceDataService.FetchUserDetail(serialNumber, dbName)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.DeviceList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    ByPassUserLogin(email) {
        let data = {
            email: email,
        }
        UsersDataService.ByPassUserLogin(data)
        .then((response) => {
            localStorage.setItem('token', response.data.data.token);
            let routeData = this.$router.resolve({name: 'dashboard'});
            window.open(routeData.href, '_blank');
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    let d = new Date();
    d.setMonth(d.getMonth()-1);
    this.from_date = d.toISOString().split('T')[0];
    this.DeviceList();
  },
  mounted() {
    $('#db_name_dropdwon').select2({
      placeholder: "Select Database"
    }).on('select2:select', (e) => {
      if (e.params.data.text == "Select DB") {
        this.db_name = "";
      } else {
        this.db_name = e.params.data.text;
      }
    });
  },
};
</script>

<style>
</style>
