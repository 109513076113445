<template>
  <div class="md-layout text-center">
    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="this.$route.params.show_email_div">
      <strong>Congratulation!</strong> A message with a confirmation link has been sent to your email address. Please open the link to activate your account.
    </div><br>
      <login-card header-color="green">
        <h4 slot="title" class="title">Welcome to {{ WhiteLabellingTitle() }}</h4>
        <!--<md-button
          slot="buttons"
          href="#facebook"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-facebook-square"></i>
        </md-button>
        <md-button
          slot="buttons"
          href="#twitter"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-twitter"></i>
        </md-button>
        <md-button
          slot="buttons"
          href="#google"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-google-plus-g"></i>
        </md-button>
        <p slot="description" class="description">Or Be Classical</p>-->
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email</label>
          <md-input v-model="email" type="email" @keyup.enter="Login()"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password</label>
          <md-input v-model="password" type="password" @keyup.enter="Login()"></md-input>
        </md-field>
        <div slot="footer">
          <md-button class="md-success" v-on:click="Login()" :disabled="isDisabled">
            Login
          </md-button>  
        </div>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import UserDataService from "../../services/SuperAdmin/UsersDataService";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      isDisabled: false,
      email: null,
      password: null,
    };
  },
  methods: {
    Login() {
      this.isDisabled = true;
      var data = {
        email: this.email,
        password: this.password,
      };
      UserDataService.login(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.$router.push({ path: '/super-admin/verify/otp', query: { email: this.email }});
          /*localStorage.setItem('super_admin_token', response.data.data.token);
          localStorage.setItem('token', "");
          document.location = "/admin-dashboard";*/
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
.md-card-actions{
  padding: unset !important;
}
</style>
