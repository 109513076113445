import http from "../../http-common-super-admin";

class DomainDataService {
    DomainList() {
      return http.get("domains/list");
    }
    CreateDomain(data) {
      return http.post("domains/create", data);
    }
    GetDomainDetail(id) {
      return http.get("domains/list?id="+id);
    }
    UpdateDomain(data) {
      return http.post("domains/update", data);
    }
    DeleteDomain(data) {
        return http.post("domains/delete", data);
    }
    CreateEmailConfig(data) {
        return http.post("domains/email/config/create", data,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }
    EmailConfigList() {
      return http.get("domains/email/config/list");
    }
    DeleteEmailConfig(data) {
      return http.post("domains/email/config/delete", data);
    }
    SendTestEmail() {
      return http.get("domains/email/test");
    }
    generateSignupUrl(data) {
      return http.get("domains/generate-signup-url", { params: data });
    }
    completeEnterpriseSignup(data) {
      return http.post("domains/enterprise/create", data);
    }
}

export default new DomainDataService();