import axios from "axios";

const headers = { "Content-Type": "application/json" };
if (localStorage.getItem('token')) {
  headers.Authorization = localStorage.getItem('token');
}
export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: headers
});
//process.env.VUE_APP_API_URL
//"https://test.novaemm.com:4500/api/v1/admin/"