<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-success" @click="$router.push({ path: 'admin-domains-create'})">Create Domain</md-button>
      </div>
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="Domain">{{ item.domain }}</md-table-cell>
              <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Address">{{ item.address }}</md-table-cell>
              <md-table-cell md-label="Phone Number">{{ item.phone_number }}</md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: block ruby;">
                  <md-button v-if="!item.enterprise_detail" class="md-just-icon md-primary m-0 md-simple" @click="generateSignupUrl(item._id)"><md-icon title="Setup Enterprise">settings</md-icon></md-button>
                  <md-button class="md-just-icon md-info m-0 md-simple" @click="$router.push({ path: '/admin-domains-edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                  <md-button class="md-just-icon md-danger m-0 md-simple" @click="DeleteDomain(item._id)"><md-icon>close</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import DomainDataService from "../../services/SuperAdmin/DomainDataService";
import Swal from "sweetalert2";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
      DomainList() {
        DomainDataService.DomainList()
          .then((response) => {
            if (response.data.data) {
              this.tableData = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      
      DeleteDomain(id) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = { "id" : id };
            DomainDataService.DeleteDomain(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.DomainList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
      },

      generateSignupUrl(id) {
        let data = {
          id: id,
        };
        DomainDataService.generateSignupUrl(data)
          .then((response) => {
            window.location.href = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },

      completeEnterpriseSignup() {
        let data = {
          enterpriseToken: this.$route.query.enterpriseToken,
          domainId: this.$route.query.domain_id,
        };
        DomainDataService.completeEnterpriseSignup(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.DomainList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });  
      }
  },
  beforeMount() {
    this.DomainList();
    if (this.$route.query.enterpriseToken) {
      this.completeEnterpriseSignup();
    }
  },
};
</script>

<style>
</style>
