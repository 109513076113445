import { render, staticRenderFns } from "./GridSystem.vue?vue&type=template&id=08c2e8ea&scoped=true&"
import script from "./GridSystem.vue?vue&type=script&lang=js&"
export * from "./GridSystem.vue?vue&type=script&lang=js&"
import style0 from "./GridSystem.vue?vue&type=style&index=0&id=08c2e8ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c2e8ea",
  null
  
)

export default component.exports