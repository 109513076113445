<template>
    <div class="m-3">
        <div class="card">
            <div class="card-header">
                Webinar Detail
            </div>
            <div class="card-body webinar-header">
                <div class="row">
                    <div class="col-sm-6">
                        <p class="card-text">{{ webinarDetail.title }}</p>                
                        <small v-if="webinarDetail.repeat_every_week" class="text-info font-weight-bold">
                            Every {{ getDay(webinarDetail.date) }} at {{ webinarDetail.time }}
                        </small>
                        <small v-else class="text-info font-weight-bold">
                            {{ HumanReadableDateFormat(webinarDetail.date, true) }} {{ webinarDetail.time }}
                        </small>
                        <md-field v-if="webinarDetail.repeat_every_week">
                            <md-select class="box-textbox" placeholder="Webinar Dates" v-model="webinarDate" :style="{background: 'white !important', padding: '5px'}">
                                <md-option :value="HumanReadableDateFormat(date, true)" v-for="date in upcomingDates" :key="date">{{ HumanReadableDateFormat(date, true) }}</md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                          <md-input type="text" :style="{background: 'white !important', padding: '5px'}" v-model="emailIds" placeholder="Enter Emails"></md-input>
                        </md-field>
                        <small class="text-danger float-left">Enter multiple email address comma seperated.</small><br>
                        <div :style="{ display: 'flex' }">
                            <md-button class="md-success mr-2" :disabled="isDisabled" @click="webinarRegistration()">Register
                              <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                            </md-button>
                            <md-button class="md-primary mr-2" @click="requestWebinarModal = true">Request</md-button>
                        </div>
                    </div>
                    <div class="col-sm-6 webinar-img">
                        <img v-if="webinarDetail.image_link" class="card-img-top img-thumbnail fix-height" :src="webinarDetail.image_link" :style="{ width: 'auto !important' }">
                        <img v-else class="card-img-top img-thumbnail fix-height" :src="webinarDefaultImage" alt="Card image cap">
                    </div>
                </div>
            </div>
            <div class="card-body m-3">
                <div v-html="webinarDetail.description" />              
            </div>
        </div>
        <!-- Models -->
        <template>
            <modal v-if="requestWebinarModal" @close="requestWebinarModal = false">
              <template slot="header">
                <h4 class="modal-title">Request Webinar</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="requestWebinarModal = false"><md-icon>clear</md-icon></md-button>
              </template>
              <template slot="body">
                <div class="box-body">
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <md-textarea type="text" placeholder="Please let us know more about your prefer date and time" v-model="notes"></md-textarea>
                        </md-field>
                      </div>
                    </div>
                </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="requestWebinarModal = false">Close</md-button>
                <md-button class="md-success" :disabled="isDisabled" @click="requestWebinar()">
                  Request <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                </md-button>
              </template>
            </modal>
        </template>
    </div>
</template>
<script>
import WebinarService from "../../services/WebinarService";
import { Modal } from "@/components";

export default {
  components: {
    Modal,
  },
  props: {
    webinarDefaultImage: {
      type: String,
      default: "./img/webinar.jpg",
    },
  },
  data() {
    return {
        isDisabled: false,
        webinarId: this.$route.query.id,
        webinarDetail: null,
        webinarDate: "",
        upcomingDates: [],
        emailIds: "",
        requestWebinarModal: false,
        notes:"",
    };
  },
  methods: {
    webinarList() {
        let data = { id: this.webinarId };
        WebinarService.webinarList(data)
        .then((response) => {
          this.webinarDetail = response.data.data;
          this.upcomingDates = response.data.dates;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    webinarRegistration() {
      this.isDisabled = true;
      let date = this.webinarDate;
      if (this.webinarDetail.repeat_every_week == false) {
        date = this.HumanReadableDateFormat(this.webinarDetail.date, true);
      }
      let data = {
        webinarId: this.webinarId, date: date, emailIds: this.emailIds
      }
      WebinarService.webinarRegistration(data)
        .then((response) => {
          this.isDisabled = false;
          this.emailIds="";
          this.date="";
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    requestWebinar() {
      this.isDisabled = true;
      
      let data = {
        webinarId: this.webinarId, notes: this.notes
      }
      WebinarService.requestWebinar(data)
        .then((response) => {
          this.isDisabled = false;
          this.notes="";
          this.requestWebinarModal = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    HumanReadableDateFormat(date, onlyDate = false) {
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
          "July", "Aug", "Sept", "Oct", "Nov", "Dec"
          ];
          let dt = new Date(date);
          if (dt == "Invalid Date") {
              return "-";
          }
          let day = ("0" + dt.getDate()).slice(-2);
          let month = dt.getMonth();
          let year = dt.getFullYear();
          let hour = ("0" + dt.getHours()).slice(-2);
          let minute = ("0" + dt.getMinutes()).slice(-2);
          let seconds = ("0" + dt.getSeconds()).slice(-2);
          if (onlyDate) {
              return day + " " + monthNames[month] + " " + year;
          } else {
              return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
          }
      },
  },
  beforeMount() {
    this.webinarList();
  },
}
</script>