<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <!--<li class="md-list-item">
              <a
                href="#/components/notifications"
                class="
                  md-list-item-router md-list-item-container md-button-clean
                  dropdown
                "
              >
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>-->
            <li>
              <a href="javascript:void(0)" @click="$router.back()" class="md-button md-primary">
                <div class="md-ripple">
                  <div class="md-button-content">Back</div>
                </div>
              </a>
            </li>&nbsp;
            <li>
              <a href="https://docs.tabnova.com/novaemm" target="_blank" class="md-button md-info">
                <div class="md-ripple">
                <div class="md-button-content">Documentation</div>
                </div>
              </a>
            </li>&nbsp;
            <div v-if="!excludePayment">
              <li v-if="!isActivePlan">
                <a @click="$router.push('plan-list')" class="md-button md-primary">
                  <div class="md-ripple">
                  <div class="md-button-content">buy Plan</div>
                  </div>
                </a>
              </li>
              <li v-else>
                <a :href="upgradePlanLink" class="md-button md-warning">
                  <div class="md-ripple">
                  <div class="md-button-content">Update Plan</div>
                  </div>
                </a>
              </li>
            </div>
            <li class="md-list-item">
              <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                      <!--<h4>Divyesh Amreliya</h4>&nbsp;-->
                      <md-icon>group</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="$router.push({ path: 'change-password'})">Change Password</a></li>
                      <li><a @click="$router.push({ path: '/privacy-security'})">Privacy & Security</a></li>
                      <li><a @click="$router.push({ path: '/audit-logs'})">Audit History</a></li>
                      <li><a @click="$router.push({ path: '/payment-history'})">Payment History</a></li>
                      <li><a @click="$router.push({ path: '/cards'})">Cards</a></li>
                      <li><a @click="DoLogout()">Logout</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
            <!--<li>
              <a href="https://docs.tabnova.com/novaemm" target="_blank" class="md-button md-primary">
                <div class="md-ripple">
                <div class="md-button-content">Documentation</div>
                </div>
              </a>
            </li>&nbsp;
            <li>
                <a @click="DoLogout()" class="md-button md-secondary">
                  <div class="md-ripple">
                  <div class="md-button-content">Logout</div>
                  </div>
                </a>
              </li>-->
          </md-list>
        </div>
      </div>
    </div>
    <div class="alert alert-danger" style="width: 85%;" v-if="device_exceeded_message">
      <span><b> Danger - </b> {{ device_exceeded_message }}</span>
    </div>
  </md-toolbar>
</template>

<script>
import UserDataService from "../../../services/UsersDataService";
export default {
  data() {
    return {
      device_exceeded_message: '',
      isActivePlan: false,
      stripeCustomerId: null,
      stripePlanId: null,
      upgradePlanLink:null,
      excludePayment: false,
      selectedEmployee: "",
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    DoLogout() {
      localStorage.setItem('token', "");
      UserDataService.Logout()
        .then((response) => {})
        .catch((e) => {
        });
      this.$router.push('/login');
    },
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.stripeCustomerId = response.data.data.stripe_customer_id;
          this.stripePlanId = response.data.data.stripe_plan_id;
          this.upgradePlanLink = "/subscription?price_id="+this.stripePlanId;
          if (response.data.data.subscription_expire_at) {
            if (new Date(response.data.data.subscription_expire_at) > new Date()) {
              this.isActivePlan = true;
            }
          }
          
          if( response.data.exclude_users.match(new RegExp("(?:^|,)"+response.data.data.email+"(?:,|$)"))) {
              this.excludePayment = true;
          }

          if (response.data.device_exceeded_message) {
            this.device_exceeded_message = response.data.device_exceeded_message
          }

        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              this.$router.push('login');
            }
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
};
</script>
