<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-field>
                            <md-select class="box-textbox" placeholder="Country" v-model="country">
                                <md-option v-for="code in GetCountryList()" v-bind:key="code.code"
                                    :value="code.iso">{{ code.iso + ' - ' + code.country }}</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-field>
                            <md-input type="text" class="search_text_box" v-model="search_application" placeholder="Search Applications" ></md-input>
                            <button class="search_btn" :disabled="isDisabled" @click="SearchAppStoreApp()"><i class="fa fa-search"></i></button>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <div v-if="app_store_applications.length > 0" class="scrollit">
                            <div class="app-listing box-shadow" v-if="app_store_applications">
                                <div class="screencellbook" v-for="app in app_store_applications" :key="app.appId">
                                <img :src="app.icon">
                                <a class="badge-icon" href="javascript:void(0);" @click="AddToProfileApplication(app)">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </a>
                                <span class="apptext" :title="app.title">{{ app.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
            <md-table
                :value="queriedData"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort"
                class="paginated-table table-striped table-hover" 
                table-header-color="green"
                md-fixed-header
            >
            <md-table-toolbar>
                <md-field>
                    <label for="pages">Per page</label>
                    <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                        {{ item }}
                    </md-option>
                    </md-select>
                </md-field>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Package Name" md-sort-by="package_name">
                    <img :src="item.app_icon" style="height: 50px;width: 50px;" /> 
                    <span v-if="!item.is_web_app"> {{ item.package_name }} </span>
                    <span v-else> {{ item.application_details.title }} </span>
                </md-table-cell>
                <md-table-cell md-label="Product Id">{{ item.product_id }}</md-table-cell>
                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                <md-table-cell md-label="Actions">
                    <md-button class="md-just-icon md-danger md-simple" @click="DeleteManualApp(item.package_name)"><md-icon>close</md-icon></md-button>&nbsp;&nbsp;&nbsp;
                </md-table-cell>
                </md-table-row>
            </md-table>
            </md-card-content>

            <md-card-actions md-alignment="space-between">
            <div class="">
                <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
            </div>
            <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
            >
            </pagination>
            </md-card-actions>
            
        </md-card>
    </div>
</template>
<script>
import ApplicationDataService from "../../services/ApplicationDataService";
import { Pagination } from "@/components";
import Swal from "sweetalert2";

export default {
    components: {
        Pagination
    },
    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
            result = this.searchedData;
        }
        return result.slice(this.from, this.to);
        },
        to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
        },
        from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
        return this.searchedData.length > 0
            ? this.searchedData.length
            : this.tableData.length;
        },
    },
    data() {
        return {
            isDisabled: false,
            tableData: [],
            search_application: "",
            profile_applications: [],
            app_store_applications: [],
            updated_app_store_application: [],
            country: "us",

            pagination: {
                perPage: 25,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            searchedData: [],
            //Sorting
            currentSort: "created_at",
            currentSortOrder: "asc",
        }
    },
    methods: {
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === "desc") {
                return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
        GetApplicationList() {
        ApplicationDataService.GetApplicationList("", "Ios")
            .then((response) => {
            if (response.data.data) {
                this.tableData = response.data.data;
            }
            })
            .catch((e) => {
            this.$toast.error(e);
            });
        },
        SearchAppStoreApp() {
            if (!this.search_application) {
            this.$toast.error("Please enter application name to search");
            }
            this.isDisabled = true;
            ApplicationDataService.SearchAppStoreApp(this.search_application, this.country)
            .then((response) => {
                this.app_store_applications = response.data.data;
                this.isDisabled = false;
            })
            .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                this.isDisabled = false;
            });    
        },
        AddToProfileApplication(app) {
            let data = {
                product_id: app.id,
                package_name: app.appId,
                app_icon: app.icon,
                application_details: app,
                type: "Ios"
            };
            ApplicationDataService.CreateIosApplication(data)
            .then((response) => {
                let updated_app_store_application = this.app_store_applications.filter(elem => elem.appId != app.appId)
                this.profile_applications.push(app);
                this.app_store_applications = updated_app_store_application;
                this.GetApplicationList();
            })
            .catch((e) => {
            });
        },
        DeleteManualApp(package_name) {
            let message = `This application also be deleted from profile application list`;
            let data = {
                package_name: package_name,
                type: "Ios"
            };
            ApplicationDataService.ListLinkProfiles(data)
            .then((response) => {
                if (response.data.data.length > 0) {
                    message += "<ul>";
                    for (let content of response.data.data) {
                        message += '<li>'+content.profile_id.profile_name+'</li>';
                    }
                    message += "</ul>";
                }
                Swal.fire({
                title: "Are you sure?",
                //text: message,
                html:message,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "md-button md-success",
                cancelButtonClass: "md-button md-danger",
                confirmButtonText: "Yes, delete it!",
                buttonsStyling: false,
                }).then((result) => {
                if (result.value) {
                    var data = { "package_name" : package_name, "type": "Ios" };
                    ApplicationDataService.DeleteApplication(data)
                    .then((response) => {
                        this.$toast.success(response.data.respones_msg);
                        this.GetApplicationList();
                    })
                    .catch((e) => {
                        if (e.response) {
                        this.$toast.error(e.response.data.respones_msg);
                        }
                    });
                }
                });
            });
            
            },
    },
    beforeMount() {
        this.GetApplicationList();
    },
}
</script>
<style scoped>
    .search_text_box {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        float: left;
        width: 80%;
    }
    
</style>