<template>
  <div class="md-layout-item md-size-100">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
          

    <!-- Content Start -->
    <table cellpadding="0" cellspacing="0" cols="1" bgcolor="#d7d7d7" align="center" style="max-width: 600px;">
        <!-- This encapsulation is required to ensure correct rendering on Windows 10 Mail app. -->
        <tr bgcolor="#d7d7d7">
            <td
                style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
               

                <!-- Generic Pod Left Aligned with Price breakdown Start -->
                <table align="center" cellpadding="0" cellspacing="0" cols="3" bgcolor="white"
                    class="bordered-left-right"
                    style="max-width: 600px; width: 100%;">
                    <tr height="50">
                        <td colspan="3"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                    </tr>
                    <tr align="center">
                        <td width="36"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                        <td class="text-danger">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </td>
                        <td width="36"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                    </tr>
                    <tr align="center">
                        <td width="36"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                        <td class="text-danger">
                            <h1
                                style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 30px; font-weight: 700; line-height: 34px; margin-bottom: 0; margin-top: 0;">
                                Payment Failed</h1>
                        </td>
                        <td width="36"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                    </tr>
                    <tr height="30">
                        <td colspan="3"
                            style="color: #464646; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 16px; vertical-align: top;">
                        </td>
                    </tr>
                    <tr align="left">
                        <td width="36"></td>
                        <td>
                            <p>{{ message }}</p>
                        </td>
                        <td width="36"></td>
                    </tr>
                    <tr height="50"></tr>
                </table>
                <!-- Generic Pod Left Aligned with Price breakdown End -->


            </td>
        </tr>
    </table>
    <!-- Content End -->
      </div>
    </div>
  </div>
</template>
<script>
import PaymentDataService from "../../services/PaymentDataService";
export default {
    props: {
        tick: {
            type: String,
            default: "./img/tick.png",
        },
    },
    data() {
        return {
            message: this.$route.query.message
        };
    },
}
</script>