<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-content>
        <div style="float:right;">
            <md-button class="md-success" type="submit" :disabled='isDisabled' @click="CreateUser()">Save</md-button>
          </div>
          <hr>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Login Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                User Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="user_name"></md-input>
                </md-field>
                <span class="md-helper-text">Use like this in application configuration 
                    <b><span style="color:red;">$username$</span></b></span>&nbsp;
                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                    <div class="bottom">
                        <p><b>$username$</b> value will be replaced by <b>User Name</b> in application manage configuration.</p>
                    </div>
                </div>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Confirm Password
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="password" v-model="confirm_password"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Device
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="user_device" name="user_device">
                      <md-option>Select Device</md-option>
                      <md-option
                        v-for="device in devices"
                        v-bind:key="device._id"
                        :value="device._id"
                      >
                        {{ device.hardwareInfo.serialNumber }}
                      </md-option>
                    </md-select>
                </md-field>
                <md-switch v-model="search_hide_assign_devices" @change="FilterDeviceList()">Hide Assign Devices</md-switch>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Basic Details</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                First Name <span style="color:red;">*</span>
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="first_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Last Name
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text"  v-model="last_name"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-30 md-form-label">
                Email
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="email"></md-input>
                </md-field>
                <span class="md-helper-text">Use like this in MDM configuration <b><span style="color:red;">$emailAddress$</span></b></span>&nbsp;
                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                    <div class="bottom">
                        <p>$emailAddress$ value will be replaced by email in application manage configuration.</p>
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          <hr>
          <h4 class="card-title">Custom Properties <br>(<small>Those attribues used as a placeholder in MDM policy configuration text fields. Use like this <b><span style="color:red;">$user.content.custom1$</span></b>&nbsp;
          <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
              <div class="bottom">
                  <p>$user.content.custom1$ value will be replaced by custom content in application manage configuration.</p>
              </div>
          </div>
          </small>)</h4>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Custom 1
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="custom1"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Custom 2
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-input type="text" v-model="custom2"></md-input>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import DeviceProceDataService from "../../services/DeviceProceDataService";

export default {
  components: {},
  data() {
    return {
      user_name: null,
      password: null,   
      confirm_password: null,
      first_name: null,
      last_name: null,
      email: null,
      custom1: null,
      custom2: null,
      isDisabled: false,
      user_device:null,
      search_hide_assign_devices: false,
      devices: [],
    };
  },
  methods: {
    GetDevicesList() {
      ApplicationUserDataService.GetDevicesList()
        .then((response) => {
          if (response.data.data) {
            this.devices = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    CreateUser() {
      this.isDisabled = true;
      var data ={
        'user_name' : this.user_name,
        'password'  : this.password,
        'confirm_password' : this.confirm_password,
        'first_name' : this.first_name,
        'last_name' : this.last_name,
        'email' : this.email,
        'user_device': this.user_device,
        'custom1' : this.custom1,
        'custom2' : this.custom2,
      };
      ApplicationUserDataService.CreateUser(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.$router.push("/users");
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response) {
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
    FilterDeviceList() {
      let data = {
        profile_id: "",
        serial_number: "",
        user_id: "",
        hide_assign_devices: this.search_hide_assign_devices,
        type: "",
        imei_number: "",
        model: "",
      }
      DeviceProceDataService.GetProfileDeviceList(data)
        .then((response) => {
          if (response.data.data) {
            this.devices = response.data.data;
            this.user_device = null;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetDevicesList();
  },
};
</script>
<style>
@import '../../assets/css/common.css';
hr {
  border: 0;
  clear:both;
  width: 100%;               
  background-color:#555855;
  height: 1px;
}
input[type="text"]:disabled {
    background-color: #dddddd;
}
</style>