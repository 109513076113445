import http from "../http-common";

class ApplicationDataService {
    CreateWebToken(data) {
        return http.post("application/create/web/token", data);
    }
    GetApplicationList(profile_id = "", type="", is_manual_app="", is_vpp_app = false, package_name = "") {
        return http.get("application/list?profile_id="+profile_id+"&type="+type+"&is_manual_app="+is_manual_app+"&is_vpp_app="+is_vpp_app+"&package_name="+package_name);
    }
    CreateApplication(data) {
        return http.post("application/create", data);
    }
    DeleteApplication(data) {
        return http.post("application/delete", data);
    }
    ListLinkProfiles(data) {
        return http.get("application/profile-list", { params: data });
    }

    // Application Config
    GetApplicationConfigList(package_name,profile_id) {
        return http.get("application/config/list?package_name="+package_name+"&profile_id="+profile_id);
    }
    CreateApplicationConfig(data) {
        return http.post("application/create/config", data);
    }
    DeleteApplicationConfig(data) {
        return http.post("application/delete/config", data);
    }
    ApplyAppConfigToPolicy(data) {
        return http.post("application/apply/config", data);
    }
    GetApplicationPermission(data) {
        return http.post("application/permission/detail", data);
    }
    UpdateAppPermission(data) {
        return http.post("application/permission/update", data);
    }
    SyncWebApp() {
        return http.get("application/sync/webapp");
    }
    AddWebApp(data) {
        return http.post("application/create/webapp", data);
    }
    SearchPlayStoreApp(app_name, country="us") {
        return http.get("application/playstore/search?app_name="+app_name+"&country="+country);
    }
    SearchAppStoreApp(app_name, country) {
        return http.get("application/appstore/search?app_name="+app_name+"&country="+country);
    }
    CreateIosApplication(data) {
        return http.post("application/create/ios", data);
    }
    CreateEnterpriseApp(data) {
        return http.post("application/create/enterprise-app", data,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }
    GetContentPublicLink(id) {
        return http.get("application/enterprise-app/ipa-link?id="+id);
    }
    SaveIosManageConfig(data) {
        return http.post("application/save/app-config", data);
    }
}

export default new ApplicationDataService();