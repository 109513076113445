<template>
  <div>
    <div class="md-layout" :style="{ 'justify-content': 'center' }">
          <div class="md-layout-item md-size-75">
            <tabs :tab-name="['Android', 'IOS']" color-button="warning">
              <template slot="tab-pane-1">
                  <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <tabs :tab-name="['BYOD', 'Company Owned', 'Default Enterprise']" color-button="info">
                      <template slot="tab-pane-1">
                          <div class="md-layout" :style="{padding: '10px'}">
                            <div class="md-layout-item md-size-60 p-3">
                              <ol class="ul-text-format">
                                  <li>Open your camera and scan this QR code. 
                                    <br><small class="text-info">Some device didn't support in built scanner, for such devices use external application to scan QR code.</small>
                                  </li>
                                  <li>After scanning this QR code, you will redirect to web browser or you will get http link, Open that link to browser.</li>
                                  <li>Install the work profile and follow the instruction given in installation.</li>
                                  <li><a @click="$router.push({ path: 'view-profiles', query: { profile_id: profile_id }})" href="javascript:void(0)">Click Here</a> to add or remove applications, or changes any restrictions.</li>
                              </ol>
                            </div>
                            <div class="md-layout-item md-size-40 t-center">
                              <img :src="byow_qr_code"/>
                              <a href="javascript:void(0)" @click="shareQrCodeModal = true"><md-icon label="Share" :style="{ color:'green' }">email</md-icon></a>
                            </div>
                          </div>
                      </template>
                      <template slot="tab-pane-2">
                        <div class="md-layout" :style="{padding: '10px'}">
                            <div class="md-layout-item md-size-50 p-3">
                              <ol class="ul-text-format">
                                <li>Factory reset your device</li>
                                <li>Tap the welcome screen six times in the same spot to launch the QR code scanner.</li>
                                <li>Scan this QR Code on all your devices.</li>
                              </ol>
                            </div>
                            <div class="md-layout-item md-size-50 t-center">
                              <img :src="normal_qr_code" style="width: 100% !important;" />
                              <a href="javascript:void(0)" @click="shareQrCodeModal = true"><md-icon label="Share" :style="{ color:'green' }">email</md-icon></a>
                            </div>
                          </div>
                      </template>
                      <template slot="tab-pane-3">
                        <div class="md-layout" :style="{padding: '10px'}">
                            <div class="md-layout-item md-size-100 p-3">
                              <ol class="ul-text-format">
                                <li>This QR code is generated from a default enterprise, so all devices enrolled using this devices are managed by our default enterprise.</li>
                                <li>You can create your own enterprise from  the <a @click="$router.push('android-enterprise')" href="javascript:void(0)">Android Enterprise</a> menu.</li>
                                <li>You can switch to personal enterprise later on. When you switch to personal enterprise, you need to delete all your existing profiles which you had created in default enterprise and re-enroll your devices to the newly created profile in your personal enterprise.</li>
                            </ol>
                            </div>
                        </div>
                      </template>
                    </tabs>
                  </div>
                </div>
              </template>
              <template slot="tab-pane-2">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <tabs :tab-name="['Qr Code', 'Default APN']" color-button="info">
                      <template slot="tab-pane-1">
                        <div class="md-layout" :style="{padding: '10px'}">
                          <div class="md-layout-item md-size-50 p-3">
                            <ol class="ul-text-format">
                                <li>Open your camera and scan this QR code. 
                                  <br><small class="text-info">Some device didn't support in built scanner, for such devices use external application to scan QR code.</small>
                                </li>
                                <li>After scanning this QR code, you will redirect to web browser or you will get http link, Open that link to the browser.</li>
                                <li>Once link is opend to browser one installation file will be downloaded, please install that file and follow the instructions.</li>
                                <li><a @click="$router.push({ path: 'view-ios-profiles', query: { profile_id: iosProfileId }})" href="javascript:void(0)">Click Here</a> to add or remove applications, or changes any restrictions.</li>
                            </ol>
                          </div>
                          <div class="md-layout-item md-size-50 t-center">
                            <img :src="iosQrCode"/>
                            <span style="font-size: 11px;font-weight: 500;">Install Using Link : 
                              <p class="text-primary">{{iosQrCodeLink}} <a href="javascript:void(0)" style="color:green;" @click="CopyProfileContent(iosQrCodeLink)"><i class="fa fa-clone" aria-hidden="true"></i></a></p>
                            </span>
                          </div>
                        </div>
                      </template>
                      <template slot="tab-pane-2">
                        <div class="md-layout" :style="{padding: '10px'}">
                            <div class="md-layout-item md-size-100 p-3">
                              <ol class="ul-text-format">
                                <li>This QR code is generated from a default APN, so all devices enrolled using this devices are managed by our default APN.</li>
                                <li>You can create your own APN from the <a @click="$router.push('configure-ios')" href="javascript:void(0)">Apple Setup</a> menu.</li>
                                <li>You can switch to personal APN later on. When you switch to personal APN, you need to delete all your existing profiles which you had created in default APN and re-enroll your devices to the newly created profile in your personal APN.</li>
                            </ol>
                            </div>
                        </div>
                      </template>
                    </tabs>
                  </div>
                </div>
                
              </template>
            </tabs>
          </div>
    </div>
     <!--Send Email Modal -->
    <template>
        <modal v-if="shareQrCodeModal" @close="shareQrCodeModal = false">
          <template slot="header">
            <h4 class="modal-title">Email QR Code</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="shareQrCodeModal = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
          <div class="box-body">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-field>
                  <label>Email <small class="text-danger">*</small></label>
                  <md-input type="text" v-model="emailIds"></md-input>
                </md-field>
                <small class="text-danger float-left">Enter multiple email address comma seperated.</small>
              </div>
            </div>
          </div>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="shareQrCodeModal = false">Close</md-button>
            <md-button class="md-success" :disabled="isDisabled" @click="sendQrCodeEmail()">Send</md-button>
          </template>
        </modal>
    </template> 
  </div>
</template>
<script>
import QrCodeDataService from "../../services/QrCodeDataService";
import { Tabs } from "@/components";
import { Modal } from "@/components";
export default {
  components: {
    Tabs,
    Modal
  },
  data() {
    return {
      isDisabled: false,
      show_byod_qr_code: true,
      barcode: "",
      byow_qr_code: "",
      normal_qr_code: "",
      enterprise_token : "",
      currentQrCodeID: "",
      profile_id: "",
      shareQrCodeModal: false,
      emailIds: "",
      iosQrCode: "",
      iosProfileId: "",
      iosQrCodeLink:""
    }
  },
  methods: {
    getDefaultQrCode() {
      QrCodeDataService.getDefaultQrCode()
        .then((response) => {          
          if (response.data.data) {
            this.currentQrCodeID = response.data.data._id;
            this.profile_id = response.data.data.profile_id;
            this.barcode = response.data.data.byod_qr_code_url;
            this.byow_qr_code = response.data.data.byod_qr_code_url;
            this.normal_qr_code = response.data.data.qr_code_url;
            this.enterprise_token = "https://enterprise.google.com/android/enroll?et="+response.data.data.enrollment_token_response.vaule
            this.iosQrCode = response.data.iosDefaultQrCode.qr_code_url;
            this.iosProfileId = response.data.iosDefaultQrCode.profile_id;
            this.iosQrCodeLink = response.data.iosDefaultQrCode.enrollment_token_response.value;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    toggleQrCode() {
      let old_qr_code = this.barcode;
      this.barcode = (this.show_byod_qr_code) ? this.byow_qr_code : this.normal_qr_code;
      this.byow_qr_code = old_qr_code;

      let data = { id : this.currentQrCodeID, current_qr_code: (this.show_byod_qr_code == true) ? "BYOD" : "Normal" };
      QrCodeDataService.UpdateQrCode(data)
        .then((response) => {
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
    sendQrCodeEmail() {
      this.isDisabled = true;
      let data = { emailIds: this.emailIds, id: this.currentQrCodeID };
      QrCodeDataService.sendQrCodeEmail(data)
        .then((response) => {          
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.emailIds = "";
          this.shareQrCodeModal = false;
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    CopyProfileContent(content) {
        navigator.clipboard.writeText(content);
        this.$toast.success("text copied");
    },
  },
  beforeMount() {
    this.getDefaultQrCode();
  }
}
</script>