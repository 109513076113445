<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <h5 class="text-primary">
          <b>Package Name : {{ package_name }}</b>
        </h5>
      </div>
    </div>
    <div style="float: right">
      <md-button class="md-danger" @click="$router.push({ path: 'view-ios-profiles', query: { profile_id:  profile_id  }})">Back</md-button>&nbsp;
      <md-button class="md-success" @click="SaveManageConfig()">Save</md-button>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div v-if="tableData.length > 0" style="width: 100%">
            <div v-for="(textField, i) in tableData" :key="i" >
              <div class="md-layout-item text-fields-row">
                <md-field>
                  <label>Key</label>
                  <md-input type="text" :value="textField.key" name="key[]"></md-input>&nbsp;
                </md-field>
                <md-field>
                  <label>Value</label>
                  <md-input type="text" :value="textField.value" name="value[]"></md-input>&nbsp;
                </md-field>
                <md-button class="md-just-icon md-danger" @click="DeleteManageConfig(i)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </md-button>
              </div>
            </div>
          </div>
          <div v-for="(textField, i) in textFields" :key="i" style="width: 100%">
            <div class="md-layout-item text-fields-row">
              <md-field>
                <label>{{ textField.label }}</label>
                <md-input type="text" name="key[]"></md-input>&nbsp;
              </md-field>
              <md-field>
                <label>Value</label>
                <md-input type="text" name="value[]"></md-input>&nbsp;
              </md-field>
              <md-button class="md-just-icon md-danger" @click="deleteTextBox(i)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </md-button>
            </div>
          </div>
        </div>
        <md-button class="md-primary" @click="addTextBox">Add More</md-button>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import ApplicationDataService from "../../services/ApplicationDataService";
export default {
  components: {},
  data() {
    return {
      profile_id: this.$route.query.profile_id,
      package_name: this.$route.query.package_name,
      tableData: [],
      textFields: [
        {
          label: "Key",
          value: "",
        },
      ],
    };
  },
  methods: {
    GetApplicationConfigList() {
      ApplicationDataService.GetApplicationConfigList(
        this.$route.query.package_name,
        this.profile_id
      )
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data[0].configurations
            this.textFields = [];
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    
    SaveManageConfig() {
      let keyMissing = false;
      let valueMissing = false;

      let keys = $("input[name='key[]']")
        .map(function(){
            if($(this).val()) {
              return $(this).val();
            } else {
              keyMissing = true;
            }
          }
        ).get();
      
      let values = $("input[name='value[]']")
        .map(function(){
            if($(this).val()) {
              return $(this).val();
            } else {
              valueMissing = true;
            }
          }
        ).get();

      if (keys.length == 0) {
        return this.$toast.error("Add keys & value to save it.");
      }
      if (keyMissing) {
        return this.$toast.error("Some of the key is missing, please fill it first.");
      }
      if (valueMissing) {
        return this.$toast.error("Some of the value is missing, please fill it first.");
      }
      
      let data = {
        profile_id: this.profile_id,
        package_name: this.package_name,
        keys: keys,
        values: values
      }
      ApplicationDataService.SaveIosManageConfig(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    addTextBox() {
      this.textFields.push({
        label: "Key",
        value: "",
      });
    },
    deleteTextBox(index) {
      this.textFields.splice(index, 1);
    },
    DeleteManageConfig(index) {
      this.tableData.splice(index, 1);
    },
  },

  beforeMount() {
    this.GetApplicationConfigList();
  },
};
</script>
<style>
</style>
