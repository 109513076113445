<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <simple-wizard>
        <template slot="header">
          <h3 class="title">Setup Your Device</h3>
          <h5 class="category">
            Complete all below step for setup.
          </h5>
          <button type="button" @click="$router.push({ path: 'dashboard'})" class="md-button md-simple md-just-icon md-round modal-default-button md-theme-default" style="height: 25px;"><div class="md-ripple"><div class="md-button-content"><i class="fa fa-window-close" aria-hidden="true"></i></div></div></button>
        </template>

        <!--<wizard-tab :before-change="() => validateEnterprose()">
          <template slot="label">EMM</template>
          <div class="alert alert-secondary alert-dismissible fade show ul-text-format" role="alert">
            <strong>Info!</strong> To unenroll your existing gmail account, please follow below step.
            <ul>
              <li>Open google chrome -> Login to your gmail account & Paste 
                <a href="https://play.google.com/work" target="_blank"><b>play.google.com/work</b></a> in new tab
              </li>
              <li>Click on Admin Setting from left panel</li>
              <li>Click on <b style="color:black;">⋮</b> from Organization Information & Select Delete Organization</li>
            </ul>
          </div>
          <p class="ul-text-format">Android Enterprise (AFW) provides a streamlined mechanism to enroll, manage, and secure your corporate-owned as well as employee-owned (BYOD) devices. NovaEMM seamlessly integrates with Android Enterprise.</p>
          <div v-if="!enterprise_id"  class="ul-text-format">
            <p><b>To create Android Enterprise click on below button and complete the registration</b></p>
            <div class="text-center">
              <md-button class="md-primary" @click="GenerateSignupUrl()">Create Android Enterprise</md-button>
            </div>
          </div>
          <div v-else class="ul-text-format">
              <div class="w3-responsive">
                <table class="ws-table-all">
                  <tbody>
                    <tr>
                      <th style="width: 30%">Enterprise Id</th>
                      <td>{{ enterprise_id }}</td>
                    </tr>
                    <tr>
                      <th>Enterprise Name</th>
                      <td>{{ enterprise_name }}</td>
                    </tr>
                    <tr>
                      <th>Enterprise Email</th>
                      <td>{{ enterprise_email }}</td>
                    </tr>
                    <tr>
                      <th>Enterprise Phone</th>
                      <td>{{ enterprise_phone }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </wizard-tab>-->

        <wizard-tab :before-change="() => validateCreateProfile()">
          <template slot="label">Profile</template>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <md-input type="text" class="box-textbox" placeholder="Profile Name" v-model="profile_name"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-input type="text" class="search_text_box" v-model="search_application" placeholder="Search Applications" ></md-input>
              <button class="search_btn" :disabled="isDisabled" @click="SearchPlayStoreApp()"><i class="fa fa-search"></i></button>
              <h4 class="text-success" style="margin:0;"><b>Search and approve application to kiosk it in next step</b></h4>
            </div>
            <div class="md-layout-item md-size-100">
              <div v-if="profile_applications.length > 0">
                <div class="profile-app-listing box-shadow" v-if="profile_applications">
                    <div class="screencellbook" v-for="app in profile_applications" :key="app.appId">
                      <img :src="app.icon">
                      <a class="badge-icon" href="javascript:void(0);" @click="DeleteFromProfileApplication(app)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </a>
                      <span class="apptext" :title="app.title">{{ app.title }}</span>
                    </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <div v-if="play_store_applications">
                <div class="app-listing box-shadow" v-if="play_store_applications">
                    <div class="screencellbook" v-for="app in play_store_applications" :key="app.appId">
                      <img :src="app.icon">
                      <a class="badge-icon" href="javascript:void(0);" @click="AddToProfileApplication(app)">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </a>
                      <span class="apptext" :title="app.title">{{ app.title }}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
          
        </wizard-tab>

        <wizard-tab :before-change="() => validateKiosk()">
          <template slot="label">Kiosk</template>
          <div class="md-layout-item md-size-100">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-radio class="custom-radio" v-model="kiosk_type" value="None">None</md-radio>
                <md-radio class="custom-radio" v-model="kiosk_type" value="singleApp" key="101">Single App</md-radio>
                <md-radio class="custom-radio" v-model="kiosk_type" value="MULTI APP" key="102">Multi App</md-radio>
                <md-radio class="custom-radio" v-model="kiosk_type" value="BROWSER" key="103">Browser</md-radio>
              </div>
            </div>
          </div>
          <!-- Single App Kiosk-->
          <div class="md-layout-item md-size-100" v-if="kiosk_type == 'singleApp'">
            <div v-if="profile_applications.length > 0">
              <h4><b>Select one application to lockdown your devices.</b></h4>
              <ul id="product-directory">
                <li class="li-class" v-for="app in profile_applications" :key="app.appId">
                  <input type="radio" class="radio-d-none" name="single_app_kiosk_package" v-model="single_app_kiosk_package" :value="app.appId" :id="app.appId" />
                  <label class="label" :for="app.appId"><img :src="app.icon" /></label>
                  <span class="apptext">{{ app.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          
          <!-- Multi App Kiosk-->
          <div class="md-layout-item md-size-100" v-if="kiosk_type == 'MULTI APP'">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <p>Enable Kiosk custom launcher with all approved apps. To add or remove apps from the custom launcher please use the Applications menu.</p>
              </div>
              <div class="md-layout-item md-size-100">
                  <div v-if="profile_applications.length > 0">
                    <div class="profile-app-listing box-shadow" v-if="profile_applications">
                        <div class="screencellbook" v-for="app in profile_applications" :key="app.appId">
                          <img :src="app.icon">
                          <a class="badge-icon" href="javascript:void(0);" @click="DeleteProfileApplication(app)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </a>
                          <span class="apptext" :title="app.title">{{ app.title }}</span>
                        </div>
                    </div>
                  </div>
              </div>
            </div>  
          </div>

          <!-- Browser App Kiosk-->
          <div class="md-layout-item md-size-100" v-if="kiosk_type == 'BROWSER'">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                  <md-field>
                    <label>Website Url</label>
                    <md-input type="text" v-model="browser_kiosk_website_url"></md-input>
                  </md-field>
              </div>
              
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 mobile-responsive">
                  <md-field>
                    <label>Screen Timeout</label>
                    <md-input type="number" v-model="browser_kiosk_screen_timeout"></md-input>
                    <span class="md-helper-text">Pass number in <b>Seconds</b>.</span>
                  </md-field>
              </div>
              <div class="md-layout-item md-size-50 mobile-responsive">
                  <md-field>
                    <label>Screen Brightness</label>
                    <md-input type="number" v-model="browser_kiosk_screen_brightness" max="255"></md-input>
                    <span class="md-helper-text">Pass number in <b>0 to 255</b>.</span>
                  </md-field>
              </div>
            </div>
            <br>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 mobile-responsive">
                  <md-field>
                    <label>Screen Orientation</label>
                    <md-select v-model="browser_kiosk_screen_orientation">
                      <md-option value="Auto">Auto</md-option>
                      <md-option value="Portrait">Portrait</md-option>
                      <md-option value="Landscape">Landscape</md-option>
                    </md-select>
                  </md-field>
              </div>
              <div class="md-layout-item md-size-50 mobile-responsive">
                  <md-checkbox class="custom-checkbox" v-model="browser_kiosk_show_address_bar">Show Address Bar</md-checkbox>
              </div>
            </div>
            
          </div>
        </wizard-tab>
        
        <wizard-tab :before-change="() => validateCreateConfiguration()">
          <template slot="label">QR Code</template>
          <div class="md-layout" v-if="!display_barcode">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <md-input type="text" class="box-textbox" placeholder="Configuration Name" v-model="configuration_name"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <md-input type="text" class="box-textbox" disabled placeholder="Profile Name" v-model="profile_name"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <md-input type="text" class="box-textbox" id="wifi_ssid" placeholder="SSID Name" v-model="wifi_ssid"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <select v-model="wifi_security" class="box-textbox" style="width:100%">
                  <option value="None" selected>None</option>
                  <option value="WPA">WPA</option>
                  <option value="WEP-PSK">WEP-PSK</option>
                  <option value="WPA-PSK">WPA-PSK</option>
                </select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-field>
                <md-input type="password" class="box-textbox" placeholder="WiFi Password" id="wifi_passphrase" v-model="wifi_passphrase"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout" v-if="display_barcode">
            <md-switch v-model="show_byod_qr_code" @change="toggleQrCode()">Show BYOD QR Code</md-switch>
            <div class="md-layout-item md-size-100">
              <span style="font-size: 11px;font-weight: 500;">Install Using Link : 
                <p class="text-primary">{{enterprise_token}} <a href="javascript:void(0)" style="color:green;" @click="CopyProfileContent(enterprise_token )"><i class="fa fa-clone" aria-hidden="true"></i></a></p>
              </span>
            </div>
            <div class="md-layout-item md-size-18 md-small-size-100 img-center">
                <div class="android-img">
                  <img :src="barcode" />
                </div>
            </div>
          </div>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import EnterpriseDataService from "../../services/EnterpriseDataService";
import ApplicationDataService from "../../services/ApplicationDataService";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import QrCodeDataService from "../../services/QrCodeDataService";

export default {
  data() {
    return {
      isDisabled: false,
      wizardModel: {},
      
      // Enterprise Tab
      enterprise_id: null,
      enterprise_name: null,
      enterprise_email: null,
      enterprise_phone: null,

      // Profile
      profile_name: "Default Profile",
      profile_id: null,
      search_application: null,
      play_store_applications: null,
      profile_applications: [
        {
          appId: "com.android.chrome",
          currency: "USD",
          icon: "https://play-lh.googleusercontent.com/KwUBNPbMTk9jDXYS2AeX3illtVRTkrKVh5xR1Mg4WHd0CG2tV4mrh1z3kXi5z_warlk",
          summary: "Fast, simple, and secure. Google Chrome browser for Android phones and tablets.",
          title: "Google Chrome: Fast & Secure"
        }
      ],

      // Kiosk
      kiosk_wizard_sucess: false,
      kiosk_type: "None",
      single_app_kiosk_package: null,

      // Multi App Kiosk
      multi_app_kiosk_google_custom_launcher: true,

      // Browser kiosk
      browser_kiosk_screen_timeout: "0",
      browser_kiosk_screen_brightness: "0",
      browser_kiosk_screen_orientation: "Auto",
      browser_kiosk_website_url: "",
      browser_kiosk_close_on_touch: false,
      browser_kiosk_enable_screen_saver: false,
      browser_kiosk_show_address_bar: false,


      //Qr Code
      configuration_name:  null,
      wifi_ssid: null,
      wifi_security: "None",
      wifi_passphrase: null,
      barcode: "",
      enterprise_token: "",
      display_barcode: false,
      show_byod_qr_code: false,
      byow_qr_code: "",
      normal_qr_code: "",
    };
  },
  components: {
    SimpleWizard,
    WizardTab,
  },
  methods: {
    validateEnterprose() {
      if (this.enterprise_id) {
        return true;
      } else {
        this.$toast.error("Please create enterprise first.");
      }
    },
    async validateCreateProfile() {
      if (this.profile_name) {
        var data = {
          profile_name: this.profile_name,
          exit_passcode: 123456,
          profile_type: "Android",
          profile_applications: this.profile_applications
        };
  
      await DeviceProceDataService.CreateProfile(data)
        .then((response) => {
          this.profile_id = response.data.data._id;
          this.configuration_name = "Qr Code For " + this.profile_name;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
      } else {
        this.$toast.error("Profile name required.");
      }
      if (this.profile_id) {
        this.resizeWindow();
        return true;
      }
    },
    async validateKiosk() {
      let data = {
        profile_id: this.profile_id,
        kiosk_type: this.kiosk_type
      }
      if (this.kiosk_type == "singleApp") {
          if (!this.single_app_kiosk_package) {
            this.$toast.error("Please select one application.");
            return false;
          }
          data.single_app_kiosk_package_name = this.single_app_kiosk_package;
      }
      if (this.kiosk_type == "MULTI APP") {
          data.multi_app_kiosk_google_custom_launcher = true;
      }
      if (this.kiosk_type == "BROWSER") {
          data.browser_kiosk_screen_timeout = this.browser_kiosk_screen_timeout;
          data.browser_kiosk_screen_brightness = this.browser_kiosk_screen_brightness;
          data.browser_kiosk_screen_orientation = this.browser_kiosk_screen_orientation;
          data.browser_kiosk_website_url = this.browser_kiosk_website_url;
          data.browser_kiosk_show_address_bar = this.browser_kiosk_show_address_bar;
      }
      
      await DeviceProceDataService.UpdateProfileKiosk(data)
        .then((response) => {
          this.kiosk_wizard_sucess = true;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });

      if (this.kiosk_wizard_sucess) {
        setTimeout("$('#wifi_ssid').val('')",1000);
        setTimeout("$('#wifi_passphrase').val('')",1000);
        return true;
      }
    },
    async validateCreateConfiguration() {
        var data = {
          profile_id: this.profile_id,
          configuration_name: this.configuration_name,
          wifi_ssid: this.wifi_ssid,
          wifi_security: this.wifi_security,
          wifi_passphrase: this.wifi_passphrase,
        };
        await QrCodeDataService.CreateQrCode(data)
          .then((response) => {
              this.$toast.success(response.data.respones_msg);
              this.display_barcode = true;
              this.barcode = response.data.qrcode_url;
              this.normal_qr_code = response.data.qrcode_url;
              this.enterprise_token = "https://enterprise.google.com/android/enroll?et="+response.data.data.enrollment_token_response.value;
              this.byow_qr_code = response.data.byod_qr_code_url;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
          if (this.barcode) {
            this.wizardComplete();
            return true;
          }
    },
    toggleQrCode() {
      let old_qr_code = this.barcode;
      this.barcode = (this.show_byod_qr_code) ? this.byow_qr_code : this.normal_qr_code;
      this.byow_qr_code = old_qr_code;
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    wizardComplete() {
      Swal.fire({
        title: "Good job!",
        text: "Factory reset your device, tap the welcome screen six times in the same spot to launch the QR code scanner, and scan all your devices!",
        type: "success",
        confirmButtonClass: "md-button md-success",
        buttonsStyling: false,
      });
    },
    GenerateSignupUrl() {
      var data = {};
      EnterpriseDataService.GenerateSignupUrl(data)
        .then((response) => {
          window.location.href = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    AddToProfileApplication(app) {
      let updated_play_store_application = this.play_store_applications.filter(elem => elem.appId != app.appId)
      this.profile_applications.push(app);
      this.play_store_applications = updated_play_store_application;
    },
    DeleteFromProfileApplication(app) {
      if (this.profile_applications.length == 1) {
        this.profile_applications = [];
      } else {
        let updated_play_store_application = this.profile_applications.filter(elem => elem.appId != app.appId)
        this.play_store_applications.push(app);
        this.profile_applications = updated_play_store_application;
      }
    },
    SearchPlayStoreApp() {
        if (!this.search_application) {
          this.$toast.error("Please enter application name to search");
        }
        this.isDisabled = true;
        ApplicationDataService.SearchPlayStoreApp(this.search_application)
        .then((response) => {
          this.play_store_applications = response.data.data;
          this.isDisabled = false;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
        });    
    },
    GetEnterpriseDetal() {
      var data = {};
      EnterpriseDataService.GetEnterpriseDetal(data)
        .then((response) => {
          if (response.data.data) {
            this.enterprise_id = response.data.data.name;
            this.enterprise_name = (response.data.data.enterprise_detail) ? response.data.data.enterprise_detail.contactInfo.dataProtectionOfficerName : "-";
            this.enterprise_email = (response.data.data.enterprise_detail) ? response.data.data.enterprise_detail.contactInfo.dataProtectionOfficerEmail : "-";
            this.enterprise_phone = (response.data.data.enterprise_detail) ? response.data.data.enterprise_detail.contactInfo.dataProtectionOfficerPhone : "-";
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    DeleteProfileApplication(app) {
      var data = {
        package_name: app.appId,
        profile_id: this.profile_id,
      };

      DeviceProceDataService.DeleteProfileApplication(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          let updated_play_store_application = this.profile_applications.filter(elem => elem.appId != app.appId)
          this.play_store_applications.push(app);
          this.profile_applications = updated_play_store_application;
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    resizeWindow() {
      if ($(window).width() < 600) {
        $('.mobile-responsive').removeClass('md-size-50').addClass('md-size-100');
      }
    },
    CopyProfileContent(content) {
        navigator.clipboard.writeText(content);
        this.$toast.success("text copied");
      },
  },
  beforeMount() {
    this.GetEnterpriseDetal();
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
};
</script>
<style scoped>
.text-center{
  text-align: center;
}
.box-textbox{
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
}
.app-listing {
    margin-top: 15px;
    min-height: 345px;
    overflow-y: auto;
    height: 345px;
}
.box-shadow {
    border-radius: 0 0 2px 2px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 14%);
    padding: 25px;
    background-color: #fff;
}
.app-listing .screencellbook {
    margin: 5px;
}
.profile-app-listing {
    margin-top: 15px;
    overflow-y: auto;
}
.profile-app-listing .screencellbook {
    margin: 5px;
}
.screencellbook {
    width: 81px;
    font-size: 12px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
}
.badge-icon {
    color: black !important;
}

.ws-table-all th {
  text-align: left;
}

.ul-class {
    list-style-type: none;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
}

.li-class {
  display: inline-block;
  text-align: center;
}

.radio-d-none {
  display: none;
}

.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.label img {
  height: 75px;
  width: 75px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + .label {
  border-color: #ddd;
}

:checked + .label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:not(:checked) + .label:before {
  content: "";
  background-color: #ffffff;
  transform: scale(0.5);
}

:checked + .label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}

.exit-btn {
    float: right;
    margin-top: -60px;
    margin-right: 10px;
}

.search_text_box {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  float: left;
  width: 80%;
}

.box-textbox::placeholder {
  color: unset !important;
  font: inherit;
  font-weight: unset !important;
}
.box-textbox:focus {
  color: unset !important;
  border-color: unset !important;
}
</style>