<template>
    <div>
        <div style="float: right">
            <md-button class="md-success" @click="CreateEnterpriseAppModal = true">Create</md-button>
        </div>
        <md-card>
            <md-card-content>
            <md-table
                :value="queriedData"
                class="paginated-table table-striped table-hover" 
                table-header-color="green"
                md-fixed-header
            >
            <md-table-toolbar>
                <md-field>
                    <label for="pages">Per page</label>
                    <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                        {{ item }}
                    </md-option>
                    </md-select>
                </md-field>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Package Name">
                    <img :src="item.app_icon" style="height: 50px;width: 50px;" /> 
                    <span v-if="!item.is_web_app"> {{ item.package_name }} </span>
                    <span v-else> {{ item.application_details.title }} </span>
                </md-table-cell>
                <md-table-cell md-label="Product Id">{{ item.product_id }}</md-table-cell>
                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                </md-table-row>
            </md-table>
            </md-card-content>

            <md-card-actions md-alignment="space-between">
            <div class="">
                <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
            </div>
            <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
            >
            </pagination>
            </md-card-actions>
            
        </md-card>
        <!-- Models -->
      <template>
          <modal v-if="CreateEnterpriseAppModal" @close="CreateEnterpriseAppModal = false">
            <template slot="header">
              <h4 class="modal-title">Add Enterprise App</h4>
              <md-button style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="CreateEnterpriseAppModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-field>
                            <md-input type="text" class="box-textbox" placeholder="Package Name" v-model="package_name"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <label><b>IPA File: </b></label>
                        <input type="file" @change="handleFileUpload( $event )"/>
                    </div>
                    <br><br><br>
                    <div class="md-layout-item md-size-100">
                        <label><b>App Icon: </b></label>
                        <input type="file" @change="handleAppIconFileUpload( $event )"/>
                    </div>
                </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="CreateEnterpriseAppModal = false">Close</md-button>
              <md-button class="md-success" :disabled="isDisabled" @click="CreateEnterpriseApp()">
                Add <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import ApplicationDataService from "../../services/ApplicationDataService";
import { Pagination } from "@/components";
import { Modal } from "@/components";

export default {
    components: {
        Modal,
        Pagination
    },
    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
            result = this.searchedData;
        }
        return result.slice(this.from, this.to);
        },
        to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
        },
        from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
        return this.searchedData.length > 0
            ? this.searchedData.length
            : this.tableData.length;
        },
    },
    data() {
        return {
            isDisabled: false,
            tableData: [],
            search_application: "",
            profile_applications: [],
            app_store_applications: [],
            updated_app_store_application: [],
            
            // Pagination
            pagination: {
                perPage: 25,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            searchedData: [],

            //Create App
            CreateEnterpriseAppModal: false,
            package_name: "",
            ipa_file: "",
            app_icon: "",
        }
    },
    methods: {
        GetApplicationList() {
        ApplicationDataService.GetApplicationList("", "Ios", true)
            .then((response) => {
            if (response.data.data) {
                this.tableData = response.data.data;
            }
            })
            .catch((e) => {
            this.$toast.error(e);
            });
        },
        handleFileUpload(event){
            this.ipa_file = event.target.files[0];
        },
        handleAppIconFileUpload(event){
            this.app_icon = event.target.files[0];
        },
        CreateEnterpriseApp() {
            this.isDisabled =true;
            let formData = new FormData();
            formData.append("ipa_file", this.ipa_file);
            formData.append("app_icon", this.app_icon);
            formData.append("package_name", this.package_name);
            ApplicationDataService.CreateEnterpriseApp(formData)
            .then((response) => {
                this.isDisabled =false;
                this.package_name = "";
                this.$toast.success(response.data.respones_msg);
                this.GetApplicationList();
                this.GetContentPublicLink(response.data.data._id)
                this.CreateEnterpriseAppModal = false;
            }).catch((e) => { this.$toast.error(e.response.data.respones_msg); this.isDisabled =false; });
        },
        GetContentPublicLink(id) {
            ApplicationDataService.GetContentPublicLink(id)
            .then((response) => {
                this.GetApplicationList();
                this.$toast.success(response.data.respones_msg);
            })
            .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },
    },
    beforeMount() {
        this.GetApplicationList();
    },
}
</script>
<style scoped>
.box-textbox{
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
}
</style>