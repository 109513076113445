import http from "../http-common";

class PaymentDataService {
    Purchase(data) {
        return http.post("payment/purchase", data);
    }
    GetPlanDetail(price_id) {
        return http.get("payment/plan/details?price_id="+price_id);
    }
    UpdateSubscription(data) {
        return http.post("payment/stripe/update/subscription", data);
    }
    GetCardList(data) {
        return http.get("payment/card/list", data);
    }
    AddCard(data) {
        return http.post("payment/card/create", data);
    }
    UpdateCard(data) {
        return http.post("payment/card/update", data);
    }
    DeleteCard(data) {
        return http.post("payment/card/delete", data);
    }
    ChangeDefaultCard(data) {
        return http.post("payment/card/change/default/card", data);
    }
    ApplyCoupon(data) {
        return http.post("payment/coupon/apply", data);
    }
    GetPriceList(data) {
        return http.get("payment/price/list");
    }
    GetPaymentHistory() {
        return http.get("payment/history");
    }
}

export default new PaymentDataService();