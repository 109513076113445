<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="from_date" md-immediately>
                    <label>Start Date</label>
                  </md-datepicker>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="to_date" md-immediately>
                    <label>To Date</label>
                  </md-datepicker>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25">
                  <md-button class="md-primary" :disabled="isDisabled" @click="DbList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Database Name">{{ item.db_name  }}</md-table-cell>
              <md-table-cell md-label="Created At">{{ HumanReadableDateFormat(item.created_at)  }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import DbDataService from "../../services/SuperAdmin/DbDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      from_date: "",
      to_date: new Date().toISOString().split('T')[0],
      tableData: [],

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

    };
  },
  methods: {
    DbList() {
      DbDataService.DbList(this.from_date, this.to_date)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    let d = new Date();
    d.setMonth(d.getMonth()-1);
    this.from_date = d.toISOString().split('T')[0];
    this.DbList();
  },
};
</script>

<style>
</style>
