import http from "../http-common";

class WebinarService {
    webinarList(data = null) {
        return http.get("webinar/list", {params: data});
    }
    webinarRegistration(data) {
        return http.post("webinar/registration",data);
    }
    requestWebinar(data) {
        return http.post("webinar/request",data);
    }
}

export default new WebinarService();