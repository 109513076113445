<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <input type="file" @change="handleFileUpload( $event )" ref="myFileInput"/>
                &nbsp;&nbsp;
                <md-button class="md-success" :disabled="isDisabled" @click="DbRestore()">Restore</md-button>
              </div>
          </div>
          <md-table v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="File Name">{{ item.file_name }}</md-table-cell>
              <md-table-cell md-label="Status">
                  <span class="text-success" v-if="item.status">Success</span>
                  <span class="text-danger" v-else>Error</span>
              </md-table-cell>
              <md-table-cell md-label="Message">
                  <span class="text-success" v-if="item.status">Datanase restored Successfully.</span>
                  <span class="text-danger" v-else>{{ item.error_message }}</span>
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import DbDataService from "../../services/SuperAdmin/DbDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      contentFile: null,
    };
  },
  methods: {
    handleFileUpload(event) {
        this.contentFile = event.target.files[0];
      },

      DbRestore() {
        this.isDisabled =true;
        const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
          }.bind(this)
        }
        let formData = new FormData();
        formData.append("fileData", this.contentFile);
        DbDataService.DbRestore(formData, config)
          .then((response) => {
            this.isDisabled = false;
            this.$refs.myFileInput.value = '';
            this.contentFile = "";
            this.$toast.success(response.data.respones_msg);
            this.RestoreList();
          })
          .catch((e) => {
            this.$refs.myFileInput.value = '';
            this.contentFile = "";
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
      },
      RestoreList() {
        DbDataService.RestoreList()
          .then((response) => {
            if (response.data.data) {
              this.tableData = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      
  },
  beforeMount() {
    this.RestoreList();
  },
};
</script>

<style>
</style>
