<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>security</md-icon>
          </div>
          <h4 class="title">Privacy & Security</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout-item">
            <md-switch v-model="enable_two_step_email_verification">Enable Two Step Login</md-switch>
        </div>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-success" :disabled="isDisabled" @click="UpdateTwoStepVerification()">Save</md-button>
        </md-card-actions>
      </md-card>  
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";

export default {
  data() {
    return {
      isDisabled: false,
      enable_two_step_email_verification: false,
    }
  },
  methods: {
    UpdateTwoStepVerification() {
      this.isDisabled = true;
      var data = {
        enable_two_step_email_verification: this.enable_two_step_email_verification,
      };

      UsersDataService.UpdateTwoStepVerification(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    GetAdminDetail() {
      var data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          this.enable_two_step_email_verification = response.data.data.enable_two_step_email_verification;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
};
</script>