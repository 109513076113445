<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-success" @click="$router.push({ path: 'admin-create-webinar'})">Create Webinar</md-button>
      </div>
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }"> 
              <md-table-cell md-label="Image">
                <img v-if="item.image_link" :src="item.image_link" :id="item._id" style="height: 75;width: 75px;" @click="openFullscreen(item._id)" />
                <span v-else>-</span>
              </md-table-cell>  
              <md-table-cell md-label="Title">{{ item.title }}
                <br><a :href="item.link" target="_blank">{{ item.link }}</a>
              </md-table-cell>
              <!--<md-table-cell md-label="Description"><div v-html="item.description" /> </md-table-cell>-->
              <md-table-cell md-label="Date">
                {{ HumanReadableDateFormat(item.date, true) }} At {{ item.time }}
                <br><span v-if="item.repeat_every_week" class="expired">Every Week</span> 
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <div style="display: block ruby;">
                  <md-button class="md-just-icon md-danger md-simple" @click="deleteWebinar(item._id)"><md-icon>close</md-icon></md-button>
                  <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'admin-webinar-registrations', query: { id: item._id }})"><md-icon title="Registration List">how_to_reg</md-icon></md-button>
                  <md-button class="md-just-icon md-info md-simple" @click="$router.push({ path: 'admin-webinar-request', query: { id: item._id }})"><md-icon title="Request">app_registration</md-icon></md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <span v-if="tableData.length == 0">No data found.</span>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import WebinarService from "../../../services/SuperAdmin/WebinarService";
import Swal from "sweetalert2";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
    };
  },
  methods: {
      webinarList() {
        WebinarService.webinarList()
          .then((response) => {
            if (response.data.data) {
              this.tableData = response.data.data;
            } else {
                this.tableData = [];
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      
      deleteWebinar(id) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let data = { "id" : id };
            WebinarService.deleteWebinar(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.webinarList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
      },
      openFullscreen(id) {
        let elem = document.getElementById(id);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      },
  },
  beforeMount() {
    this.webinarList();
  },
};
</script>

<style>
</style>
