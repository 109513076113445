<template>
  <div class="md-layout">
    <!--User Agent : {{ userAgent }}, OS : {{ os }}-->
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <md-icon>group</md-icon>
          </div>
          <p class="category">Total Users</p>
          <h3 class="title">
            <span>{{ total_users }}</span>
          </h3>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Total Devices</p>
          <h3 class="title">
            <!--<animated-number :value="total_devices"></animated-number>-->
            <span>{{ total_devices }}</span>
          </h3>
        </template>

        <!--<template slot="footer">
          <div class="stats">
            <md-icon>local_offer</md-icon>
            Tracked from Google Analytics
          </div>
        </template>-->
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>tablet</md-icon>
          </div>
          <p class="category">Total Application</p>
          <h3 class="title">
            <!--<animated-number :value="total_applications"></animated-number>-->
            <span>{{ total_applications }}</span>
          </h3>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>content_paste</md-icon>
          </div>
          <p class="category">Total Profile</p>
          <h3 class="title">
            <!--<animated-number :value="total_profile"></animated-number>-->
            <span>{{ total_profile }}</span>
          </h3>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="simpleBarChart.data"
          :chart-options="simpleBarChart.options"
          :chart-responsive-options="simpleBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Device Enrollment
              <small> - Month Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="pieChart.data"
          :chart-options="pieChart.options"
          chart-type="Pie"
          header-icon
          chart-inside-content
          background-color="green"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>pie_chart</md-icon>
            </div>
            <h4 class="title">Device Type</h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div>
              <div class="md-layout-item">
                <i class="fa fa-circle text-info"></i> Android
                <i class="fa fa-circle text-warning"></i> IOS
              </div>
            </div>
          </template>
        </chart-card>
      </div>
  </div>
</template>

<script>
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import DashboardDataService from "../../services/DashboardDataService";

import {
  StatsCard,
  ChartCard,
  //ProductCard,
  AnimatedNumber,
  //GlobalSalesCard,
  //GlobalSalesTable,
} from "@/components";


export default {
  components: {
    StatsCard,
    ChartCard,
    //AnimatedNumber,
    //ProductCard,
    //GlobalSalesCard,
    //GlobalSalesTable,
    //AsyncWorldMap,
  },
  data() {
    return {
      total_users: 0,
      total_devices: 0,
      total_applications: 0,
      total_profile: 0,
      pie_chart_data: [],
      simpleBarChart: null,
      pieChart: null,

      os: "",
      userAgent:"",
    };
  },
  methods: {
     GetDashboardData: function () {
      DashboardDataService.GetDashboardData()
      .then((response) => {
        this.total_users = response.data.data.application_user_count;
        this.total_devices = response.data.data.device_count;
        this.total_applications = response.data.data.application_count;
        this.total_profile = response.data.data.profile_count;
        this.$toast.success(response.data.respones_msg);
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
        this.isDisabled = false;
      });
    },
    CreateNextCloudUser: function () {
      DashboardDataService.CreateNextCloudUser()
      .then((response) => {
        this.$toast.success(response.data.respones_msg);
      })
      .catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
      });
    },
  },
  mounted() {
    this.userAgent = navigator.userAgent;
    this.os = navigator.platform;
    //this.GetDashboardData();
  },
  async created() {
    await DashboardDataService.GetDashboardData()
      .then((response) => {
          this.total_users = response.data.data.application_user_count;
          this.total_devices = response.data.data.device_count;
          this.total_applications = response.data.data.application_count;
          this.total_profile = response.data.data.profile_count;
          
          this.pieChart = {
            data: {
              labels: [response.data.data.device_count, "0"],
              series: [response.data.data.device_count, "0"],
            },
            options: {
              height: "230px",
            },
          }

          this.simpleBarChart = {
            data: {
              labels: ["Jan", "Fab", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
              series: [
                response.data.data.month_wise_device_count,
              ],
            },
            options: {
              height: "230px",
              seriesBarDistance: 10,
              axisX: {
                showGrid: false,
              },
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
          }

          if (response.data.data.next_cloud_account_created == false) {
              this.CreateNextCloudUser();
          }
      }).catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
      });
  },
};
</script>
<style scoped>
.md-card-chart {
  height: 430px !important;
}
</style>