<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <div style="float: left">
                <h4>
                  <p class="text-primary"> {{ profile_name }} 
                    <a href="javascript:void(0)" @click="updateProfileNameModal = true"><md-icon style="color: #00bcd4 !important">edit</md-icon></a>
                  </p>
                </h4>
            </div>
            <div style="float: right">
              <md-button class="md-success" :disabled="isDisabled" @click="ApplyPolicy()">Apply Policy<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>&nbsp;
              <md-button class="md-danger" @click="$router.push('device-profiles')">Back</md-button>
            </div>
        </div>
        <tabs :tab-name="[
          'Applications',
          'Restrictions',          
          'Kiosk',
          'Devices',
          'WiFi',
          'Qr Code'
        ]" color-button="warning">
        
        <!-- Applications Tabs -->
        <template slot="tab-pane-1">
          <md-card>
            <md-card-content>
              <div class="md-layout" v-if="applications">
                <div class="md-layout-item md-size-25">
                  <md-switch v-model="filterVppApps" @change="GetApplicationList()"><b>Show VPP Applications Only</b></md-switch>
                </div>
                <div class="md-layout-item md-size-25">
                  <md-field>
                    <md-input type="text" placeholder="Search Applications" v-model="search_package_name"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-button class="md-primary" :disabled="isDisabled" @click="GetApplicationList()">
                    Search</md-button> 
                  &nbsp;<md-button class="md-info" :disabled="isDisabled" @click="SyncVppApps()">Sync VPP</md-button>
                  &nbsp;<!--<md-button class="md-success" v-if="!filterVppApps" @click="$router.push({ path: 'app-store'})">More Apps</md-button>-->
                    <a class="md-success" v-if="filterVppApps" target="_blank" href="https://business.apple.com/">
                      <md-button class="md-success">More Apps</md-button>
                    </a>
                </div>
              </div>
              <div class="md-layout">
                <div class="app-listing-copy" v-if="applications">
                  <ul id="product-directory">
                    <li v-for="app in applications" :key="app._id">
                        <img :src=app.app_icon>
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.package_name,app.is_web_app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name" v-if="!app.application_details"><small><b>{{app.package_name }}</b></small></span>
                          <span class="apptext" :title="app.package_name" v-else><small><b>{{app.application_details.title }}</b>  ({{ app.package_name }})</small></span>
                        </div>
                    </li>
                    <!-- App Store Application Append -->
                    <li v-for="app in storeApps" :key="app.appId">
                        <img :src=app.icon style="opacity: 0.5;">
                        <a class="badge-icon" href="javascript:void(0);" @click="CreateProfileApplication(app.appId, false, app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                          <span class="apptext" :title="app.package_name"><small><b>{{app.title }}</b>  ({{ app.appId }})</small></span>
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
              <md-table
                v-model="profileApplications"
                table-header-color="green"
              >
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Package Name">
                    <div style="display:flex;">
                      <img :src="item.app_icon" style="height: 50px;width: 50px;" />&nbsp;
                    <p>{{ item.display_text }} <span v-if="item.has_title"> ({{ item.package_name }})</span></p>
                    </div>
                  </md-table-cell>
                  <md-table-cell md-label="App Disabled">
                    <input type="checkbox" class="custom-control-input" v-if="item.is_app_disabled == true" checked
                      :id="item._id" @change="UpdateAppEnableDisableStatus(item._id)">
                    <input type="checkbox" class="custom-control-input" v-else
                      :id="item._id" @change="UpdateAppEnableDisableStatus(item._id)">
                  </md-table-cell>
                  <md-table-cell md-label="App Status">
                    <select class="form-control" @change="ChangeInstallType(item.package_name,$event)">
                      <option value="AVAILABLE" v-bind:selected="item.install_type == 'AVAILABLE'">AVAILABLE</option>
                      <option value="PREINSTALLED" v-bind:selected="item.install_type == 'PREINSTALLED'">INSTALLED</option>
                      <option value="BLOCKED" v-bind:selected="item.install_type == 'BLOCKED'">BLOCK & UNINSTALL</option>
                    </select>  
                  </md-table-cell>
                  <md-table-cell md-label="Actions">
                    <div style="display: flex;">                    
                      <md-button class="md-just-icon md-danger" @click="DeleteProfileApplication(item.package_name)"><md-icon>close</md-icon></md-button>&nbsp;
                      <md-button class="md-just-icon md-primary" @click="$router.push({ path: 'manage-ios-app-config', query: { package_name: item.package_name, profile_id:  profile_id  }})"><md-icon>settings</md-icon></md-button>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </template>

        <!-- Restriction Tabs -->
        <template slot="tab-pane-2">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <md-button class="md-success" :disabled="isDisabled" @click="InstallProfile()">Save</md-button>
              </div>
              <br /><br />
              <collapse
                :collapse="['General Settings', 'Safari Settings', 'iCloud & Siri Settings','Lock Screen Settings', 'App Settings']"
                icon="keyboard_arrow_down"
                color-collapse="success"
              >
                <template slot="md-collapse-pane-1">
                  <h4><b>General</b></h4>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="allowCamera">Allow Camera</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="allowScreenShot">Allow Screen Capture</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="forceEncryptedBackup">Force Encrypted Backup</md-switch>
                    </div>
                  </div>
                  <div>
                      <h4><b>Supervised</b></h4>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowEnablingRestrictions">Allow Enabling Restrictions</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowDeviceNameModification">Allow Device Name Modification</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowWallpaperModification">Allow Wallpaper Modification</md-switch>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowExplicitContent">Allow Explicit Content</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowBluetoothModification">Allow Bluetooth Modification</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowPasscodeModification">Allow Passcode Modification</md-switch>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowUIConfigurationProfileInstallation">Allow UIConfiguration Profile Installation</md-switch>
                        </div>
                      </div>
                  </div>
                </template>

                <template slot="md-collapse-pane-2">
                  <div>
                    <h4><b>General</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="safariAllowAutoFill">Allow AutoFill</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="safariAllowJavaScript">Allow JavaScript</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="safariAllowPopups">Allow Popups</md-switch>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="safariForceFraudWarning">Force Fraud Warning</md-switch>
                        </div>
                    </div>
                    <h4><b>Supervised</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                            <md-switch v-model="allowSafari">Enable Safari</md-switch>
                        </div>
                    </div>
                  </div>
                </template>

                <template slot="md-collapse-pane-3">
                  <div>
                    <h4><b>General</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowCloudBackup">Allow Cloud Backup</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowCloudPhotoLibrary">Allow Cloud Photo Library</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowManagedAppsCloudSync">Allow Managed Apps Cloud Sync</md-switch>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowAssistant">Allow Siri</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowAssistantWhileLocked">Allow Siri While Locked</md-switch>
                        </div>
                    </div>
                    <h4><b>Supervised</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowCloudDocumentSync">Allow Cloud Document Sync</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="forceAssistantProfanityFilter">Force Siri Profanity filter</md-switch>
                        </div>
                    </div>
                  </div>
                </template>

                <template slot="md-collapse-pane-4">
                  <div>
                    <h4><b>General</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowFingerprintForUnlock">Allow Fingerprint For Unlock</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowPassbookWhileLocked">Allow Passbook While Locked</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowAutoUnlock">Allow Auto Unlock</md-switch>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowVoiceDialing">Allow Voice Dialing</md-switch>
                        </div>
                    </div>
                    <h4><b>Supervised</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowNotificationsModification">Allow Notifications Modification</md-switch>
                        </div>
                    </div>
                  </div>
                </template>

                <template slot="md-collapse-pane-5">
                  <div>
                    <h4><b>General</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowEnterpriseAppTrust">Allow Enterprise App Trust</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowBookstoreErotica">Allow Bookstore Erotica</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="forceAirDropUnmanaged">Force Air Drop Unmanaged</md-switch>
                        </div>
                    </div>
                    <h4><b>Supervised</b></h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowChat">Allow iMessage</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowAppInstallation">Allow App Installation</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowUIAppInstallation">Allow UI AppInstallation</md-switch>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowAppRemoval">Allow App Removal</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowNews">Allow News</md-switch>
                        </div>
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowMusicService">Allow Music Service</md-switch>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-33">
                          <md-switch v-model="allowBookstore">Allow Bookstore</md-switch>
                        </div>
                    </div>
                  </div>
                </template>
              </collapse>
            </md-card-content>
          </md-card>
        </template>

        <!-- Kiosk Tabs -->
        <template slot="tab-pane-3">
          <md-card>
            <md-card-content>
              <div style="float: right">
                <!--<md-button class="md-danger" v-if="this.package_name" @click="ExitKioskMode()">Exit</md-button>&nbsp;-->
                <md-button class="md-success" :disabled="isDisabled" @click="InstallProfile()">Save</md-button>
              </div>
              <br /><br />
              <div class="md-layout-item md-size-100">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="None">None</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="singleApp" key="101">Single App</md-radio>
                    <md-radio class="custom-radio" v-model="kiosk_type" @change="KioskTypeChange()" value="MULTI APP" key="102">Multi App</md-radio>
                  </div>
                </div>
              </div>
              
              <!-- Single App Kiosk-->  
              <div class="md-layout" v-if="kiosk_type == 'singleApp'">
                <div class="md-layout-item md-size-100 kiosk-div">
                    <div class="md-layout-item md-size-100">
                      <div v-if="profileApplicationsKiosk.length > 0">
                        <h4><b>Select one application to lockdown your devices.</b></h4>
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileApplicationsKiosk" :key="app._id">
                            <input type="radio" class="radio-d-none" name="package_name" v-model="package_name" :value="app.package_name" :id="app._id" />
                            <label class="label" :for="app._id"><img :src="app.app_icon" class="app-icon-single" /></label>
                            <span class="apptext">
                              <small><b>{{ app.display_text }}</b></small>
                              <small v-if="app.has_title">({{ app.package_name }})</small>
                            </span> 
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
                <div class="md-layout-item md-size-100">
                  <h4><b>Settings</b></h4>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="DisableTouch">Disable Touch</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="DisableDeviceRotation">Disable Device Rotation</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="DisableRingerSwitch">Disable Ringer Switch</md-switch>
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="DisableSleepWakeButton">Disable Sleep Wake Button</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="DisableAutoLock">Disable AutoLock</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="DisableVolumeButtons">Disable Volume Buttons</md-switch>
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="EnableVoiceOver">Enable VoiceOver</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="EnableZoom">Enable Zoom</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="EnableInvertColors">Enable Invert Colors</md-switch>
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="EnableAssistiveTouch">Enable Assistive Touch</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="EnableSpeakSelection">Enable Speak Selection</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="VoiceOver">Voice Over</md-switch>
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item">
                        <md-switch v-model="Zoom">Zoom</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="InvertColors">Invert Colors</md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-switch v-model="AssistiveTouch">Assistive Touch</md-switch>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Multi App Kiosk-->
              <div class="md-layout-item md-size-100" v-if="kiosk_type == 'MULTI APP'">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <p>Enable Kiosk custom launcher with all approved apps. To add or remove apps from the custom launcher please use the Applications menu.</p>
                  </div>
                      <div v-if="profileApplicationsKiosk.length > 0">
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileApplicationsKiosk" :key="app._id">
                              <label class="label" :for="app._id"><img :src=app.app_icon class="app-icon-single" /></label>
                              <a class="badge-icon" href="javascript:void(0);" style="color:black;" @click="DeleteProfileApplication(app.package_name)">
                                <i class="fa fa-window-close" aria-hidden="true"></i>
                              </a>
                              <span class="apptext" :title="app.display_text">
                                <small><b>{{ app.display_text }}</b></small>
                                <small v-if="app.has_title">({{ app.package_name }})</small>
                              </span>
                          </li>
                        </ul>
                  </div>
                </div>  
              </div>
            </md-card-content>
          </md-card>
        </template>

        <!-- Devices Tabs -->
        <template slot="tab-pane-4">
          <md-card>
              <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <div style="float: right;padding-right:15px;">
                          <md-button class="md-danger f-right-padding" :disabled="isDeviceBtnDisabled" @click="IssueCommand('EraseDevice')">Erase</md-button>&nbsp;
                          <md-button class="md-secondary f-right-padding" style="width:19% !important" :disabled="isDeviceBtnDisabled" @click="IssueCommand('ClearPasscode')">Clear Passcode</md-button>&nbsp;
                          <md-button class="md-warning f-right-padding" :disabled="isDeviceBtnDisabled" @click="IssueCommand('ShutDownDevice')">ShutDown</md-button>&nbsp;
                          <md-button class="md-primary f-right-padding" :disabled="isDeviceBtnDisabled" @click="IssueCommand('RestartDevice')">Reboot</md-button>&nbsp;
                          <md-button class="md-info f-right-padding" :disabled="isDeviceBtnDisabled" @click="IssueCommand('DeviceLock')">Lock</md-button>&nbsp;
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <img :src="LoaderImage" class="loader-img" style="display:none;" />
                      <md-table v-model="profileDevices" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Actions">
                            <div class="md-layout">
                              <div class="md-layout-item">
                                <md-checkbox :value="item.name" v-model="selected_devices" @change="selectDevice()"></md-checkbox>
                              </div>
                            </div>
                          </md-table-cell>
                          <md-table-cell md-label="Serial Number">
                            <a @click="$router.push({ path: 'ios-device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.hardwareInfo.serialNumber }}</a>
                          </md-table-cell>
                          <md-table-cell v-if="item.user_id" md-label="User Name">{{ item.user_id.user_name }}</md-table-cell>
                          <md-table-cell v-else md-label="User Name">-</md-table-cell>
                          <md-table-cell md-label="Hardware">{{ item.hardwareInfo.hardware }}</md-table-cell>
                          <md-table-cell md-label="Brand">{{ item.hardwareInfo.brand }}</md-table-cell>
                          <md-table-cell md-label="Model">{{ item.hardwareInfo.model }}</md-table-cell>
                          <md-table-cell md-label="Enrollment Time">{{ HumanReadableDateFormat(item.enrollmentTime)}}</md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                  <div class="md-layout-item md-size-100 md-small-size-100" v-if="profileDevices.length == 0">
                    <b>No Devices Found.</b>
                  </div>
                </div>
            </md-card-content>
          </md-card>
        </template>

        <!-- WiFi Tab -->
        <template slot="tab-pane-5">
          <md-card>
              <md-card-content>
                <div style="float: right;padding-right: 15px;">
                  <md-button class="md-success" @click="AddWifiModal = true">Add</md-button>
                </div>
                <br /><br />
                <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <md-table v-model="profileWifi" table-header-color="green">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="ssid">{{ item.wifi_ssid }}</md-table-cell>
                          <md-table-cell md-label="security">{{ item.wifi_security }}</md-table-cell>
                          <md-table-cell md-label="passphrase">{{ item.wifi_passphrase }}</md-table-cell>
                          <md-table-cell md-label="Auto Connect">{{ item.wifi_autoconnect }}</md-table-cell>
                          <md-table-cell md-label="Is Default">
                            <input type="radio" class="custom-control-input" v-if="item.is_default == true" checked
                              :id="item._id" @change="UpdateDefaultWiFi(item._id)">
                            <input type="radio" class="custom-control-input" v-else
                              :id="item._id" @change="UpdateDefaultWiFi(item._id)">
                          </md-table-cell>
                          <md-table-cell md-label="Actions">
                            <md-button class="md-just-icon md-danger" @click="DeleteProfileWiFi(item._id)"><md-icon>delete</md-icon></md-button>
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                    </div>
            </md-card-content>
          </md-card>
        </template>
        <!-- QR code Tab -->
        <template slot="tab-pane-6">
          <md-card>
            <md-card-content>
              <md-table v-model="qrCodeData" table-header-color="green">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Name">{{ item.configuration_name }}
                  </md-table-cell>
                  <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                  <md-table-cell md-label="Actions">
                    <div style="display: flex;">
                      <a  @click="OpenShowQrCodeModal(item.qr_code_url, item.enrollment_token_response,item.profile_id.profile_type)" href="javascript:void(0)" class="md-just-icon md-success"><md-icon>qr_code</md-icon></a>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </template>

        <!-- Lost Mode Tab -->
        <!--<template slot="tab-pane-6">
          <md-card>
              <md-card-content>
                <div style="float: right;padding-right: 15px;">
                  <md-button class="md-success" @click="InstallProfile()" :disabled="isDisabled">Save</md-button>
                </div>
                <br />
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-switch v-model="enable_lost_mode">Enable Lost Mode</md-switch>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Foot Note</label>
                      <md-input type="text" v-model="lock_footnote" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Lock Message</label>
                      <md-input type="text" v-model="lock_message" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Phone Number</label>
                      <md-input type="text" v-model="lock_phone_number" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                </div>
            </md-card-content>
          </md-card>
        </template>-->
      </tabs>

      <!--Add Wifi Modal -->
      <template>
          <modal v-if="AddWifiModal" @close="AddWifiModal = false;">
            <template slot="header">
              <h4 class="modal-title">Add WiFi</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="AddWifiModal = false;"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>SSID Name</label>
                    <md-input type="text" v-model="wifi_ssid"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50"> 
                  <md-field>
                    <select v-model="wifi_security" class="browser-default custom-select" style="padding: 9px;border: none;">
                      <option value="None" selected>None</option>
                      <option value="WEP">WEP</option>
                      <option value="WPA">WPA</option>
                      <option value="WPA2">WPA2</option>
                    </select>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>Passphrase</label>
                    <md-input type="text" style="background-color:unset;" v-model="wifi_passphrase" :disabled="['None'].indexOf(wifi_security) !== -1"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field class="autoconnect">
                    <md-checkbox v-model="wifi_autoconnect">Auto Connect</md-checkbox>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="AddWifiModal = false;">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="AddWifi()">Save</md-button>
            </template>
          </modal>
      </template>

      <!-- QR Code Model -->
      <template>
          <modal v-if="ShowQrCodeModal" @close="ShowQrCodeModal = false">
            <template slot="header">
              <h4 class="modal-title">Qr Code</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="ShowQrCodeModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100" v-if="enterprise_token">
                  <span style="font-size: 11px;font-weight: 500;">Install Using Link : 
                    <p class="text-primary">{{enterprise_token}} <a href="javascript:void(0)" style="color:green;" @click="CopyProfileContent(enterprise_token )"><i class="fa fa-clone" aria-hidden="true"></i></a></p>
                  </span>
                  
                </div>
                <div class="md-layout-item md-size-100">
                  <div class="android-img">
                    <img :src="barcode" />
                  </div>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="ShowQrCodeModal = false">Close</md-button>
            </template>
          </modal>
      </template>

      <!--Upfate Profile Name Modal -->
      <template>
          <modal v-if="updateProfileNameModal" @close="updateProfileNameModal = false">
            <template slot="header">
              <h4 class="modal-title">Update Profile Name</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="updateProfileNameModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <label>Profile Name</label>
                    <md-input type="text" v-model="profile_name"></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="updateProfileNameModal = false">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="InstallProfile()">Save</md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import { Tabs } from "@/components";
import { Collapse } from "@/components";
import { Modal } from "@/components";
import Swal from "sweetalert2";

import DeviceProceDataService from "../../services/DeviceProceDataService";
import IosMdmDataService from "../../services/IosMdmDataService";
import ApplicationDataService from "../../services/ApplicationDataService";
import UsersDataService from "../../services/UsersDataService";
import QrCodeDataService from "../../services/QrCodeDataService";

export default {
    components: {
        Tabs,
        Collapse,
        Modal
    },
    props: {
      LoaderImage: {
        type: String,
        default: "./img/loader.gif",
      },
    },
    data() {
        return {
            profile_name: null,
            profile_id: this.$route.query.profile_id,
            isDisabled: false,

            //Applications Tab
            filterVppApps: false,
            vppTokenSet: false,
            applications: [],
            profileApplications: [],
            search_package_name: "",
            storeApps: [],

            //Restriction -> General Tabs
            allowCamera: true,
            allowScreenShot: true,
            allowBluetoothModification: true,
            forceEncryptedBackup: false,
            allowEnablingRestrictions: false,
            allowEraseContentAndSettings: false,
            allowDeviceNameModification: false,
            allowWallpaperModification: false,
            allowExplicitContent: false,
            allowUIConfigurationProfileInstallation: false,
            allowPasscodeModification: true,

            //Restriction -> Safari Tabs
            allowSafari: false,
            safariAllowAutoFill: true,
            safariAllowJavaScript: true,
            safariAllowPopups: true,
            safariForceFraudWarning: true,

            //Restriction -> iCloud & Siri Settings
            allowCloudBackup: false,
            allowCloudPhotoLibrary: false,
            allowManagedAppsCloudSync: false,
            allowAssistant: true,
            allowAssistantWhileLocked: true,
            allowCloudDocumentSync: false,
            forceAssistantProfanityFilter: true,

            //Restriction -> Lock Screen Settings
            allowFingerprintForUnlock: true,
            allowPassbookWhileLocked: true,
            allowAutoUnlock: false,
            allowVoiceDialing: false,
            allowNotificationsModification: false,

            //Restriction -> App Settings
            allowEnterpriseAppTrust: false,
            allowBookstoreErotica: false,
            forceAirDropUnmanaged: false,
            allowChat: true,
            allowAppInstallation: false,
            allowUIAppInstallation: false,
            allowAppRemoval: false,
            allowNews: false,
            allowMusicService: true,
            allowBookstore: true,

            //Kiosk Tab
            kiosk_type: "None",
            profileApplicationsKiosk: [],
            
            // Single App Kiosk
            package_name: "",
            DisableTouch: true,
            DisableDeviceRotation: true,
            DisableRingerSwitch: true,
            DisableSleepWakeButton: true,
            DisableAutoLock: true,
            DisableVolumeButtons: true,
            EnableVoiceOver: true,
            EnableZoom: true,
            EnableInvertColors: true,
            EnableAssistiveTouch: true,
            EnableSpeakSelection: true,
            VoiceOver: true,
            Zoom: true,
            InvertColors: true,
            AssistiveTouch: true,

            //Profile Devices Tab
            profileDevices: [],
            selected_devices: [],
            isDeviceBtnDisabled: true,

            //Wifi
            profileWifi: [],
            AddWifiModal: false,
            wifi_ssid: null,
            wifi_security: "None",
            wifi_passphrase: null,
            wifi_autoconnect: false,

            //Lost Mode
            enable_lost_mode: false,
            lock_footnote: "",
            lock_message: "",
            lock_phone_number: "",

            //Qr Code
            qrCodeData: [],
            barcode: "",
            enterprise_token : "",
            ShowQrCodeModal: false,

            updateProfileNameModal: false,
        }
    },
    methods: {
        GetDeviceProfileDetail() {
            DeviceProceDataService.GetDeviceProfileDetail(this.$route.query.profile_id)
            .then((response) => {
                if (response.data.data) {
                    this.profile_name = response.data.data.profile_name;

                    //Restriction -> General Tabs
                    this.allowCamera = response.data.data.allow_camera;
                    this.allowScreenShot = response.data.data.allow_screenshot;
                    this.allowBluetoothModification = response.data.data.allow_bluetooth_modification;
                    this.forceEncryptedBackup = response.data.data.force_encrypted_backup;
                    this.allowEnablingRestrictions = response.data.data.allow_enabling_restrictions;
                    this.allowEraseContentAndSettings = response.data.data.allow_erase_content_and_settings;
                    this.allowDeviceNameModification = response.data.data.allow_device_name_modification;
                    this.allowWallpaperModification = response.data.data.allow_wallpaper_modification;
                    this.allowExplicitContent = response.data.data.allow_explicit_content;
                    this.allowUIConfigurationProfileInstallation = response.data.data.allow_ui_configuration_profile_installation;
                    this.allowPasscodeModification = response.data.data.allow_passcode_modification;

                    //Restriction -> Safari Tabs
                    this.allowSafari = response.data.data.allow_safari;
                    this.safariAllowAutoFill = response.data.data.safari_allow_auto_fill;
                    this.safariAllowJavaScript = response.data.data.safari_allow_java_script;
                    this.safariAllowPopups = response.data.data.safari_allow_popups;
                    this.safariForceFraudWarning = response.data.data.safari_force_fraud_warning;

                    //Restriction -> iCloud & Siri Settings
                    this.allowCloudBackup = response.data.data.allow_cloud_backup;
                    this.allowCloudPhotoLibrary = response.data.data.allow_cloud_photo_library;
                    this.allowManagedAppsCloudSync = response.data.data.allow_managed_apps_cloud_sync;
                    this.allowAssistant = response.data.data.allow_assistant;
                    this.allowAssistantWhileLocked = response.data.data.allow_assistant_while_locked;
                    this.allowCloudDocumentSync = response.data.data.allow_cloud_document_sync;
                    this.forceAssistantProfanityFilter = response.data.data.force_assistant_profanity_filter;

                    //Restriction -> Lock Screen Settings
                    this.allowFingerprintForUnlock = response.data.data.allow_fingerprint_for_unlock;
                    this.allowPassbookWhileLocked = response.data.data.allow_passbook_while_locked;
                    this.allowAutoUnlock = response.data.data.allow_auto_unlock;
                    this.allowVoiceDialing = response.data.data.allow_voice_dialing;
                    this.allowNotificationsModification = response.data.data.allow_notifications_modification;

                    //Restriction -> App Settings
                    this.allowEnterpriseAppTrust = response.data.data.allow_enterprise_app_trust;
                    this.allowBookstoreErotica = response.data.data.allow_bookstore_erotica;
                    this.forceAirDropUnmanaged = response.data.data.force_air_drop_unmanaged;
                    this.allowChat = response.data.data.allow_chat;
                    this.allowAppInstallation = response.data.data.allow_app_installation;
                    this.allowUIAppInstallation = response.data.data.allow_ui_appInstallation;
                    this.allowAppRemoval = response.data.data.allow_app_removal;
                    this.allowNews = response.data.data.allow_news;
                    this.allowMusicService = response.data.data.allow_music_service;
                    this.allowBookstore = response.data.data.allow_bookstore;

                    // Kiosk Tabs
                    this.kiosk_type = response.data.data.kiosk_type;

                    // Single App Kiosk
                    this.package_name= response.data.data.single_app_kiosk_package_name;
                    if (response.data.data.ios_single_app_kiosk_options) {
                      this.DisableTouch= response.data.data.ios_single_app_kiosk_options.DisableTouch;
                      this.DisableDeviceRotation= response.data.data.ios_single_app_kiosk_options.DisableDeviceRotation;
                      this.DisableRingerSwitch= response.data.data.ios_single_app_kiosk_options.DisableRingerSwitch;
                      this.DisableSleepWakeButton= response.data.data.ios_single_app_kiosk_options.DisableSleepWakeButton;
                      this.DisableAutoLock= response.data.data.ios_single_app_kiosk_options.DisableAutoLock;
                      this.DisableVolumeButtons= response.data.data.ios_single_app_kiosk_options.DisableVolumeButtons;
                      this.EnableVoiceOver= response.data.data.ios_single_app_kiosk_options.EnableVoiceOver;
                      this.EnableZoom= response.data.data.ios_single_app_kiosk_options.EnableZoom;
                      this.EnableInvertColors= response.data.data.ios_single_app_kiosk_options.EnableInvertColors;
                      this.EnableAssistiveTouch= response.data.data.ios_single_app_kiosk_options.EnableAssistiveTouch;
                      this.EnableSpeakSelection= response.data.data.ios_single_app_kiosk_options.EnableSpeakSelection;
                      this.VoiceOver= response.data.data.ios_single_app_kiosk_options.VoiceOver;
                      this.Zoom= response.data.data.ios_single_app_kiosk_options.Zoom;
                      this.InvertColors= response.data.data.ios_single_app_kiosk_options.InvertColors;
                      this.AssistiveTouch= response.data.data.ios_single_app_kiosk_options.AssistiveTouch;
                    }

                    // Lost Mode
                    /*this.enable_lost_mode = response.data.data.enable_lost_mode;
                    if (response.data.data.enable_lost_mode_data) {
                      this.lock_footnote = response.data.data.enable_lost_mode_data.lock_footnote;
                      this.lock_message = response.data.data.enable_lost_mode_data.lock_message;
                      this.lock_phone_number = response.data.data.enable_lost_mode_data.lock_phone_number;
                    }*/

                }
            }).catch((e) => { this.$toast.error(e); });
        },

        InstallProfile() {
          this.isDisabled = true;
            let data = {
                profile_id: this.profile_id,
                profile_name: this.profile_name,

                //Restriction -> General Tabs
                allowCamera: this.allowCamera,
                allowScreenShot: this.allowScreenShot,
                allowBluetoothModification: this.allowBluetoothModification,
                forceEncryptedBackup: this.forceEncryptedBackup,
                allowEnablingRestrictions: this.allowEnablingRestrictions,
                allowEraseContentAndSettings: this.allowEraseContentAndSettings,
                allowDeviceNameModification: this.allowDeviceNameModification,
                allowWallpaperModification: this.allowWallpaperModification,
                allowExplicitContent: this.allowExplicitContent,
                allowUIConfigurationProfileInstallation: this.allowUIConfigurationProfileInstallation,
                allowPasscodeModification: this.allowPasscodeModification,

                //Restriction -> Safari Tabs
                allowSafari: this.allowSafari,
                safariAllowAutoFill: this.safariAllowAutoFill,
                safariAllowJavaScript: this.safariAllowJavaScript,
                safariAllowPopups: this.safariAllowPopups,
                safariForceFraudWarning: this.safariForceFraudWarning,

                //Restriction -> iCloud & Siri Settings
                allowCloudBackup: this.allowCloudBackup,
                allowCloudPhotoLibrary: this.allowCloudPhotoLibrary,
                allowManagedAppsCloudSync: this.allowManagedAppsCloudSync,
                allowAssistant: this.allowAssistant,
                allowAssistantWhileLocked: this.allowAssistantWhileLocked,
                allowCloudDocumentSync: this.allowCloudDocumentSync,
                forceAssistantProfanityFilter: this.forceAssistantProfanityFilter,

                //Restriction -> Lock Screen Settings
                allowFingerprintForUnlock: this.allowFingerprintForUnlock,
                allowPassbookWhileLocked: this.allowPassbookWhileLocked,
                allowAutoUnlock: this.allowAutoUnlock,
                allowVoiceDialing: this.allowVoiceDialing,
                allowNotificationsModification: this.allowNotificationsModification,

                //Restriction -> App Settings
                allowEnterpriseAppTrust: this.allowEnterpriseAppTrust,
                allowBookstoreErotica: this.allowBookstoreErotica,
                forceAirDropUnmanaged: this.forceAirDropUnmanaged,
                allowChat: this.allowChat,
                allowAppInstallation: this.allowAppInstallation,
                allowUIAppInstallation: this.allowUIAppInstallation,
                allowAppRemoval: this.allowAppRemoval,
                allowNews: this.allowNews,
                allowMusicService: this.allowMusicService,
                allowBookstore: this.allowBookstore,

                // Kiosk Tabs
                kiosk_type: this.kiosk_type,

                // Single app kiosk
                single_app_kiosk_package_name: this.package_name,
                ios_single_app_kiosk_options : {
                  DisableTouch: this.DisableTouch,
                  DisableDeviceRotation: this.DisableDeviceRotation,
                  DisableRingerSwitch: this.DisableRingerSwitch,
                  DisableSleepWakeButton: this.DisableSleepWakeButton,
                  DisableAutoLock: this.DisableAutoLock,
                  DisableVolumeButtons: this.DisableVolumeButtons,
                  EnableVoiceOver: this.EnableVoiceOver,
                  EnableZoom: this.EnableZoom,
                  EnableInvertColors: this.EnableInvertColors,
                  EnableAssistiveTouch: this.EnableAssistiveTouch,
                  EnableSpeakSelection: this.EnableSpeakSelection,
                  VoiceOver: this.VoiceOver,
                  Zoom: this.Zoom,
                  InvertColors: this.InvertColors,
                  AssistiveTouch: this.AssistiveTouch,
                },

                // Lost Mode
                /*enable_lost_mode: this.enable_lost_mode,
                enable_lost_mode_data : {
                  lock_footnote: this.lock_footnote,
                  lock_message: this.lock_message,
                  lock_phone_number: this.lock_phone_number,
                }*/
            };
        
            IosMdmDataService.InstallProfile(data)
                .then((response) => {
                    this.isDisabled = false;
                    this.updateProfileNameModal = false;
                    this.$toast.success(response.data.respones_msg);
                }).catch((e) => {
                    this.isDisabled = false;
                    this.$toast.error(e.response.data.respones_msg);
                });
        },

        GetApplicationList() {
          this.isDisabled = true;
          this.applications = [];
          ApplicationDataService.GetApplicationList(this.profile_id, "Ios", "", this.filterVppApps, this.search_package_name)
            .then((response) => {
              if (response.data.data) {
                this.applications = response.data.data;
                this.vppTokenSet = response.data.vpp_token_set;
                this.isDisabled = false;
              }
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
              this.isDisabled = false;
            });

            if (this.search_package_name) {
              ApplicationDataService.SearchAppStoreApp(this.search_package_name, "us")
                .then((response) => {
                  this.storeApps = response.data.data;
                })
                .catch((e) => {
                  this.$toast.error(e.response.data.respones_msg);
                });    
            } else {
              this.storeApps = [];
            }
        },

        GetProfileApplicationList() {
          this.profileApplications = [];
          DeviceProceDataService.GetProfileApplicationList(
            this.$route.query.profile_id
          )
            .then((response) => {
              if (response.data.data) {
                this.profileApplications = response.data.data;
                this.profileApplicationsKiosk = response.data.data.filter(i => i.install_type == "PREINSTALLED");
              }
            })
            .catch((e) => {
              this.$toast.error(e);
            });
        },

        async CreateProfileApplication(package_name, is_web_app, app = "") {
          if (app) {
            let data = {
                product_id: app.id,
                package_name: app.appId,
                app_icon: app.icon,
                application_details: app,
                type: "Ios"
            };
            await ApplicationDataService.CreateIosApplication(data)
            .then((response) => {
                let updated_app_store_application = this.storeApps.filter(elem => elem.appId != app.appId)
                this.storeApps = updated_app_store_application;
                this.search_package_name = "";
            }).catch((e) => {
              let updated_app_store_application = this.storeApps.filter(elem => elem.appId != app.appId)
              this.storeApps = updated_app_store_application;
              this.search_package_name = "";
            })
          }
          
          var data = {
            profile_id: this.$route.query.profile_id,
            package_name: package_name,
            is_web_app: is_web_app,
          };

          DeviceProceDataService.CreateProfileApplication(data)
            .then((response) => {
              this.$toast.success(response.data.respones_msg);
              this.GetProfileApplicationList();
              this.GetApplicationList();
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });
        },

        DeleteProfileApplication(package_name) {
          let data = {
            package_name: package_name,
            profile_id: this.$route.query.profile_id,
          };

          DeviceProceDataService.DeleteProfileApplication(data)
            .then((response) => {
              this.$toast.success(response.data.respones_msg);
              this.GetProfileApplicationList();
              this.GetApplicationList();
            })
            .catch((e) => {
              this.isDisabled = false;
              this.$toast.error(e.response.data.respones_msg);
            });
        },

        UpdateAppEnableDisableStatus: function (id) {
          let value = $("#"+id).is(":checked")
          let data = {
            "application_id": id,
            "is_app_disabled": value,
            profile_id: this.profile_id,
          }
          DeviceProceDataService.UpdateAppEnableDisableStatus(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
        },

        ChangeInstallType(package_name, event) {
          let data = {
              profile_id: this.profile_id,
              package_name: package_name,
              install_type: event.target.value,
          };
      
          IosMdmDataService.ChangeInstallType(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },

        ApplyPolicy() {
          this.isDisabled = true;
          let data = {
            profile_id: this.profile_id,
          }
          IosMdmDataService.ApplyPolicy(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.isDisabled = false;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
            /*if (e.response.data.data.payment_redirection) {
              this.$router.push({ path: 'plan-list'})
            }*/
          });
        },

        GetProfileDeviceList() {
            this.profileDevices = [];
            $(".loader-img").show();
            let data = {
              profile_id: this.$route.query.profile_id,
              serial_number: "",
              user_id: "",
              hide_assign_devices: false,
              type: "",
              imei_number: "",
              model: "",
            }
            DeviceProceDataService.GetProfileDeviceList(data)
              .then((response) => {
                if (response.data.data) {
                  $(".loader-img").hide();
                  this.profileDevices = response.data.data;
                }
              })
              .catch((e) => {
                this.$toast.error(e);
              });
        },

        KioskTypeChange() {
          if (this.profileApplicationsKiosk.length == 1) {
            if (this.kiosk_type == "singleApp") {   
                this.package_name = this.profileApplicationsKiosk[0].package_name;
            } else {
                this.package_name = "";
            }
          }
        },

        selectDevice() {
          if (this.selected_devices.length > 0) {
            this.isDeviceBtnDisabled = false;
          } else {
            this.isDeviceBtnDisabled = true;
          }
        },

        IssueCommand(command) {
          Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, "+command,
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.isDeviceBtnDisabled = true;
              this.selected_devices.forEach((value, index) => {
                let data = {
                  "profile_id": this.profile_id,
                  "type": command,
                  "name": value,
                }
                IosMdmDataService.IssueCommand(data)
                .then((response) => {
                  this.$toast.success(response.data.respones_msg);
                  this.isDeviceBtnDisabled = false;
                  this.selected_devices = [];
                })
                .catch((e) => {
                  this.$toast.error(e.response.data.respones_msg);
                  this.isDeviceBtnDisabled = false;
                  this.selected_devices = [];
                });
              });
            }
          });
        },

        AddWifi: function () {
          this.isDisabled = true;
          var data = {
            "profile_id": this.profile_id,
            "wifi_ssid": this.wifi_ssid,
            "wifi_security": this.wifi_security,
            "wifi_passphrase": this.wifi_passphrase,
            "wifi_autoconnect": this.wifi_autoconnect,
          } 
          DeviceProceDataService.AddWifi(data)
          .then((response) => {
            this.wifi_ssid = null;
            this.wifi_security = null;
            this.wifi_passphrase = null;
            this.wifi_autoconnect = null;
            this.AddWifiModal = false;
            this.ListWifi();
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
        },

        ListWifi: function () {
          DeviceProceDataService.ListWifi(this.$route.query.profile_id)
          .then((response) => {
            this.profileWifi = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
        },

        DeleteProfileWiFi: function (id) {

          Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              var data = {
                id : id
              };
              DeviceProceDataService.DeleteProfileWiFi(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.ListWifi();
                this.isDisabled = false;
              })
              .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                this.isDisabled = false;
              });
            }
          });
        },

        UpdateDefaultWiFi(id) {
          this.isDisabled = true;
          let value = $("#"+id).is(":checked")
          var data = {
            "profile_id": this.profile_id,
            "id": id,
            "value": value
          } 
          DeviceProceDataService.UpdateDefaultWiFi(data)
          .then((response) => {
            this.ListWifi();
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.isDisabled = false;
          });
        },

        QueryAppAttributes(packageName) {
          let data = {
            package_name: packageName,
            profile_id: this.profile_id,
          }
          IosMdmDataService.QueryAppAttributes(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        },

        SyncVppApps() {
          this.isDisabled = true;
          UsersDataService.SyncVppApps()
            .then((response) => {
              this.isDisabled = false;
              this.$toast.success(response.data.respones_msg);
            })
            .catch((e) => {
              this.isDisabled = false;
              this.$toast.error(e.response.data.respones_msg);
            });
        },  

        CopyProfileContent(content) {
          navigator.clipboard.writeText(content);
          this.$toast.success("text copied");
        },
        GetQrCodeList() {
        let data = {
          profile_id: this.profile_id
        }
        QrCodeDataService.GetQrCodeList(data)
          .then((response) => {
            if (response.data.data) {
              this.qrCodeData = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e);
          });
      },
      OpenShowQrCodeModal(qrCode, enterpriseToken, profileType) {
        this.barcode = qrCode
        if (enterpriseToken) {
          if (profileType == "Android") {
            this.enterprise_token = "https://enterprise.google.com/android/enroll?et="+enterpriseToken.value
          } else {
            this.enterprise_token = enterpriseToken.value
          }
        } else {
          this.enterprise_token = ""
        }
        this.ShowQrCodeModal = true;
      },
    },
    beforeMount() {
        this.GetDeviceProfileDetail();
        this.GetProfileApplicationList();
        this.GetApplicationList();
    },
    mounted: function () {
      this.$nextTick(() => {
        const that = this;
        $("#0").on("click", function() {
          that.GetProfileApplicationList();
          that.GetApplicationList();
        });
         $("#2").on("click", function() {
          that.GetProfileApplicationList();
        });
         $("#3").on("click", function() {
          that.GetProfileDeviceList();
        });
        $("#4").on("click", function() {
          that.ListWifi();
        });
        $("#5").on("click", function() {
          that.GetQrCodeList();
        });
      })
    },
}
</script>
