import http from "../http-common";

class IosMdmDataService {

    CreatePushCertificate(data) {
        return http.post("/ios/push-certificate/create", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    CreateProfile(data) {
        return http.post("ios/profile/create", data);
    }
    CreateProfileQrCode(data) {
        return http.post("ios/profile/generate/qr-code", data);
    }
    InstallProfile(data) {
        return http.post("ios/profile/install", data);
    }
    ChangeInstallType(data) {
        return http.post("ios/profile/application-status-change", data);
    }
    ApplyPolicy(data) {
        return http.post("ios/profile/apply/policy", data);
    }
    IssueCommand(data) {
        return http.post("ios/profile/device/issue/command", data);
    }
    QueryAppAttributes(data) {
        return http.post("application/query/app-attributes", data);
    }
    deleteAllIosProfiles() {
        return http.delete("ios/profile/delete/all");
    }
}

export default new IosMdmDataService();