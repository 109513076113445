import http from "../../http-common-super-admin";

class UserDataService {
  login(data) {
    return http.post("users/login", data);
  }
  VerifyOtp(data) {
    return http.post("users/verify/otp", data);
  }
  getUserDetail(data) {
    return http.get("/users/detail", data);
  }
  UserList(data) {
    return http.get("/users/list",{ params: data });
  }
  ByPassUserLogin(data) {
    return http.post("users/bypass-login", data);
  }
  ChangePassword(data) {
    return http.post("users/change-password", data);
  }
  SyncUsers() {
    return http.post("users/sync/users");
  }
  DbUserDetail(data) {
    return http.get("/users/db-user-detail", { params: data });
  }
  UpdateUserDetail(data) {
    return http.post("/users/db-user-update", data);
  }
  UpdateAdminDetail(data) {
    return http.post("/users/update", data);
  }
  GetSettings(data) {
    return http.get("/users/settings",  { params: data });
  }
}

export default new UserDataService();