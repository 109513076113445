<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-primary mr-2" v-show="enterprise_detail.is_default_enterprise == false" @click="SyncEnterprise()">Sync</md-button>
        <md-button v-show="enterprise_detail.is_default_enterprise == true || !enterprise_detail" class="md-success mr-2"
            @click="GenerateSignupUrl()">Create Android Enterprise</md-button>
      </div>
      <md-card>
        <!--<md-card-header class="md-card-header-icon md-card-header-green" v-show="show_table">
          <div class="card-icon">
            <md-icon>android</md-icon>
          </div>
          <h4 class="title">Enterprise Detail</h4>
        </md-card-header>-->
        <md-card-content v-show="show_table">
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Enterprise Name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Enterprise ID">{{ item.enterprise_id  }}</md-table-cell>
              <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="Phone">{{ item.phone }}</md-table-cell>
              <!-- <md-table-cell md-label="Administrator">{{
                item.admin_email
              }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <button v-if="item.is_enrolled == 1" type="button" @click="UnenrollEnterprise(item.enterprise_id)"
                  style="width:unset;line-height:unset;" class="md-button md-danger md-theme-default">
                  <div class="md-button-content" style="padding:0 10px;">Unenroll</div>
                </button>
                <button v-else type="button" @click="GenerateSignupUrl()" style="width:unset;line-height:unset;" class="md-button md-success md-theme-default">
                  <div class="md-button-content" style="padding:0 10px;">Enroll</div>
                </button>
              </md-table-cell>-->
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import EnterpriseDataService from "../../../services/EnterpriseDataService";
import DeviceProceDataService from "../../../services/DeviceProceDataService";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      selected: [],
      show_table: false,
      tableData: [
        {
          name: "",
          enterprise_id: "",
          email: "",
          phone: "",
        },
      ],
      enterprise_detail: null,
    };
  },
  methods: {
    getClass: ({ id }) => ({
      "table-success": id === 1,
      "table-info": id === 3,
      "table-danger": id === 5,
      "table-warning": id === 7,
    }),
    GenerateSignupUrl() {
      //console.log(this.$route.query.test)
      var data = {};
      EnterpriseDataService.GenerateSignupUrl(data)
        .then((response) => {
          window.location.href = response.data.data;
        })
        .catch((e) => {
          if (e.response.data.deleteProfile == true) {
            let message = `Following profile is linked with default enterprise, please delete all linked profile first and try again.`;
            let data = {
              profile_id: "",
              search_profile_name: "",
              search_profile_type: "Android"
            }
            DeviceProceDataService.GetDeviceProfileList(data)
              .then((response) => {
                if (response.data.data.length > 0) {
                    message += "<ul>";
                    for (let profile of response.data.data) {
                        message += '<li>'+profile.profile_name+'</li>';
                    }
                    message += "</ul>";
                }
                Swal.fire({
                  title: "Are you sure?",
                  html:message,
                  type: "warning",
                  showCancelButton: true,
                  cancelButtonClass: "md-button md-warning",
                  confirmButtonClass: "md-button md-danger",
                  confirmButtonText: "Delete All",
                }).then((result) => {
                  if (result.value) {
                      Swal.fire({
                        title: "All data will be deleted",
                        html: "<ul><li>All work profile will be removed from the BYOD devices.</li><li>All the company owned device will be factory reset.</li></ul>",
                        type: "warning",
                        showCancelButton: true,
                        cancelButtonClass: "md-button md-warning",
                        confirmButtonClass: "md-button md-danger",
                        confirmButtonText: "Yes Delete All",
                      }).then((result) => {
                        if (result.value) {
                            DeviceProceDataService.deleteAllProfiles()
                            .then((response) => {
                              this.$toast.success(response.data.respones_msg);
                            })
                            .catch((e) => {
                              this.$toast.error(e.response.data.respones_msg);
                            });    
                        }
                      });   
                  }
                });
              })
              .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
              });
          } else {
            this.$toast.error(e.response.data.respones_msg);
          }
        });
    },
    CompleteEnterpriseSignup() {
      var data = {
        enterpriseToken: this.$route.query.enterpriseToken
      };
      EnterpriseDataService.CompleteEnterpriseSignup(this.$route.query.enterpriseToken)
        .then((response) => {
          this.GetEnterpriseDetal();
          this.$router.push({ path: '/configure-android'});
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    GetEnterpriseDetal() {
      var data = {};
      EnterpriseDataService.GetEnterpriseDetal(data)
        .then((response) => {
          if (response.data.data) {
            this.enterprise_detail = response.data.data;
            this.show_table = true;
            this.tableData[0].name = response.data.data.name;
            this.tableData[0].enterprise_id = response.data.data.enterprise_id;
            this.tableData[0].email= (response.data.data.enterprise_detail) ? response.data.data.enterprise_detail.contactInfo.dataProtectionOfficerEmail : "";
            this.tableData[0].phone= (response.data.data.enterprise_detail) ? response.data.data.enterprise_detail.contactInfo.dataProtectionOfficerPhone : "";
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    SyncEnterprise() {
        EnterpriseDataService.SyncEnterprise()
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetEnterpriseDetal();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    UnenrollEnterprise(enterprise_id) {
      var data = {
        enterprise_id: enterprise_id
      };

      EnterpriseDataService.UnenrollEnterprise(data)
        .then((response) => {
          this.GetEnterpriseDetal();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
  },
  beforeMount() {
    if (this.$route.query.enterpriseToken) {
      this.CompleteEnterpriseSignup();
    }
    this.GetEnterpriseDetal();
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
