<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-actions class="text-center">
          <md-button class="md-success" @click="AddCardModal = true">Add Card</md-button>
        </md-card-actions>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Last 4 Digit">{{ item.last4 }} <span class="badge" v-if="item.id == default_card">Default</span></md-table-cell>
              <md-table-cell md-label="Exp Month">{{ item.exp_month }}</md-table-cell>
              <md-table-cell md-label="Exp Year">{{ item.exp_year }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button class="md-just-icon md-info" @click="EditCard(item.id,item.name,item.exp_month,item.exp_year)"><md-icon>edit</md-icon></md-button>&nbsp;
                <md-button class="md-just-icon md-danger" @click="DeleteCard(item.id)"><md-icon>close</md-icon></md-button>
                <a href="javascript:void(0)" class="badge-link" v-if="item.id != default_card" @click="ChangeDefaultCard(item.id)">Mark As Default</a>&nbsp;
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>

    <!--Add Wifi Modal -->
    <template>
        <modal v-if="AddCardModal" @close="AddCardModalHide">
          <template slot="header">
            <h4 class="modal-title">Add Card</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="AddCardModalHide"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
          <div class="box-body">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>Name</label>
                  <md-input type="text" v-model="card_name"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50" v-if="is_edit_popup == false"> 
                <md-field>
                  <label>Number</label>
                  <md-input type="text" v-model="number"></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <label>Exp Month</label>
                  <md-input type="number" max="12" v-model="exp_month"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50"> 
                <md-field>
                  <label>Exp Year</label>
                  <md-input type="number" v-model="exp_year"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50" v-if="is_edit_popup == false"> 
                <md-field>
                  <label>CVC</label>
                  <md-input type="text" v-model="cvc"></md-input>
                </md-field>
              </div>
            </div>
          </div>
          </template>
          <template slot="footer">
            <md-button class="md-danger md-simple" @click="AddCardModalHide">Close</md-button>
            <md-button v-if="is_edit_popup == false" class="md-success" :disabled="isDisabled" @click="AddCard()">Add</md-button>
            <md-button v-else class="md-success"  :disabled="isDisabled" @click="UpdateCard()">Update</md-button>
          </template>
        </modal>
    </template>
  </div>
</template>
<script>
import PaymentDataService from "../../services/PaymentDataService";
import Swal from "sweetalert2";
import { Modal } from "@/components";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      tableData: [],
      default_card: null,
      AddCardModal: false,
      card_name: null,
      number: null,
      exp_month: null,
      exp_year: null,
      cvc: null,
      is_edit_popup: false,
      update_item_id: null,
      isDisabled: false
    };
  },
  methods: {
    
    AddCardModalHide: function () {
      this.CleatForm();
      this.AddCardModal = false;
    },
    GetCardList() {
      PaymentDataService.GetCardList({})
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data.data;
            this.default_card = response.data.data.default_source;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    AddCard() {
      this.isDisabled = true;
      var data = {
        card_name : this.card_name,
        number : this.number,
        exp_month : this.exp_month,
        exp_year : this.exp_year,
        cvc: this.cvc
      }
      PaymentDataService.AddCard(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.CleatForm();
          this.AddCardModal = false,
          this.GetCardList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    UpdateCard() {
      this.isDisabled = true;
      var data = {
        id: this.update_item_id,
        name : this.card_name,
        exp_month : this.exp_month,
        exp_year : this.exp_year,
      }
      PaymentDataService.UpdateCard(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.CleatForm();
          this.AddCardModal = false,
          this.GetCardList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    CleatForm() {
        this.number  = null;
        this.card_name = null;
        this.exp_month = null;
        this.exp_year  = null; 
        this.cvc  = null; 
        this.update_item_id = null;
        this.is_edit_popup = false;
    },
  
    EditCard(id, name,exp_month,exp_year) {
        this.update_item_id = id;
        this.card_name = name;
        this.exp_month = exp_month;
        this.exp_year  = exp_year;
        this.is_edit_popup = true; 
        this.AddCardModal = true;
    },

    ChangeDefaultCard(id) {
      Swal.fire({
          title: "Are you sure?",
          text: `You want to change your default card!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = { "id" : id };
            PaymentDataService.ChangeDefaultCard(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.GetCardList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
    },

    DeleteCard(id) {
      Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            var data = { "id" : id };
            PaymentDataService.DeleteCard(data)
              .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.GetCardList();
              })
              .catch((e) => {
                if (e.response) {
                  this.$toast.error(e.response.data.respones_msg);
                }
              });
          }
        });
    },  
  },
  beforeMount() {
    this.GetCardList();
  },
};
</script>
<style scoped>
.badge {
  background-color: cyan;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  color: black;
}
.badge-link {
    background-color: lightgreen;
    color: black !important;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
</style>
