<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-primary" :disabled="isDisabled" @click="SyncVppApps()">Sync</md-button>
      </div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>apps</md-icon>
          </div>
          <h4 class="title">VPP Applications</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Icon" v-if="item.assetDetail">
                    <img :src="item.assetDetail.artwork[0].url" style="width:50px;" />
                </md-table-cell>
                <md-table-cell md-label="Icon" v-else>-</md-table-cell>
              <md-table-cell md-label="Id">{{ item.adamId }}</md-table-cell>
              <md-table-cell md-label="Title" v-if="item.assetDetail">{{ item.assetDetail.name }}</md-table-cell>
              <md-table-cell md-label="Title" v-else>-</md-table-cell>
              <md-table-cell md-label="Assigned Count">{{ item.assignedCount  }}</md-table-cell>
              <md-table-cell md-label="Available Count">{{ item.availableCount  }}</md-table-cell>
              <md-table-cell md-label="Device Assignable">{{ item.deviceAssignable  }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";
export default {
  data() {
    return {
      tableData: [],
      isDisabled: false,
    };
  },
  methods: {
    GetAdminDetail() {
      var data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          if (response.data.data.ios_mdm_vpp_assets) {
            this.tableData = response.data.data.ios_mdm_vpp_assets;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    SyncVppApps() {
      this.isDisabled = true;
      UsersDataService.SyncVppApps()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetAdminDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },  
  },
  beforeMount() {
    this.GetAdminDetail()
  },
};
</script>
