<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float:right;">
                    <md-button class="md-success" :disabled='isDisabled' @click="CreateDomain()">Save</md-button>
                </div>
                <hr>
                <h4>Basic Details</h4>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Domain</b></label>
                            <md-input type="text" v-model="domain"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Title</b></label>
                            <md-input type="text" v-model="title"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>About</b></label>
                            <md-textarea type="text" v-model="about"></md-textarea>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Address</b></label>
                            <md-textarea type="text" v-model="address"></md-textarea>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Phone Number</b></label>
                            <md-input type="text" v-model="phone_number"></md-input>
                        </md-field>
                    </div>
                </div>
                <h4>Links Section</h4>
                <div class="md-layout" v-for="index in links_array_length" :key="index">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Title</b></label>
                            <md-input type="text" name="links_title[]"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Url</b></label>
                            <md-input type="text" name="links_url[]"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-button class="md-primary" @click="AddLinkBlock()">Add</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import DomainDataService from "../../services/SuperAdmin/DomainDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      domain: "",
      title: "",
      about: "",
      address: "",
      phone_number: "",
      links_array_length : 1,
    };
  },
  methods: {
      CreateDomain() {
        let titles = $("input[name='links_title[]']")
            .map(function(){ 
            return $(this).val();
        }).get();

        let urls = $("input[name='links_url[]']")
            .map(function(){ 
            return $(this).val();
        }).get();

        // Prepare links array 
        let links = []
        titles.forEach((value,index) => {
            if (value && urls[index]) {
                links.push({
                    "title": value,
                    "link": urls[index]
                })
            }
        });

        let reqData = {
            domain: this.domain,
            title: this.title,
            about: this.about,
            address: this.address,
            phone_number: this.phone_number,
            links: links,
        }
        DomainDataService.CreateDomain(reqData)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.$router.push("/admin-domains-list");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      AddLinkBlock() {
        this.links_array_length++;
      }
  },
  beforeMount() {
  },
};
</script>

<style>
</style>
