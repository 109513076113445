<template>
    <div>
        <div style="float: right">
            <md-button class="md-success" :disabled="isDisabled" @click="createWebinar()">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="webinarForm">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-datepicker v-model="date" md-immediately @input="appointmentsByDate()">
                            <label>Select date</label>
                            </md-datepicker>            
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Time</label>
                                <md-select v-model="time">
                                    <md-option v-for="time in timeSlot()" :key="time" :value="time">{{ time }}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Title</label>
                                <md-input type="text" v-model="title"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Link</label>
                                <md-input type="text" v-model="link"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-field>
                                <vue-editor style="height: 90%;width:100%" v-model="description"></vue-editor>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <input type="file" @change="handleFileUpload( $event )"/>
                        </div>
                        <div class="md-layout-item md-size-100">
                            <md-field>
                                <md-checkbox v-model="repeatEveryWeek"></md-checkbox>
                                <div style="padding-left: 0px;margin-top: 15px;">Repeat Every Week</div> 
                            </md-field>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import WebinarService from "../../../services/SuperAdmin/WebinarService";
export default {
    components: {
        VueEditor,
    },
    data() {
        return {
            isDisabled: false,
            date: null,
            time: null,
            title: "",
            link:"",
            description:"",
            image: "",
            repeatEveryWeek: false,
        }
    },
    methods: {
        handleFileUpload(event){
          this.image = event.target.files[0];
        },
        createWebinar() {
            this.isDisabled =true;
            let formData = new FormData();
            formData.append("image", this.image); formData.append("date", this.date); formData.append("time", this.time);
            formData.append("title", this.title); formData.append("link", this.link); formData.append("description", this.description);
            formData.append("repeatEveryWeek", this.repeatEveryWeek);
            WebinarService.createWebinar(formData)
            .then((response) => {
                this.isDisabled =false;
                this.$refs.webinarForm.reset(); 
                this.description="";this.time="";this.image="";
                this.$toast.success(response.data.respones_msg);
                this.$router.push("/admin-webinars");
            })
            .catch((e) => {
                this.isDisabled =false;
                this.$toast.error(e.response.data.respones_msg);
            });
        }
    }
}
</script>