<template>
    <div class="row p-3 m-3">
        <div class="card" style="width: 15rem;" v-for="item in tableData" :key="item._id">
            <a href="javascript:void(0)" @click="$router.push({ path: 'webinar', query: { id: item._id }})">
                <img v-if="item.image_link" class="card-img-top img-thumbnail fix-height" :src="item.image_link" alt="Card image cap">
                <img v-else class="card-img-top img-thumbnail fix-height" :src="webinarDefaultImage" alt="Card image cap">
                <div class="card-body">
                    <h6 class="card-title">{{ item.title }}</h6>
                    <small v-if="item.repeat_every_week" class="text-info font-weight-bold">
                        Every {{ getDay(item.date) }} at {{ item.time }}
                    </small>
                    <small v-else class="text-info font-weight-bold">
                        {{ HumanReadableDateFormat(item.date, true) }} {{ item.time }}
                    </small>
                </div>
            </a>
            <div class="card-footer">
                <!--<md-field v-if="item.repeat_every_week">
                    <md-select class="box-textbox" placeholder="Webinar Dates" v-model="webinarDate" :style="{padding: '5px'}">
                        <md-option value="10 April 2023">10 April 2023</md-option>
                        <md-option value="17 April 2023">17 April 2023</md-option>
                        <md-option value="24 April 2023">24 April 2023</md-option>
                    </md-select>
                </md-field>-->
                <div :style="{ display: 'flex' }">
                    <md-button class="md-success mr-2" @click="$router.push({ path: 'webinar', query: { id: item._id }})">Register</md-button>
                    <md-button class="md-primary mr-2" @click="openRequestModal(item._id, item.title)">Request</md-button>
                </div>
            </div>
        </div>
        <span v-if="tableData.length == 0">No data found.</span>
        <!-- Models -->
        <template>
            <modal v-if="requestWebinarModal" @close="requestWebinarModal = false">
              <template slot="header">
                <h4 class="modal-title">Request For {{ webinarTitle }}</h4>
                <md-button
                  style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                  @click="requestWebinarModal = false"><md-icon>clear</md-icon></md-button>
              </template>
              <template slot="body">
                <div class="box-body">
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <md-textarea type="text" placeholder="Please let us know more about your prefer date and time" v-model="notes"></md-textarea>
                        </md-field>
                      </div>
                    </div>
                </div>
              </template>
              <template slot="footer">
                <md-button class="md-danger md-simple" @click="requestWebinarModal = false">Close</md-button>
                <md-button class="md-success" :disabled="isDisabled" @click="requestWebinar()">
                  Request <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                </md-button>
              </template>
            </modal>
        </template>
    </div>
</template>
<script>
import WebinarService from "../../services/WebinarService";
import { Modal } from "@/components";

export default {
  components: {
    Modal,
  },
  props: {
    webinarDefaultImage: {
      type: String,
      default: "./img/webinar.jpg",
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      requestWebinarModal: false,
      notes:"",
      webinarId:"",
      webinarTitle:"",
    };
  },
  methods: {
    webinarList() {
        WebinarService.webinarList()
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    openRequestModal(webinarId, title) {
      this.webinarId = webinarId;
      this.webinarTitle = title;
      this.requestWebinarModal = true;
    },
    requestWebinar() {
      this.isDisabled = true;
      
      let data = {
        webinarId: this.webinarId, notes: this.notes
      }
      WebinarService.requestWebinar(data)
        .then((response) => {
          this.isDisabled = false;
          this.notes="";
          this.requestWebinarModal = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.webinarList();
  },
}
</script>