var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v("Type Validation")])])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" Required Text ")]),_c('div',{staticClass:"md-layout-item md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("required")])])]),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" Email ")]),_c('div',{staticClass:"md-layout-item md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("email=\"true\"")])])]),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" Number ")]),_c('div',{staticClass:"md-layout-item md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("number=\"true\"")])])]),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" Url ")]),_c('div',{staticClass:"md-layout-item md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"url","rules":{
                required: true,
                regex:
                  /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("url=\"true\"")])])]),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v(" Equal To ")]),_c('div',{staticClass:"md-layout-item md-size-30 md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"equal","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.equal),callback:function ($$v) {_vm.equal=$$v},expression:"equal"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-size-30 md-xsmall-size-100"},[_c('ValidationProvider',{attrs:{"name":"equalTo","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.equalTo),callback:function ($$v) {_vm.equalTo=$$v},expression:"equalTo"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("equalTo=\"#idSource\"")])])])]),_c('md-card-actions',{staticClass:"text-center"},[_c('md-button',{staticClass:"md-success",attrs:{"type":"submit"}},[_vm._v("Validate Inputs ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }