<template>
  <div class="md-layout-item md-size-100">
    <div class="md-layout">
      <div class="md-layout-item md-size-50 wrapper_1">
        <md-button v-if="!isActivePlan" class="md-secondary float-right" @click="$router.push('plan-list')">Pricing Page</md-button>
        <div>
        <h4 class="color-white">Summary</h4>
        <hr>
        <p>
          <span class="color-white">Plan Price</span> 
          <span class="float-right color-black">
            <b>{{ plan_currency }} {{ plan_price }} / {{ plan_interval }} / Device</b>
          </span>
        </p>
        <p v-if="isActivePlan">
          <span class="color-white">Subscription Expire At</span> 
          <span class="float-right color-black">
            <b> {{ subscription_expire_at }}</b>
          </span>
        </p>
        <p>
          <span class="color-white">Current Devices</span> 
          <span class="float-right color-black">
            <b>{{ current_device }}</b>
          </span>
        </p>
        <p>
          <span class="color-white">Devices After Purchase</span> 
          <span class="float-right color-black">
            <b>{{ after_purchase_device }}</b>
          </span>
        </p>
        <p v-if="coupon_amount">
          <span class="color-white">Coupon Discount</span> 
          <span class="float-right color-black">
            <b> {{ coupon_amount }}</b>
          </span>
        </p>
        <hr>
        <p class="color-white">Total <span class="float-right" style="color:black">
          <b> {{ plan_currency }} {{ NumberTODecimal(payable_amount - coupon_amount) }}</b></span></p>
      </div>
      </div>
      <div class="md-layout-item md-size-50 wrapper_2">
        <div v-if="!isActivePlan" ref="card"></div>
        <div>
          <md-field>
            <md-input
              placeholder="Qty" type="number" min="1" name="qty" v-model="qty"
              style="box-sizing: border-box;height: 40px;padding: 10px 12px;border: 1px solid #ddd;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;"
              @change="ChangePayableAmount()"
            ></md-input>
          </md-field>
          <small class="text-danger"><b>If you want to add more than 1 device then buy in qty</b></small>
        </div>
        <div v-if="!isActivePlan">
          <md-field>
            <md-input
              placeholder="Have coupon code?" type="text" name="coupon_code" v-model="coupon_code"
              style="box-sizing: border-box;height: 40px;padding: 10px 12px;border: 1px solid #ddd;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;"></md-input>
            <md-button class="md-secondary" style="margin:0px;" :disabled="isDisabled" @click="ApplyCoupon()">Apply</md-button>
          </md-field>
        </div>
        <div>
          <vue-recaptcha
            :sitekey="siteKey"
            @verify="verifyCaptchaMethod"
            >
          </vue-recaptcha>
        </div>
        <div style="float: right">
          <md-button v-if="!isActivePlan" class="md-success" type="submit" :disabled="isDisabled" @click="purchase()">Purchase</md-button>
          <md-button v-else class="md-success" type="submit" :disabled="isDisabled" @click="UpdateSubscription()">Update</md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentDataService from "../../services/PaymentDataService";
import { VueRecaptcha } from 'vue-recaptcha';
const appearance = {
  theme: "night",
};
let stripe = Stripe("pk_live_51LXRUQLA8AkLfo94wvABwEZjrH4tGOTLu5Cry76n6cqK0LonkP6YcG0WDQfN4wZ8pWnfjMXjoO8t9eoGnblrFVMb00cBqyg8UQ"),
  elements = stripe.elements(appearance),
  card = undefined;

export default {
  components: { VueRecaptcha },
  data() {
    return {
      isDisabled: false,
      qty: 1,
      plan_price: null,
      plan_interval: null,
      plan_currency: null,
      payable_amount: 0,
      current_device: 2,
      after_purchase_device: 2,
      isActivePlan: false,
      subscription_expire_at: null,

      //Coupons
      coupon_code: null,
      original_coupon_amount: 0,
      coupon_amount: 0,
      coupon_type: "",

      siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
      captcha: "",

    };
  },
  methods: {
    purchase() {
      if (!this.captcha) {
        this.$toast.error("Please verify captcha.");
        return;  
      }
      var context = this;
      context.isDisabled = true;
      stripe.createToken(card).then(function (result) {
        if (result.error) {
          self.hasCardErrors = true;
          context.isDisabled = false;
          //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
          context.$toast.error(result.error.message);
          return;
        }
        var token = result.token;
        stripe
          .createPaymentMethod({
            type: "card",
            card: card,
            billing_details: {
              name: localStorage.getItem("login_user_name"),
            },
          })
          .then(function (result) {
            var data = {
              token: token,
              qty: context.qty,
              coupon_code: context.coupon_code,
              price_id: context.$route.query.price_id,
              payment_method_id: result.paymentMethod.id,
              captcha: context.captcha,
            };

            PaymentDataService.Purchase(data)
              .then((response) => {
                stripe
                  .confirmCardPayment(response.data.data, {
                    payment_method: {
                      card: card,
                      billing_details: {
                        name: localStorage.getItem("login_user_name"),
                      },
                    },
                  })
                  .then(function (result) {
                    if (result.error) {
                      context.isDisabled = false;
                      context.$toast.error(result.error.message);
                      document.location = "/payment-fail?message="+result.error.message;
                    } else {
                      context.isDisabled = false;
                      // The payment has been processed!
                      if (result.paymentIntent.status === "succeeded") {
                        //console.log(result);
                        context.$toast.success(response.data.respones_msg);
                        card.clear();
                        //context.$router.push('/dashboard');
                        //context.$router.go();
                        document.location = "/payment-success";
                      }
                    }
                  });
              })
              .catch((e) => {
                context.$toast.error(e.response.data.respones_msg);
                context.isDisabled = false;
              });
          });
      });
    },

    UpdateSubscription() {
      this.isDisabled = true;
      var data = {
        qty: this.qty,
        coupon_code: this.coupon_code,
        price_id: this.$route.query.price_id,
        captcha: this.captcha,
      };
      PaymentDataService.UpdateSubscription(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isDisabled = false;
          document.location = "/payment-success";
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
          if (e.response.data.is_redirect == true) {
            document.location = "/payment-fail?message="+e.response.data.respones_msg;
          }
        });
    },

    GetPlanDetail() {
      PaymentDataService.GetPlanDetail(this.$route.query.price_id).then((response) => {
        this.plan_currency = response.data.data.currency;
        this.plan_interval = response.data.data.interval;
        this.plan_price = response.data.data.amount / 100;
        this.payable_amount = response.data.data.amount / 100;
        this.current_device = response.data.data.current_device;
        this.after_purchase_device = response.data.data.current_device + 1;
        this.coupon_amount = response.data.data.coupon_amount;
        this.original_coupon_amount = response.data.data.coupon_amount;
        this.coupon_type = response.data.data.coupon_type;
        this.subscription_expire_at =
          response.data.data.subscription_expire_at_formatted;
        if (response.data.data.subscription_expire_at) {
          if (
            new Date(response.data.data.subscription_expire_at) > new Date()
          ) {
            this.isActivePlan = true;
          }
        }
      });
    },
    ChangePayableAmount() {
      if (this.coupon_type == "PERCENTAGE") {
        this.coupon_amount = (this.original_coupon_amount * this.qty).toFixed(2);
      }
      this.payable_amount = (this.plan_price * this.qty).toFixed(2);
      this.after_purchase_device = parseInt(this.current_device) + parseInt(this.qty);
      if (this.coupon_code) {
        this.ApplyCoupon();
      }
    },
    ApplyCoupon(){
      this.isDisabled = true;
      var data = {
        coupon_code: this.coupon_code,
        price_id: this.$route.query.price_id,
        qty: this.qty,
      };
      PaymentDataService.ApplyCoupon(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isDisabled = false;
          this.coupon_amount = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
          this.isDisabled = false;
          this.coupon_code = null;
          this.coupon_amount = 0;
        });
    },
    resizeWindow() {
      if ($(window).width() < 600) {
        $('.wrapper_1').removeClass('md-size-50').addClass('md-size-100');
        $('.wrapper_2').removeClass('md-size-50').addClass('md-size-100');
      }
    },
    verifyCaptchaMethod(recaptchaToken) {
      this.captcha = recaptchaToken;
    }
  },
  mounted: function () {
    card = elements.create("card");
    card.mount(this.$refs.card);
    //this.GetPlanDetail();
    this.resizeWindow();
  },
  beforeMount () {;
    this.GetPlanDetail();
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
};
</script>
<style type="text/css">
/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*stripe css end*/
.wrapper_1 {
  background-color:#527eee;
  padding: 50px;
}
.wrapper_2 {
  background-color:var(--md-theme-default-background, #fff);
  padding: 50px;
}
.float-right{
  float: right;
}
.color-white{
  color: var(--md-theme-default-background, #fff);
}
.color-black{
  color: black;
}
.coupon{
    border-radius: 1px
}

</style>