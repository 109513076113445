import http from "../http-common";

class ApplicationDataService {
    GetApplicationUserList(user_name="", email="", serial_number="",) {
        return http.get("application-user/list?user_name="+user_name+"&email="+email+"&serial_number="+serial_number)
    }
    CreateUser(data) {
        return http.post("application-user/create", data);
    }
    DeleteApplicationUser(data) {
        return http.post("application-user/delete", data);
    }
    GetApplicationUserDetail(id) {
        return http.get("application-user/detail?id="+id);
    }
    UpdateUser(data) {
        return http.post("application-user/update", data);
    }
    GetDevicesList() {
        return http.get("application-user/devices/list");
    }
    ImportUser(data) {
        return http.post("application-user/import", data,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          });
    }
}

export default new ApplicationDataService();