<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float:right;">
                    <md-button class="md-success" :disabled='isDisabled' @click="CreateEmailConfig()">Save</md-button>
                </div>
                <hr>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <select id="domain_dropdwon">
                                <option>Select Domain</option>
                                <option v-for="dom in domain_list" v-bind:key="dom._id" :value="dom.domain">
                                    {{ dom.domain }}
                                </option>
                            </select>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <input type="file" @change="handleFileUpload( $event )"/>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import DomainDataService from "../../../services/SuperAdmin/DomainDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      domain: "",
      credentialJsonFile: null,
      domain_list: [],
    };
  },
  methods: {
      CreateEmailConfig() {
        if (! this.credentialJsonFile) {
            this.$toast.error("Please select json file for email configuration");
            return false;
        }
        
        if (this.credentialJsonFile.type != "application/json") {
            this.$toast.error("Please select only json file for email configuration");
            return false;
        }
        this.isDisabled =true;
        let formData = new FormData();
        formData.append("credentialJsonFile", this.credentialJsonFile);
        formData.append("domain", this.domain);

        DomainDataService.CreateEmailConfig(formData)
          .then((response) => {
            this.isDisabled =false;
            this.$toast.success(response.data.respones_msg);
            this.$router.push("/admin-email-configuration-list");
          })
          .catch((e) => {
            this.isDisabled =false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      handleFileUpload(event) {
        this.credentialJsonFile = event.target.files[0];
      },
      DomainList() {
        DomainDataService.DomainList()
          .then((response) => {
            if (response.data.data) {
              this.domain_list = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.DomainList()
  },
  mounted() {
    $('#domain_dropdwon').select2({
      placeholder: "Select Domain"
    }).on('select2:select', (e) => {
      if (e.params.data.text == "Select Domain") {
         this.domain = "";
      } else {
         this.domain = e.params.data.text;
      }
    });
  }
};
</script>

<style>
</style>
