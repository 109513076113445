<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-actions class="text-center">
          <md-button
            type="submit"
            class="md-success"
            @click=OpenModel()
            >Create Config
          </md-button>
          <md-button class="md-danger" @click="$router.push({ path: 'view-profiles', query: { profile_id:  profile_id  }})">Back</md-button>
        </md-card-actions>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Package Name">{{ item.package_name }}</md-table-cell>
              <md-table-cell md-label="mcmId">{{ item.mcmId }}</md-table-cell>
              <md-table-cell md-label="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Created On">{{ item.created_at }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button class="md-just-icon md-info" style="width: 100px; font-size: 13px" @click="OpenModel(item.mcmId)">Edit</md-button>&nbsp;
                <md-button class="md-just-icon md-success" style="width: 100px; font-size: 13px" @click="ApplyAppConfigToPolicy(item.mcmId,item.package_name)">Apply</md-button>&nbsp;
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        
      </md-card>
      <template>
              <modal v-if="classicModal" @close="classicModalHide">
                <template slot="header">
                  <h4 class="modal-title">App Config</h4>
                  <md-button
                    style="height: 25px"
                    class="md-simple md-just-icon md-round modal-default-button"
                    @click="classicModalHide"
                  >
                    <md-icon>clear</md-icon>
                  </md-button>
                </template>
                <template slot="body">
                <div class="box-body">
                  
                    <div class="col-md-12 table-responsive" id="container"></div>
                  
                </div>
                </template>
                <template slot="footer">
                  <md-button
                    class="md-danger md-simple"
                    @click="classicModalHide"
                    >Close</md-button
                  >
                </template>
              </modal>
        </template>
    </div>
  </div>
</template>
<script>
import { Modal } from "@/components";
import ApplicationDataService from "../../services/ApplicationDataService";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      classicModal: false,
      token: null,
      profile_id: this.$route.query.profile_id,
      tableData: [],
    };
  },
  methods: {
    classicModalHide: function () {
      this.classicModal = false;
      this.GetApplicationConfigList();
    },
    OpenModel: function(mcmId = null) {
      this.classicModal = true;
      this.CreateWebToken(mcmId);
    },
    GetApplicationConfigList() {
      ApplicationDataService.GetApplicationConfigList(this.$route.query.package_name, this.profile_id)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    CreateWebToken(mcmId) {
      var data ={'page' : 'manage-app-config'};
      ApplicationDataService.CreateWebToken(data)
        .then((response) => {
          if (response.data.data) {
            this.token = response.data.data;
            LoadIframe(this.token, this.$route.query.package_name, mcmId, this.profile_id);
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    ApplyAppConfigToPolicy(mcmId, package_name) {
      var data ={'mcmId' : mcmId, 'package_name': package_name, 'profile_id': this.$route.query.profile_id};
      ApplicationDataService.ApplyAppConfigToPolicy(data)
        .then((response) => {
          if (response.data.data) {
            this.$toast.success(response.data.respones_msg);
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    }
  },
  beforeMount() {
    this.GetApplicationConfigList();
  },
};
function LoadIframe(token, package_name, mcmId, profile_id){
    var url = 'https://play.google.com/managed/mcm?token='+token+'&packageName='+package_name;
    if (mcmId) {
      url = 'https://play.google.com/managed/mcm?token='+token+'&packageName='+package_name+'&mcmId='+mcmId+'&canDelete=TRUE';
    }
    gapi.load('gapi.iframes', function() {
    var options = {
      'url': url,
      'where': document.getElementById('container'),
      'attributes': { style: 'width: 900px; height:400px', scrolling: 'no'}
    }

    var iframe = gapi.iframes.getContext().openChild(options);
    
    iframe.register('onconfigupdated', function(event) {
      //console.log("onconfigupdated : ",event);
      var data = {
        mcmId: event.mcmId,
        name: event.name,
        package_name: package_name,
        profile_id: profile_id
      };
      CreateApplicationConfig(data);
    }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);

    iframe.register('onconfigdeleted', function(event) {
     // console.log("onconfigdeleted : ",event);
      var data = {
        mcmId: event.mcmId,
      };
      DeleteApplicationConfig(data);
    }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);

  });
}

function CreateApplicationConfig(data) {  
  ApplicationDataService.CreateApplicationConfig(data)
    .then((response) => {
      this.$toast.success(response.data.respones_msg);
    })
    .catch((e) => {
      this.$toast.error(e);
    });
}

function DeleteApplicationConfig(data) {  
  ApplicationDataService.DeleteApplicationConfig(data)
    .then((response) => {
      this.$toast.success(response.data.respones_msg);
    })
    .catch((e) => {
      this.$toast.error(e);
    });
}

</script>
<style>
.modal-header {
  padding: 16px 20px !important;
  background-color: #3c4858 !important;
  border-radius: 6px 6px 0 0 !important;
  color: white !important;
}
.iframe-hieght {
  min-height:450px;
  height:450px;
  overflow:scroll;
}
.iframe-hieght div > iframe{
	height : 100vh !important;
}
.modal-container{
  max-width: 1000px !important;
}

.md-card .md-card-content .md-form-label {
  color: unset !important;
  font-weight: 400 !important;
}
.md-field label {
  color: unset !important;
  font-weight: 400 !important;
}
.md-switch .md-switch-label{
  color: unset !important;
  font-weight: unset !important;
}
</style>
