<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-content>
        <div style="float:right;">
            <md-button class="md-success" type="submit" :disabled='isDisabled' @click="UpdateProfile()">Save</md-button>
          </div>
          <hr>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Basic Settings</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Password Type
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordQuality" name="passwordQuality">
                    <md-option value="PASSWORD_QUALITY_UNSPECIFIED">PASSWORD_QUALITY_UNSPECIFIED</md-option>
                    <md-option value="BIOMETRIC_WEAK">BIOMETRIC_WEAK</md-option>
                    <md-option value="SOMETHING">SOMETHING</md-option>
                    <md-option value="NUMERIC">NUMERIC</md-option>
                    <md-option value="NUMERIC_COMPLEX">NUMERIC_COMPLEX</md-option>
                    <md-option value="ALPHABETIC">ALPHABETIC</md-option>
                    <md-option value="ALPHANUMERIC">ALPHANUMERIC</md-option>
                    <md-option value="COMPLEX">COMPLEX</md-option>
                    <md-option value="COMPLEXITY_LOW">COMPLEXITY_LOW</md-option>
                    <md-option value="COMPLEXITY_MEDIUM">COMPLEXITY_MEDIUM</md-option>
                    <md-option value="COMPLEXITY_HIGH">COMPLEXITY_HIGH</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Min Password length
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumLength" name="passwordMinimumLength" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=4>4</md-option>
                    <md-option value=5>5</md-option>
                    <md-option value=6>6</md-option>
                    <md-option value=7>7</md-option>
                    <md-option value=8>8</md-option>
                    <md-option value=9>9</md-option>
                    <md-option value=10>10</md-option>
                    <md-option value=11>11</md-option>
                    <md-option value=12>12</md-option>
                    <md-option value=13>13</md-option>
                    <md-option value=14>14</md-option>
                    <md-option value=15>15</md-option>
                    <md-option value=16>16</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Password Management Settings</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Password Expiry Period
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=4>Never</md-option>
                    <md-option value=5>In a Week</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Maximum Password History List
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=4>Never</md-option>
                    <md-option value=5>Current Password</md-option>
                    <md-option value=5>Last 5 Password</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Maximum Failed Attempt to Factory Reset
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=4>Never</md-option>
                    <md-option value=5>After 5 Attampts</md-option>
                    <md-option value=5>After 10 Attampts</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Set Idle time for Auto Lock (in minutes)
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=4>1 minutes</md-option>
                    <md-option value=5>3 minutes</md-option>
                    <md-option value=5>5 minutes</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h4 class="card-title">Advance Settings</h4>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Minimum Number of Symbols
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumSymbols" name="passwordMinimumSymbols" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING','NUMERIC'].indexOf(passwordQuality) !== -1">
                    <md-option value=1>1</md-option>
                    <md-option value=2>2</md-option>
                    <md-option value=3>3</md-option>
                    <md-option value=4>4</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Minimum Number of Alphabets
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumLetters" name="passwordMinimumLetters" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING','NUMERIC'].indexOf(passwordQuality) !== -1">
                    <md-option value=1>1</md-option>
                    <md-option value=2>2</md-option>
                    <md-option value=3>3</md-option>
                    <md-option value=4>4</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Minimum Number of Lower-case Characters
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumLowerCase" name="passwordMinimumLowerCase" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING','NUMERIC'].indexOf(passwordQuality) !== -1">
                    <md-option value=1>1</md-option>
                    <md-option value=2>2</md-option>
                    <md-option value=3>3</md-option>
                    <md-option value=4>4</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Minimum Number of Upper-case Characters
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumUpperCase" name="passwordMinimumUpperCase" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING','NUMERIC'].indexOf(passwordQuality) !== -1">
                    <md-option value=1>1</md-option>
                    <md-option value=2>2</md-option>
                    <md-option value=3>3</md-option>
                    <md-option value=4>4</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                Minimum Number of Digits
              </label>
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="passwordMinimumNumeric" name="passwordMinimumNumeric" :disabled="['PASSWORD_QUALITY_UNSPECIFIED','BIOMETRIC_WEAK','SOMETHING'].indexOf(passwordQuality) !== -1">
                    <md-option value=1>1</md-option>
                    <md-option value=2>2</md-option>
                    <md-option value=3>3</md-option>
                    <md-option value=4>4</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      passwordQuality: null,
      passwordMinimumLength: null,
      passwordMinimumSymbols: null,
      passwordMinimumLowerCase: null,
      passwordMinimumLetters: null,
      passwordMinimumUpperCase: null,
      passwordMinimumNumeric: null,
    };
  },
};
</script>
<style>
hr {
  border: 0;
  clear:both;
  width: 100%;               
  background-color:#555855;
  height: 1px;
}
input[type="text"]:disabled {
    background-color: #dddddd;
}
</style>