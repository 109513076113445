<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>security</md-icon>
          </div>
          <h4 class="title">Vpp Token</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <p>Volume Purchase Program (VPP) - Apple's Volume Purchase Program (VPP) allows you to purchase Apps on behalf of your employees or students and distribute them through {{ WhiteLabellingTitle() }}.</p>
            <label><b>Current Configuration</b></label>
            <hr><br>
          </div>
          <div v-if="ios_mdm_vpp_token_detail">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <div class="w3-responsive">
                  <table class="ws-table-all">
                    <tbody>
                      <tr>
                        <th>Organization</th>
                        <td>{{ ios_mdm_vpp_token_detail.orgName }}</td>
                      </tr>
                      <tr>
                        <th>Expires</th>
                        <td>{{ HumanReadableDateFormat(ios_mdm_vpp_token_detail.expDate) }}</td>
                      </tr>
                      <tr>
                        <th>Last Synced</th>
                        <td v-if="last_sync_time">{{ HumanReadableDateFormat(last_sync_time) }}</td>
                        <td v-else>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="md-layout-item md-size-50">
                <div class="alert alert-secondary alert-dismissible fade show" role="alert">
                  <small>Q. Why do I need to Renew the VPP Token?</small><br>
                  <small>A. The validity of the token is only for a year. If not renewed before the expiry, then NovaEMM would not be able to communicate with your VPP account and manage the VPP purchased apps.</small>
                </div>
                <md-button class="md-danger" :disabled="isDisabled" @click="DeleteVppToken()">Delete VPP Token</md-button>
              </div>
            </div>
            <div class="md-layout">
              <a href="javascript:void(0)" @click="$router.push({ path: 'vpp-apps'})">
              <div class="md-layout-item md-size-30" style="border: 1px solid #256bff;display: flex;align-items:center;min-width: unset;max-width: unset;">
                <img src="https://app.scalefusion.com/cloud-assets/v2/icons/vpp_apps-d6c819b4898ea3e3e250a9a43bd241208cef189710724515746569946c91cd62.svg" style="width: 10%" />
                &nbsp;<p>Total VPP Apps : {{ ios_mdm_vpp_apps_count }}</p>
              </div>
              </a>
            </div>
          </div>

          <div class="md-layout" v-if="!ios_mdm_vpp_token_detail">
            <!--<div class="md-layout-item">
              <md-field>
                  <label>Enter Managed Apple Id</label>
                  <md-input type="text" v-model="ios_mdm_vpp_email"></md-input>
              </md-field>    
            </div>-->
            <div class="md-layout-item md-size-100">
                <md-field>
                    <label>Enter VPP Token</label>
                    <md-input type="text" v-model="ios_mdm_vpp_token"></md-input>
                </md-field>    
            </div>
            <div class="md-layout-item md-size-100">
              <md-button class="md-success" :disabled="isDisabled" @click="UpdateVppToken()">Save</md-button>
            </div>
          </div>

        </md-card-content>

        <md-card-actions md-alignment="left">
          
        </md-card-actions>
      </md-card>  
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isDisabled: false,
      ios_mdm_vpp_token: "",
      ios_mdm_vpp_email: "",
      ios_mdm_vpp_token_detail: "",
      ios_mdm_vpp_apps: [],
      ios_mdm_vpp_apps_count: 0,
      last_sync_time: null
    }
  },
  methods: {
    UpdateVppToken() {
      this.isDisabled = true;
      var data = {
        ios_mdm_vpp_token: this.ios_mdm_vpp_token,
        ios_mdm_vpp_email: this.ios_mdm_vpp_email,
      };

      UsersDataService.UpdateVppToken(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetAdminDetail();
          this.SyncVppApps();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    DeleteVppToken() {
      this.isDisabled = true;
      let data = {
      };

      Swal.fire({
        title: "Are you sure?",
        text: `VPP token will be deleted.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          UsersDataService.DeleteVppToken(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.GetAdminDetail();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
        } else {
          this.isDisabled = false;
        }
      });
    },
    GetAdminDetail() {
      var data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          this.ios_mdm_vpp_token = response.data.data.ios_mdm_vpp_token;
          this.ios_mdm_vpp_token_detail = response.data.data.ios_mdm_vpp_token_detail;
          this.ios_mdm_vpp_email = response.data.data.ios_mdm_vpp_email;
          this.last_sync_time = response.data.data.ios_mdm_vpp_last_sync_time;
          if (response.data.data.ios_mdm_vpp_assets) {
            this.ios_mdm_vpp_apps_count = response.data.data.ios_mdm_vpp_assets.length;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    SyncVppApps() {
      this.isDisabled = true;
      UsersDataService.SyncVppApps()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetAdminDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
};
</script>