import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import SuperAdminAuthLayout from "@/pages/Dashboard/Pages/SuperAdminAuthLayout.vue";
import SuperAdminDashboardLayout from "@/pages/Dashboard/Layout/SuperAdmin/SuperAdminDashboardLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

// Android Enterprise Routes
import AndroidEnterpirse from "@/pages/Dashboard/Enterprise/Enterprise.vue";

// Device Profile Routes
import DeviceProfile from "@/pages/DeviceProfiles/DeviceProfiles.vue";
import ViewProfile from "@/pages/DeviceProfiles/ViewProfiles.vue";
import ViewIosProfile from "@/pages/DeviceProfiles/ViewIosProfiles.vue";

// QR Code Configuration Routes
import QRCode from "@/pages/QrCode/QrCodeList.vue";
import QrCodeInfo from "@/pages/QrCode/QrCodeInfo.vue";

// Applications Routes
import Applications from "@/pages/Applications/ApplicationsList.vue";
import ManagedApplicationConfig from "@/pages/Applications/ApplicationConfig.vue";
import AppStore from "@/pages/Applications/AppStore.vue";
import EnterpriseApp from "@/pages/Applications/EnterpriseApp.vue";

// IOS Application Config
import ManagedIosApplicationConfig from "@/pages/Applications/ManagedIosApplicationConfig.vue";

// Devices
import Devices from "@/pages/Devices/DeviceList.vue";
import DeviceDetail from "@/pages/Devices/DeviceDetail.vue";
import IosDeviceDetail from "@/pages/Devices/IosDeviceDetail.vue";

// Payment
import StripeSubscription from "@/pages/Payment/StripeSubscription.vue";
import Cards from "@/pages/Cards/CardList.vue";
import PlanList from "@/pages/Payment/PlanList.vue";
import PaymentSuccess from "@/pages/Payment/PaymentSuccess.vue";
import PaymentFail from "@/pages/Payment/PaymentFail.vue";
import PaymentHistory from "@/pages/Payment/PaymentHistory.vue";

// Password Policy
import PasswordPolicy from "@/pages/PasswordPolicy/PasswordPolicy.vue";

// Users Route Routes
import Users from "@/pages/Users/UserList.vue";
import CreateUser from "@/pages/Users/UserCreate.vue";
import EditUser from "@/pages/Users/UserEdit.vue";

//Login User Profile Routes
import ChangePassword from "@/pages/Auth/ChangePassword.vue";
import UpdateProfile from "@/pages/Auth/UpdateProfile.vue";
import AuditLogs from "@/pages/Auth/AuditLogs.vue";

// Forget Password
import ForgetPassword from "@/pages/Dashboard/Pages/ForgetPassword.vue";
import ResetPassword from "@/pages/Dashboard/Pages/ResetPassword.vue";

// Getting Started Wizard
import GettingStarted from "@/pages/GettingStarted/GettingStarted.vue";
import ConfigureAndroid from "@/pages/GettingStarted/ConfigureAndroid.vue";
import ConfigureIos from "@/pages/GettingStarted/ConfigureIos.vue";
import ConfigureCustomAndroid from "@/pages/GettingStarted/ConfigureCustomAndroid.vue";


// Privacy & Security
import PrivacySecurity from "@/pages/Auth/PrivacySecurity.vue";
import VerifyOtp from "@/pages/Dashboard/Pages/VerifyOtp.vue";
import VppApplicationToken from "@/pages/Auth/VppApplicationToken.vue";
import VppApplicationsList from "@/pages/Auth/VppApplicationsList.vue";

// Webinar Routes
import WebinarList from "@/pages/Webinar/List.vue";
import WebinarDetail from "@/pages/Webinar/Detail.vue";

// Super Admin Auth
import SuperAdminLogin from "@/pages/SuperAdmin/Login.vue";
import SuperAdminVerifyOtp from "@/pages/SuperAdmin/VerifyOtp.vue";
import SuperAdminDashboard from "@/pages/SuperAdmin/Dashboard.vue";
import SuperAdminDbList from "@/pages/SuperAdmin/DbList.vue";
import AllUserList from "@/pages/SuperAdmin/Users/UserList.vue";
import UserDetail from "@/pages/SuperAdmin/Users/UserDetail.vue";
import SuperAdminChangePassword from "@/pages/SuperAdmin/ChangePassword.vue";
import SuperAdminDeviceList from "@/pages/SuperAdmin/DeviceList.vue";
import SuperAdminDbRestore from "@/pages/SuperAdmin/DbRestore.vue";
import ExcludeUsers from "@/pages/SuperAdmin/Payment/ExcludeUsers.vue";
import DisabledFactoryResetUsers from "@/pages/SuperAdmin/Payment/DisabledFactoryResetUsers.vue";

//Domain Routes
import SuperAdminDomainList from "@/pages/SuperAdmin/DomainList.vue";
import SuperAdminDomainCreate from "@/pages/SuperAdmin/DomainCreate.vue";
import SuperAdminDomainEdit from "@/pages/SuperAdmin/DomainEdit.vue";
//Email Configuration Routes
import SuperAdminEmailConfigurationCreate from "@/pages/SuperAdmin/EmailConfiguration/Create.vue";
import SuperAdminEmailConfigurationList from "@/pages/SuperAdmin/EmailConfiguration/List.vue";
//Logs
import EnrollDeviceLogs from "@/pages/SuperAdmin/Logs/EnrollDeviceLogs.vue";
import CreateWebinar from "@/pages/SuperAdmin/Webinar/Create.vue";
//Super Admin Webinars
import AdminWebinarList from "@/pages/SuperAdmin/Webinar/List.vue";
import AdminWebinarRegistrationList from "@/pages/SuperAdmin/Webinar/RegistrationList.vue";
import AdminWebinarRequestList from "@/pages/SuperAdmin/Webinar/RequestList.vue";

import auth from '../middleware/auth';
import beforeAuth from '../middleware/beforeAuth';

import beforeSuperAdminAuth from '../middleware/beforeSuperAdminAuth';
import superAdminAuth from '../middleware/superAdminAuth';

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      meta: {
        middleware: [auth],
      },
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
      },
      components: { default: RtlSupport },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/verify-otp",
      name: "VerifyOtp",
      component: VerifyOtp,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let superAdminAuthPages = {
  path: "/",
  component: SuperAdminAuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/super-admin/login",
      name: "SuperAdminLogin",
      component: SuperAdminLogin,
      meta: {
        middleware: [beforeSuperAdminAuth],
      },
    },
    {
      path: "/super-admin/verify/otp",
      name: "Super Admin Verify OTP",
      component: SuperAdminVerifyOtp,
      meta: {
        middleware: [beforeSuperAdminAuth],
      },
    },
  ],
};

let superAdminDashboardPages = {
  path: "/",
  component: SuperAdminDashboardLayout,
  children: [
    {
      path: "admin-dashboard",
      name: "Super Admin Dashboard",
      component: SuperAdminDashboard,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-db-list",
      name: "Super Admin Db List",
      component: SuperAdminDbList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-device-list",
      name: "Super Admin Device List",
      component: SuperAdminDeviceList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-user-list",
      name: "Super Admin User List",
      component: AllUserList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-user-detail",
      name: "Super Admin User Detail",
      component: UserDetail,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-restore-db",
      name: "Database Restore",
      component: SuperAdminDbRestore,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-domains-list",
      name: "Domains List",
      component: SuperAdminDomainList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-domains-create",
      name: "Create Domain",
      component: SuperAdminDomainCreate,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-domains-edit",
      name: "Edit Domain",
      component: SuperAdminDomainEdit,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-email-configuration-list",
      name: "List Email Configuration",
      component: SuperAdminEmailConfigurationList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-email-configuration-create",
      name: "Create Email Configuration",
      component: SuperAdminEmailConfigurationCreate,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-change-password",
      name: "Change Password",
      component: SuperAdminChangePassword,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-exclude-users",
      name: "Exclude Users From Payment",
      component: ExcludeUsers,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-disabled-factory-reset-users",
      name: "Disabled Factory Reset Users",
      component: DisabledFactoryResetUsers,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "logs",
      name: "Enroll Device Logs",
      component: EnrollDeviceLogs,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-create-webinar",
      name: "Create Webinar",
      component: CreateWebinar,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-webinars",
      name: "Webinars List",
      component: AdminWebinarList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-webinar-registrations",
      name: "Webinars Registration List",
      component: AdminWebinarRegistrationList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-webinar-request",
      name: "Webinars Requests List",
      component: AdminWebinarRequestList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
  ],
}

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  {
    path: "/super-admin",
    redirect: "super-admin/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  superAdminAuthPages,
  superAdminDashboardPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "getting-started",
        name: "Getting Started",
        components: { default: GettingStarted },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "configure-android",
        name: "Configure Android",
        components: { default: ConfigureAndroid },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "configure-custom-android",
        name: "Configure Custom Android",
        components: { default: ConfigureCustomAndroid },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "configure-ios",
        name: "Configure IOS",
        components: { default: ConfigureIos },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "android-enterprise",
        name: "Android Enterprise",
        components: { default: AndroidEnterpirse },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "device-profiles",
        name: "Device Profile",
        components: { default: DeviceProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "view-profiles",
        name: "",
        components: { default: ViewProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "view-ios-profiles",
        name: "View Ios Profile",
        components: { default: ViewIosProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "configuration-files",
        name: "QR Code Configuration",
        components: { default: QRCode },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "scan-qrcode",
        name: "QR Code Information",
        components: { default: QrCodeInfo },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "applications",
        name: "Applications",
        components: { default: Applications },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "app-store",
        name: "App Store Applications",
        components: { default: AppStore },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "enterprise-app",
        name: "Enterprise Applications",
        components: { default: EnterpriseApp },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "devices",
        name: "Devices",
        components: { default: Devices },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "device",
        name: "Device",
        components: { default: DeviceDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "ios-device",
        name: "Ios Device",
        components: { default: IosDeviceDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "subscription",
        name: "Subscription",
        components: { default: StripeSubscription },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "plan-list",
        name: "PlanList",
        components: { default: PlanList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment-success",
        name: "PaymentSuccess",
        components: { default: PaymentSuccess },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment-fail",
        name: "PaymentFail",
        components: { default: PaymentFail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment-history",
        name: "Payment History",
        components: { default: PaymentHistory },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "cards",
        name: "Cards",
        components: { default: Cards },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "users",
        name: "Users",
        components: { default: Users },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "users/create",
        name: "Create User",
        components: { default: CreateUser },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "users/edit",
        name: "",
        components: { default: EditUser },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "password-policy",
        name: "PasswordPolicy",
        components: { default: PasswordPolicy },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "manage-app-config",
        name: "ManagedApplicationConfig",
        components: { default: ManagedApplicationConfig },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "manage-ios-app-config",
        name: "ManagedIosApplicationConfig",
        components: { default: ManagedIosApplicationConfig },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        components: { default: ChangePassword },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "privacy-security",
        name: "PrivacySecurity",
        components: { default: PrivacySecurity },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "vpp-token",
        name: "VppApplicationToken",
        components: { default: VppApplicationToken },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "vpp-apps",
        name: "VppApplicationsList",
        components: { default: VppApplicationsList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "update-profile",
        name: "UpdateProfile",
        components: { default: UpdateProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "audit-logs",
        name: "Audit Logs",
        components: { default: AuditLogs },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "webinars",
        name: "Webinars List",
        components: { default: WebinarList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "webinar",
        name: "Webinars Detail",
        components: { default: WebinarDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
    ],
  },
];

export default routes;
