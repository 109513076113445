<template>
  <div class="md-layout text-center">
    <p>Welcome to dashboard</p>
  </div>
</template>
<script>

export default {
  
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    
  },
  beforeMount() {
    
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
.md-card-actions{
  padding: unset !important;
}
</style>
