import http from "../../http-common-super-admin";

class DbDataService {
    DbList(from_date, to_date) {
      return http.get("/db/list?from_date="+from_date+"&to_date="+to_date);
    }
    DbRestore(data, config) {
        return http.post("db/restore", data,  config);
    }
    RestoreList() {
      return http.get("db/restore/list");
  }
}

export default new DbDataService();