<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <p>{{ webinarDetail.title }}</p>
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">  
              <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="DB">{{ item.db_name }}</md-table-cell>
              <md-table-cell md-label="Notes">{{ item.notes }}</md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
            </md-table-row>
          </md-table>
          <span v-if="tableData.length == 0">No data found.</span>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import WebinarService from "../../../services/SuperAdmin/WebinarService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      webinarDetail: "",
      webinarID: this.$route.query.id,
    };
  },
  methods: {
      webinarRequestList () {
        let data = { id: this.webinarID };
        WebinarService.webinarRequestList(data)
          .then((response) => {
            this.webinarDetail = response.data.webinarDetail;
            if (response.data.data) {
              this.tableData = response.data.data;
            } else {
                this.tableData = [];
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.webinarRequestList();
  },
};
</script>

<style>
</style>
