<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float:right;">
                    <md-button class="md-success" :disabled='isDisabled' @click="UpdateDomain()">Save</md-button>
                </div>
                <hr>
                <h4>Basic Details</h4>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Domain</b></label>
                            <md-input type="text" v-model="domain"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Title</b></label>
                            <md-input type="text" v-model="title"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>About</b></label>
                            <md-textarea type="text" v-model="about"></md-textarea>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Address</b></label>
                            <md-textarea type="text" v-model="address"></md-textarea>
                        </md-field>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <label><b>Phone Number</b></label>
                            <md-input type="text" v-model="phone_number"></md-input>
                        </md-field>
                    </div>
                </div>

                <div v-if="enterprise_detail">
                    <h4>Enterprise Details</h4>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <b>Name : {{ enterprise_detail.name }}</b>
                        </div>
                    </div>
                </div>

                <h4>Links Section</h4>
                <div class="md-layout" v-for="(link) in links" :key="link.title">
                    <div class="md-layout-item md-size-40">
                        <md-field>
                            <label><b>Title</b></label>
                            <md-input type="text" name="links_title[]" :value="link.title"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-40">
                        <md-field>
                            <label><b>Url</b></label>
                            <md-input type="text" name="links_url[]" :value="link.link"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-10">
                        <md-button class="md-just-icon md-danger m-0" @click="DeleteLink(link.link)"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import DomainDataService from "../../services/SuperAdmin/DomainDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      domain: "",
      title: "",
      about: "",
      address: "",
      phone_number: "",
      links: [],
      links_array_length : 1,
      enterprise_detail: "",
    };
  },
  methods: {
      UpdateDomain() {
        let titles = $("input[name='links_title[]']")
            .map(function(){ 
            return $(this).val();
        }).get();

        let urls = $("input[name='links_url[]']")
            .map(function(){ 
            return $(this).val();
        }).get();

        // Prepare links array 
        let links = []
        titles.forEach((value,index) => {
            if (value && urls[index]) {
                links.push({
                    "title": value,
                    "link": urls[index]
                })
            }
        });

        let reqData = {
            domain: this.domain,
            title: this.title,
            about: this.about,
            address: this.address,
            phone_number: this.phone_number,
            links: links,
            id: this.$route.query.id,
        }
        DomainDataService.UpdateDomain(reqData)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.$router.push("/admin-domains-list");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },

    DeleteLink(link) {
        this.links = this.links.filter(function(linkVal) {
            return link !== linkVal.link
        });
    },

    GetDomainDetail() {
        DomainDataService.GetDomainDetail(
            this.$route.query.id
        ).then((response) => {
            if (response.data.data) {
                this.domain = response.data.data.domain;
                this.title = response.data.data.title;
                this.about = response.data.data.about;
                this.address = response.data.data.address;
                this.phone_number = response.data.data.phone_number;
                this.links = response.data.data.links;
                if (response.data.data.enterprise_detail) {
                    this.enterprise_detail = response.data.data.enterprise_detail;
                } else {
                    this.enterprise_detail =  "";
                }
            }
        }).catch((e) => {
            this.$toast.error(e);
        });
    },
  },
  beforeMount() {
    this.GetDomainDetail()
  },
};
</script>

<style>
</style>
