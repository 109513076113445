<template>
  <div class="md-layout text-center">
    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="this.show_message">
      <strong>Congratulation!</strong> A message with a reset password link has been sent to your email address. Please open the link to set your new password.
    </div><br>
      <login-card header-color="green">
        <h4 slot="title" class="title">Reset Password</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Confirm Password</label>
          <md-input v-model="confirm_password" type="password"></md-input>
        </md-field>
        <div slot="footer">
          <md-button class="md-success" v-on:click="ResetPassword()" :disabled="isDisabled">
            Change Password
          </md-button>  
        </div>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import UserDataService from "../../../services/UsersDataService";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      password: null,
      confirm_password: null,
      isDisabled: false,
    };
  },
  methods: {
    ResetPassword() {
        this.isDisabled = true;
        var data = {
            password: this.password,
            confirm_password: this.confirm_password,
            iv: this.$route.query.iv,
            encrypted_data: this.$route.query.encryptedData
        };
        UserDataService.ResetPassword(data)
            .then((response) => {
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                this.$router.push('/login');
            })
            .catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
</style>
