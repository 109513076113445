<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-button class="md-success f-right-padding" :disabled="isDisabled" @click="SyncDevice()">Sync Device</md-button>&nbsp;
    <div v-if="!device_detail">
        Please click on Sync Device Button to fetch device detail
    </div>
    <div v-else>
        <tabs :tab-name="['Basic', 'Applications', 'Location', 'Lost Mode']" color-button="warning">
        <template slot="tab-pane-1">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Profile</th>
                            <td>
                              <a
                                @click="
                                  $router.push({
                                    path: 'view-ios-profiles',
                                    query: {
                                      profile_id: device_detail.profile_id._id,
                                    },
                                  })
                                "
                                href="javascript:void(0)"
                                >{{ device_detail.profile_id.profile_name }}</a
                              >
                            </td>
                          </tr>
                          <tr>
                            <th>User</th>
                            <td>{{ userName }}</td>
                          </tr>
                          <tr>
                            <th>serialNumber</th>
                            <td>{{ device_detail.serialNumber }}</td>
                          </tr>
                          <tr>
                            <th>Is Supervised</th>
                            <td>
                              <p class="badge-blue" v-if="device_detail.json_response.IsSupervised">Yes</p>
                              <p class="badge-red" v-else>No</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Battery Level</th>
                            <td>
                              {{ device_detail.json_response.BatteryLevel }}
                            </td>
                          </tr>
                          <tr>
                            <th>Bluetooth MAC</th>
                            <td>
                              {{ device_detail.json_response.BluetoothMAC }}
                            </td>
                          </tr>
                          <tr>
                            <th>WiFi MAC</th>
                            <td>{{ device_detail.json_response.WiFiMAC }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div class="w3-responsive">
                      <table class="ws-table-all">
                        <tbody>
                          <tr>
                            <th style="width: 30%">Model</th>
                            <td>{{ device_detail.json_response.Model }}</td>
                          </tr>
                          <tr>
                            <th>Model Name</th>
                            <td>{{ device_detail.json_response.ModelName }}</td>
                          </tr>
                          <tr>
                            <th>OS Version</th>
                            <td>{{ device_detail.json_response.OSVersion }}</td>
                          </tr>
                          <tr>
                            <th>Product Name</th>
                            <td>
                              {{ device_detail.json_response.ProductName }}
                            </td>
                          </tr>
                          <tr>
                            <th>UDID</th>
                            <td>{{ device_detail.json_response.UDID }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-2">
          <div class="pt-10">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <md-card>
                  <md-card-content>
                    <md-table
                      :value="queriedData"
                      class="
                        paginated-table
                        table-striped table-hover table-full-width
                      "
                      table-header-color="green"
                      md-fixed-header
                    >
                      <md-table-toolbar>
                        <md-field>
                          <label for="pages">Per page</label>
                          <md-select v-model="pagination.perPage" name="pages">
                            <md-option
                              v-for="item in pagination.perPageOptions"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                              {{ item }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </md-table-toolbar>
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Display Name"
                          >{{ item.Name }}
                          <small>( {{ item.Identifier }} )</small>
                        </md-table-cell>
                        <md-table-cell md-label="Version">{{
                          item.Version
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>
                  </md-card-content>
                  <md-card-actions md-alignment="space-between">
                    <div class="">
                      <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of
                        {{ total }} entries
                      </p>
                    </div>
                    <pagination
                      class="pagination-no-border pagination-success"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
                    >
                    </pagination>
                  </md-card-actions>
                </md-card>
              </div>
            </div>
          </div>
        </template>
        <template slot="tab-pane-3">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-card>
                  <md-card-content>
                   <small class="text-danger"><b>Note : </b>Device location only work with when devices is in lost mode. You can enable lost mode from profile detail page.</small>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-small-size-100">
                <template>
                  <l-map style="height: 300px" :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" ></l-tile-layer>
                    <l-marker :lat-lng="markerLatLng"></l-marker>
                  </l-map>
                </template>
              </div>
              <!--<div class="md-layout-item md-small-size-100">
                <md-card>
                  <md-card-content>
                    <div id="regularMap" class="map"></div>
                  </md-card-content>
                </md-card>
              </div>-->
            </div>
        </template>
        <template slot="tab-pane-4">
          <md-card>
              <md-card-content>
                <div style="float: right;padding-right: 15px;">
                  <md-button class="md-success" @click="ApplyDeviceLostMode()" :disabled="isDisabled">Apply</md-button>
                </div>
                <br />
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-switch v-model="enable_lost_mode">Enable Lost Mode</md-switch>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Foot Note</label>
                      <md-input type="text" v-model="lock_footnote" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Lock Message</label>
                      <md-input type="text" v-model="lock_message" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Phone Number</label>
                      <md-input type="text" v-model="lock_phone_number" :disabled="!this.enable_lost_mode"></md-input>
                    </md-field>
                  </div>
                </div>
            </md-card-content>
          </md-card>
        </template>
      </tabs>
    </div>
      
    </div>
  </div>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import { Tabs } from "@/components";
import { Pagination } from "@/components";
import { API_KEY } from "../Dashboard/Maps/API_KEY";
import { Loader, LoaderOptions } from "google-maps";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const loader = new Loader(API_KEY);

export default {
  components: {
    Tabs,
    Pagination,
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      device_detail: null,
      batteryInfoArray: [],
      applicationArray: [],
      applicationArrayBackup: [],
      applicationStatArray: [],
      applicationSourceArray: [],
      search_state: "",
      search_source: "",
      search_package_name: "",
      search_package_id: "",
      isDisabled: false,

      // Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      searchedData: [],

      //Lost Mode
      enable_lost_mode: false,
      lock_footnote: "",
      lock_message: "",
      lock_phone_number: "",

      //Location
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:'',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159]
    };
  },
  computed: {
    userName: function () {
      return this.device_detail.user_id
        ? this.device_detail.user_id.user_name
        : "-";
    },

    // Pagination
    queriedData() {
      let result = this.applicationArray;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.applicationArray.length;
    },
  },
  methods: {
    GetDeviceDetail() {
      DeviceProceDataService.GetDeviceDetail(this.$route.query.device_id)
        .then((response) => {
          this.device_detail = response.data.data;
          this.enable_lost_mode = response.data.data.enable_lost_mode;
          if (response.data.data.enable_lost_mode_data) {
            this.lock_footnote = response.data.data.enable_lost_mode_data.lock_footnote;
            this.lock_message = response.data.data.enable_lost_mode_data.lock_message;
            this.lock_phone_number = response.data.data.enable_lost_mode_data.lock_phone_number;
          }
          if (response.data.data.json_response) {
            this.applicationArray = this.device_detail.device_applications;
            this.applicationArrayBackup =this.device_detail.device_applications;
          }
          if (this.device_detail.device_location) {
            let lat = this.device_detail.device_location.Latitude;
            let Longitude = this.device_detail.device_location.Longitude;
            this.center = [lat, Longitude];
            this.markerLatLng = [lat, Longitude];
          }
          //console.log("Center : ", this.center);
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    LoadMap() {
      document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';
      if (this.device_detail.device_location) {
        /*let lat = this.device_detail.device_location.Latitude;
        let Longitude = this.device_detail.device_location.Longitude;
        this.center = [lat, Longitude];
        this.markerLatLng = [lat, Longitude];*/

        /*loader.load().then(function () {
            // Regular Map Begin
            const myLatlng1 = new window.google.maps.LatLng(lat, Longitude);
            const mapOptions1 = {
              zoom: 15,
              center: myLatlng1,
              scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
            };

            const map1 = new window.google.maps.Map(
              document.getElementById("regularMap"),
              mapOptions1
            );

            const marker1 = new window.google.maps.Marker({
              position: myLatlng1,
              title: "Regular Map!",
            });

            marker1.setMap(map1);
            // Regular Map End 
        })*/
      }
    },

    SyncDevice() {
      this.isDisabled = true;
      DeviceProceDataService.SyncIosDevice(this.$route.query.device_id)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          window.location.reload();
          //this.GetDeviceDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    ApplyDeviceLostMode() {
      let data = {
        id:this.$route.query.device_id,
        enable_lost_mode: this.enable_lost_mode,
        enable_lost_mode_data : {
          lock_footnote: this.lock_footnote,
          lock_message: this.lock_message,
          lock_phone_number: this.lock_phone_number,
        }
      }
      this.isDisabled = true;
      DeviceProceDataService.ApplyDeviceLostMode(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceDetail();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    this.GetDeviceDetail();
  },
  
  updated: function () {
      this.$nextTick(() => {
        const that = this;
         $("#2").on("click", function() {
          that.LoadMap();
        });
      })
    },

};
</script>
<style scoped>
.pt-10 {
  padding-top: 10px;
}
.w3-responsive {
  display: block;
  overflow-x: auto;
}
.ws-table-all {
  /*border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;*/
  width: 100%;
  display: table;
}
.ws-table-all tr,
.ws-table-all td {
  /*border: 1px solid black;
    border-collapse: collapse;*/
}
.ws-table-all td {
  padding: 5px;
}
.ws-table-all th {
  text-align: left;
}
.leaflet-control-attribution leaflet-control {
  display: none !important;
}
</style>