import http from "../http-common";

class DeviceProceDataService {
    GetDeviceProfileList(data) {
        return http.get("device-profile/list", { params: data });
    }
    GetDeviceProfileDetail(profile_id) {
        return http.get("device-profile/detail?profile_id="+profile_id);
    }
    CreateProfile(data) {
        return http.post("device-profile/create", data);
    }
    UpdateProfile(data) {
        return http.post("device-profile/update", data);
    }
    UpdateProfileKiosk(data) {
        return http.post("device-profile/update/kiosk", data);
    }
    ApplyPolicy(data) {
        return http.post("device-profile/apply/policy", data);
    }
    DeleteProfile(data) {
        return http.post("device-profile/delete", data);
    }
    DeleteIosProfile(data) {
        return http.post("ios/profile/delete", data);
    }
    CopyProfile(data) {
        return http.post("device-profile/copy", data);
    }
    CreateWebToken(data) {
        return http.post("application/create/web/token", data);
    }
    GetProfileApplicationList(profile_id) {
        return http.get("device-profile/application/list?profile_id="+profile_id);
    }
    CreateProfileApplication(data) {
        return http.post("device-profile/application/create", data);
    }
    DeleteProfileApplication(data) {
        return http.post("device-profile/application/delete", data);
    }
    GetProfileDeviceList(data) {
        return http.get("device-profile/device/list", { params: data });
    }
    RefreshProfileDevice(profile_id) {
        return http.get("device-profile/device/list/refresh?profile_id="+profile_id);
    }
    IssueCommand(data) {
        return http.post("device-profile/device/issue/command", data);
    }
    exitDeviceKiosk(data) {
        return http.post("device-profile/device/exit/kiosk", data);
    }
    DeleteDevice(data) {
        return http.post("device-profile/device/delete", data);
    }
    AssignDeviceUser(data) {
        return http.post("device-profile/device/assign/user", data);
    }
    AddWifi(data) {
        return http.post("device-profile/wifi/add", data);
    }
    ListWifi(profile_id) {
        return http.get("device-profile/wifi/list?profile_id="+profile_id);
    }
    DeleteProfileWiFi(data) {
        return http.post("device-profile/wifi/delete", data);
    }
    UpdateDefaultWiFi(data) {
        return http.post("device-profile/wifi/change-default", data);
    }
    UpdateAppEnableDisableStatus(data) {
        return http.post("device-profile/application/update/status", data);
    }
    MoveDeviceProfile(data) {
        return http.post("device-profile/device/move", data);
    }
    SyncDevices(device_id = "") {
        return http.get("device/sync?id="+device_id);
    }
    GetDeviceDetail(device_id) {
        return http.get("device/detail?id="+device_id);
    }
    DeleteDeviceUser(data) {
        return http.post("device/delete/user", data);
    }
    AddDeviceUser(data) {
        return http.post("device/add/user", data);
    }
    UploadContent(data, config) {
        return http.post("device-profile/content/upload", data,  config);
    }
    GetContentPublicLink(data) {
        return http.post("device-profile/content/public-url/generate", data);
    }
    ListContent(profile_id) {
        return http.get("device-profile/content/list?profile_id="+profile_id);
    }
    DeleteProfileContent(data) {
        return http.post("device-profile/content/delete", data);
    }
    UpdateProfileContentNote(data) {
        return http.post("device-profile/content/update-note", data);
    }
    ManuallyAddContent(data) {
        return http.post("device-profile/content/manuall/add", data);
    }
    GlobalAddContent(data) {
        return http.post("device-profile/content/global/add", data);
    }
    ListChildContent(content_id) {
        return http.get("device-profile/content/child/list?content_id="+content_id);
    }
    DeleteProfileGlobalContent(data) {
        return http.post("device-profile/content/global/delete", data);
    }
    AddContentUser(data) {
        return http.post("device-profile/content/add/user", data);
    }
    DeleteContentUser(data) {
        return http.post("device-profile/content/delete/user", data);
    }
    SyncIosDevice(device_id) {
        return http.get("ios/profile/device/detail?id="+device_id);
    }
    ApplyDeviceLostMode(data) {
        return http.post("ios/profile/device/lostmode", data);
    }
    DeleteIosDevice(data) {
        return http.post("ios/profile/device/delete", data);
    }
    deletedHistory(data) {
        return http.get("device/delete/history", { params: data });
    }
    deleteAllProfiles() {
        return http.delete("device-profile/delete/all");
    }
}

export default new DeviceProceDataService();