<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green" md-fixed-header>
            <md-table-row slot="md-table-row" slot-scope="{ item }">            
              <md-table-cell md-label="File">
                <a :href="item.url" target="_blank">{{ item.file }}</a>
                </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>  
      </md-card>
    </div>
  </div>
</template>
<script>
import LogsService from "../../../services/SuperAdmin/LogsService";

export default {
  components: {
  },
  computed: {
    directory () {
        return this.$route.query.directory || 1
    }
    },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      directory_name: this.$route.query.directory,
    };
  },
  methods: {
      GetLogs() {
        let data = {
            directory_name: this.$route.query.directory
        }
        LogsService.GetLogs(data)
          .then((response) => {
            if (response.data.data) {
              this.tableData = response.data.data;
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.directory_name = this.$route.query.directory;
    this.GetLogs();
  },
  watch: {
        directory () {
            this.GetLogs();
        }
    }
};
</script>

<style>
</style>
