import http from "../http-common";

class DashboardDataService {
    GetDashboardData = async() => {
        return http.get("dashboard");
    }
    CreateNextCloudUser() {
        return http.get("dashboard/nextcloud/user/create");
    }
}

export default new DashboardDataService();