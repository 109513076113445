<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-warning" :disabled="isDisabled" @click="ExportUsers()">Export</md-button>
        <md-button class="md-primary" :disabled="isDisabled" @click="SyncUsers()">Sync</md-button>
      </div>
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="from_date" md-immediately>
                    <label>Start Date</label>
                  </md-datepicker>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-datepicker v-model="to_date" md-immediately>
                    <label>To Date</label>
                  </md-datepicker>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <select id="db_name_dropdwon">
                    <option>Select DB</option>
                    <option v-for="db in db_list" v-bind:key="db._id" :value="db._id">
                      {{ db.db_name }}
                    </option>
                </select>
              </div>
              <div class="md-layout-item md-size-50">
                  <select id="domain_dropdwon">
                      <option>Select Domain</option>
                      <option v-for="dom in domain_list" v-bind:key="dom._id" :value="dom.domain">
                        {{ dom.domain }}
                      </option>
                  </select>
              </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-30">
              <md-field>
                <md-input type="text" class="box-textbox" placeholder="Search By Email" v-model="user_email"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>Account Verify</label>
                <md-select v-model="account_verify">
                  <md-option value="">All</md-option>
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>Enterprise Created</label>
                <md-select v-model="enterprise_created">
                  <md-option value="">All</md-option>
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                  <md-option value="Default">Default</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <md-field>
                <label>APN Setup</label>
                <md-select v-model="apn_setup">
                  <md-option value="">All</md-option>
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                  <md-option value="Default">Default</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-field>
                <label>Profile Created</label>
                <md-select v-model="profile_created">
                  <md-option value="">All</md-option>
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                  <md-option value="Default">Default</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-field>
                <label>Device Enroll</label>
                <md-select v-model="device_enrolled">
                  <md-option value="">All</md-option>
                  <md-option value="Yes">Yes</md-option>
                  <md-option value="No">No</md-option>
                </md-select>
              </md-field>
            </div>
              <div class="md-layout-item md-size-25">
                  <md-button class="md-primary" :disabled="isDisabled" @click="UserList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">
                <a href="javascript:void(0)" @click="$router.push({ path: 'admin-user-detail', query: { user_id: item._id }})">{{ item.name }}</a>
              </md-table-cell>
              <md-table-cell md-label="Email">
                <a href="javascript:void(0)" @click="ByPassUserLogin(item.email)">{{ item.email  }}</a>
                <label v-if="item.phone_number">&nbsp; / &nbsp; {{ item.country_code + item.phone_number  }}</label>
              </md-table-cell>
              <md-table-cell md-label="Progress">
                <table>
                  <tr>
                    <td><b>Account Verify: <label :class="GetClass(item.account_progress.account_verify)">{{ item.account_progress.account_verify }}</label></b></td>
                  </tr>
                  <tr>
                    <td><b>Enterprise Created: <label :class="GetClass(item.account_progress.enterprise_created)">{{ item.account_progress.enterprise_created }}</label></b></td>
                  </tr>
                  <tr>
                    <td><b>APN Setup: <label :class="GetClass(item.account_progress.apn_setup)">{{ item.account_progress.apn_setup }}</label></b></td>
                  </tr>
                  <tr>
                    <td><b>Profile Created: <label :class="GetClass(item.account_progress.profile_created)">{{ item.account_progress.profile_created }}</label></b></td>
                  </tr>
                  <tr>
                    <td><b>Device Enroll: <label :class="GetClass(item.account_progress.device_enrolled)">{{ item.account_progress.device_enrolled }}</label></b></td>
                  </tr>
                  <tr>
                    <td><b>Total Devices: <label class="text-info">{{ item.account_progress.total_devices }}</label></b></td>
                  </tr>
                </table>
              </md-table-cell>
              <md-table-cell md-label="db_name">
                {{ item.db_name }} <label v-if="item.domain"> / {{ item.domain }}</label>
              </md-table-cell>
              <!--<md-table-cell md-label="Domain">{{ item.domain }}</md-table-cell>-->
              <md-table-cell md-label="Created At">{{ HumanReadableDateFormat(item.created_at)  }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../../services/SuperAdmin/UsersDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      from_date: "",
      to_date: new Date().toISOString().split('T')[0],
      user_email: "",
      db_name: "",
      db_list: [],
      domain: "",
      domain_list: [],
      tableData: [],
      account_verify: "",
      enterprise_created: "",
      apn_setup: "",
      profile_created: "",
      device_enrolled: "",


      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

    };
  },
  methods: {
    UserList() {
      let data = { 
        from_date: this.from_date,
        to_date: this.to_date,
        email: this.user_email.trim(),
        db_name: this.db_name,
        domain: this.domain,
        account_verify: this.account_verify,
        enterprise_created: this.enterprise_created,
        apn_setup: this.apn_setup,
        profile_created: this.profile_created,
        device_enrolled: this.device_enrolled,
      }
      UsersDataService.UserList(data)
        .then((response) => {
          if (response.data.data) {
            this.tableData = response.data.data;
            this.db_list = response.data.filters.DbList;
            this.domain_list = response.data.filters.domianList;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    ByPassUserLogin(email) {
        let data = {
            email: email,
        }
        UsersDataService.ByPassUserLogin(data)
        .then((response) => {
            localStorage.setItem('token', response.data.data.token);
            let routeData = this.$router.resolve({name: 'dashboard'});
            window.open(routeData.href, '_blank');
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    SyncUsers() {
      this.isDisabled = true;
      UsersDataService.SyncUsers()
        .then((response) => {
          this.isDisabled = false;
          this.UserList();
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    
    GetClass: function(value) {
      if (value == "Yes") {
        return "text-success";
      } else {
        return "text-danger";
      }
    },

    async ExportUsers() {
      if (this.tableData.length > 0) {
        let export_data = [];
        for await (let user of this.tableData) {
          let user_arr = [
            user.name,user.email, user.country_code, user.phone_number
          ];
          export_data.push(user_arr);
        }
        this.DownloadSampleImportFile(export_data);
      } else {
        this.$toast.error("No data to export");
      }
    },
    DownloadSampleImportFile(export_data = "") {
      let csv = 'Name,Email,CountryCode,PhoneNumber\n';
      if (export_data) {
        export_data.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      if (export_data) {
        anchor.download = 'export-user.csv';
      } else {
        anchor.download = 'import-user-sample.csv';
      }
      anchor.click();
    }
  },
  beforeMount() {
    let d = new Date();
    d.setMonth(d.getMonth()-1);
    this.from_date = d.toISOString().split('T')[0];
    this.UserList();
  },
  mounted() {
    $('#db_name_dropdwon').select2({
      placeholder: "Select Database"
    }).on('select2:select', (e) => {
      if (e.params.data.text == "Select DB") {
        this.db_name = "";
      } else {
        this.db_name = e.params.data.text;
      }
    });
    $('#domain_dropdwon').select2({
      placeholder: "Select Domain"
    }).on('select2:select', (e) => {
      if (e.params.data.text == "Select Domain") {
         this.domain = "";
      } else {
         this.domain = e.params.data.text;
      }
    });
  }
};
</script>

<style>
</style>
