<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        
      </div>
      <md-card>
        <md-card-content>
            <md-field>
                <label>Disabled Factory Reset User List</label>
                <md-input v-model="disabled_factory_reset_user_list" type="text"></md-input>
            </md-field>
            <small class="text-danger">Pass comma seperated value like abc@gmail.com,xyz@gmail.com</small>
        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success" :disabled="isDisabled" @click="UpdateAdminDetail()">Update</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../../services/SuperAdmin/UsersDataService";
export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      disabled_factory_reset_user_list: null,
    };
  },
  methods: {
    GetSettings() {
      var data = {
        key: "disabled_factory_reset_user_list"
      };
      UsersDataService.GetSettings(data)
        .then((response) => {
            this.disabled_factory_reset_user_list = response.data.data.value;
            this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    UpdateAdminDetail() {
        this.isDisabled = true;
        let data = {
            key:"disabled_factory_reset_user_list",
            value: this.disabled_factory_reset_user_list
        }
        UsersDataService.UpdateAdminDetail(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.isDisabled = false;
            })
            .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
                this.isDisabled = false;
            });
    }
  },
  beforeMount() {
    this.GetSettings();
  },
};
</script>