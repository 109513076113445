import http from "../http-common";

class EnterpriseDataService {
  GenerateSignupUrl() {
    return http.get("emm/generate-signup-url");
  }

  GetEnterpriseDetal() {
    return http.get("emm/enterprise/detail");
  }

  CompleteEnterpriseSignup(enterpriseToken) {
    return http.get("emm/create/enterprise?enterpriseToken="+enterpriseToken);
  }

  UnenrollEnterprise(data) {
    return http.post("emm/enterprise/unenroll", data);
  }

  SyncEnterprise() {
    return http.get("emm/enterprise/sync");
  }
}

export default new EnterpriseDataService();