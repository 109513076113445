import http from "../http-common";

class QrCodeDataService {
    GetQrCodeList(data = "") {
        if (data) {
            return http.get("qr-code/list", { params: data });
        } else {
            return http.get("qr-code/list");
        }
    }
    CreateQrCode(data) {
        return http.post("qr-code/create", data);
    }
    DeleteQrCode(data) {
        return http.post("qr-code/delete", data);
    }
    RegenerateQrCode(data) {
        return http.post("qr-code/regenerate", data);
    }
    UpdateQrCode(data) {
        return http.post("qr-code/update", data);
    }
    getDefaultQrCode() {
        return http.get("qr-code/default");
    }
    sendQrCodeEmail(data) {
        return http.post("qr-code/send/email", data);
    }
}

export default new QrCodeDataService();