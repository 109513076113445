<template>
  <div class="md-layout-item md-size-100">
    <div class="md-layout" v-if="host == 'test.novaemm.com' || host == 'mdm.packagedisabler.com' || host == 'localhost:8080'">
      <div class="md-layout-item md-size-50 bg-white border-right">
        <div class="p-5 rounded-lg shadow">
          <div class="product-title">
            <p>Basic Plan</p>
          </div>
          <h3 class="display-amount">12$<small> / Year</small></h3>
          <ul
            class="list-unstyled my-5 text-small text-left font-weight-normal"
          >
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Android Enterprise Enrolment
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Device Configurations
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Application Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Content Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Kiosk Mode (Single/Multi App/Browser Kiosk Mode)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Online Help)
            </li>
            <li class="mb-3">
              <i class="fa fa-times mr-2 text-danger"></i> Enterprise Apps
            </li>
            <li class="mb-3">
              <i class="fa fa-times mr-2 text-danger"></i> Reports (Standard)
            </li>
            <li class="mb-3">
              <i class="fa fa-times mr-2 text-danger"></i> Reports (Custom)
            </li>
            <li class="mb-3">
              <i class="fa fa-times mr-2 text-danger"></i> Support (Email)
            </li>
            <li class="mb-3">
              <i class="fa fa-times mr-2 text-danger"></i> Support (Call)
            </li>
          </ul>
          <div class="product-footer" v-if="basic_price_id">
            <md-button class="md-success" :disabled="isDisabled" @click="RedirectToSubscriptionPage(basic_price_id)">Purchase</md-button>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-50 bg-blue">
        <div class="p-5 rounded-lg shadow">
          <div class="product-title">
            <p>Premium Plan</p>
          </div>
          <h3 class="display-amount">24$<small> / Year</small></h3>
          <ul
            class="list-unstyled my-5 text-small text-left font-weight-normal"
          >
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i>  Android Enterprise Enrolment
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Device Configurations
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Application Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Content Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Kiosk Mode (Single/Multi App/Browser Kiosk Mode)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Enterprise Apps
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Reports (Standard)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Reports (Custom)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Online Help)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Email)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Call)
            </li>
          </ul>
          <div class="product-footer" v-if="premium_price_id">
            <md-button class="md-success" :disabled="isDisabled" @click="RedirectToSubscriptionPage(premium_price_id)">Purchase</md-button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout" v-else>
      <div class="md-layout-item md-size-100 bg-blue">
        <div class="p-5 rounded-lg shadow">
          <div class="product-title">
            <p>Premium Plan</p>
          </div>
          <h3 class="display-amount">50$<small> / Year</small></h3>
          <ul
            class="list-unstyled my-5 text-small text-left font-weight-normal"
          >
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i>  Android Enterprise Enrolment
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Device Configurations
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Application Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Content Management
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Kiosk Mode (Single/Multi App/Browser Kiosk Mode)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Enterprise Apps
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Reports (Standard)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Reports (Custom)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Online Help)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Email)
            </li>
            <li class="mb-3">
              <i class="fa fa-check mr-2 text-primary"></i> Support (Call)
            </li>
          </ul>
          <div class="product-footer" v-if="white_labelling_price_id">
            <md-button class="md-success" :disabled="isDisabled" @click="RedirectToSubscriptionPage(white_labelling_price_id)">Purchase</md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentDataService from "../../services/PaymentDataService";
export default {
    data() {
        return {
            basic_price_id: null,
            premium_price_id: null,
            white_labelling_price_id: null,
            host: window.location.host,
        };
    },
    methods : {
        GetPriceList() {
             PaymentDataService.GetPriceList({}).then((response) => {
                this.basic_price_id = response.data.data[0];
                this.premium_price_id = response.data.data[1];
                this.white_labelling_price_id = response.data.data[2];
            });
        },
        RedirectToSubscriptionPage(price_id) {
            this.$router
            .push({ path: 'subscription', query: { price_id: price_id }})
            .then(() => { this.$router.go() })
        }
    },
    mounted: function () {
        this.GetPriceList();
    },
}
</script>
<style scoped>
.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
  color: black;
}

.text-uppercase {
  letter-spacing: 0.2em;
}
.bg-white {
  background-color: var(--md-theme-default-background, #fff);
}
.product-title {
  text-align: center;
  margin: 15px;
  font-weight: bold;
  font-weight: bold;
  border-bottom: 1px solid black;
}
.display-amount {
  text-align: center;
  font-weight: bold;
  color: #6ddc27;
}
.product-footer {
    text-align: center;
    margin-bottom: 10px;
    border-top: 1px solid black;
}
.bg-blue{
    background-color:#527eee;
}
.border-right{
    border-right: 1px solid black;
}
</style>